
export class NavElement {
  id: NavLinkEnum;
  name: string;
  path: string;
  iconName?: string;
}

export enum NavLinkEnum {
  /* eslint-disable */
  home = <any>'home',
  offerOrderManagement = <any>'offerOrderManagement',
  companyManagement = <any>'companyManagement',
  chiffreAddressesManagement = <any>'chiffreAddressesManagement',
  downloads = <any>'downloads',
  userManagement = <any>'userManagement',
  videoManagement = <any>'videoManagement',
  statistics = <any>'statistics',
  aboutUs = <any>'aboutUs',
  contact = <any>'contact',
  impressum = <any>'impressum',
  agb = <any>'agb',
  dataProtection = <any>'dataProtection',
  realEstate = <any>'realEstate',
  jobs = <any>'jobs',
  usedCars = <any>'usedCars',
  classifiedAds = <any>'classifiedAds',
  internalProductName = <any>'internalProductName'
  /* eslint-enable */
}

