import { NgModule } from '@angular/core';
import { DurationPipe } from './shared/pipes/duration.pipe';
import { ProductPipe } from './shared/pipes/product.pipe';
import { StatusClassPipe } from './shared/pipes/status-class.pipe';
import { DurationIsoPipe } from './shared/pipes/duration-iso.pipe';
import { ActionPipe } from './shared/pipes/action.pipe';
import { UserRolePipe } from './user-administration/UserRolePipe';
import { WorkflowToDoPipe } from './shared/pipes/workflowToDo.pipe';
import { ProductTypePipe } from './shared/pipes/product-type-pipe';
import { BenefitsPipe } from './company/company-detail/company-profile-v3/company-profile-v3-edit/shared/pipes/benefits.pipe';
import { OfferOrderRefreshOptionPipe } from './shared/pipes/offer-order-refresh-option-pipe';
import { OfferOrderTopJobDurationPipe } from './shared/pipes/offer-order-top-job-duration-pipe';
import { OfferSalaryUnitPipe } from './shared/pipes/offer-salary-unit.pipe';
import { EmploymentTypePipe } from './shared/pipes/employment-type.pipe';
import { ProductVariantPipe } from './shared/pipes/product-variant-pipe';
import { OfferOrderStatusPipe } from './shared/pipes/offer-order-status-pipe';
import { CompanyProfileStatusPipe } from './shared/pipes/company-profile-status-pipe';
import { OfferStatusPipe } from './shared/pipes/offer-status-pipe';
import {JoinPipe} from './shared/pipes/join.pipe';

@NgModule({
  declarations: [
    // All application pipes need to be registered and exported here.
    // this module is imported and exported on AppCommonsModule,
    // which is necessary for every module,
    // thus available to every module.
    DurationPipe,
    ProductPipe,
    OfferOrderStatusPipe,
    OfferStatusPipe,
    CompanyProfileStatusPipe,
    StatusClassPipe,
    DurationIsoPipe,
    ActionPipe,
    UserRolePipe,
    WorkflowToDoPipe,
    ProductTypePipe,
    ProductVariantPipe,
    BenefitsPipe,
    OfferOrderRefreshOptionPipe,
    OfferOrderTopJobDurationPipe,
    OfferSalaryUnitPipe,
    EmploymentTypePipe,
    JoinPipe

  ],
  exports: [
    DurationPipe,
    DurationIsoPipe,
    ProductPipe,
    OfferOrderStatusPipe,
    OfferStatusPipe,
    CompanyProfileStatusPipe,
    StatusClassPipe,
    ActionPipe,
    UserRolePipe,
    WorkflowToDoPipe,
    ProductTypePipe,
    ProductVariantPipe,
    BenefitsPipe,
    OfferOrderRefreshOptionPipe,
    OfferOrderTopJobDurationPipe,
    OfferSalaryUnitPipe,
    EmploymentTypePipe,
    JoinPipe
  ]
})

export class AppPipesModule {
}
