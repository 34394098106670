import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/index';
import { CompanySearchService } from '../../../company/shared/services/company-search/company-search.service';
import { debounceTime, switchMap, map } from 'rxjs/operators';
import { CompanySearchFilterBasicCompanyProfileStatus } from '../../../shared/reseller-api/client';

@Injectable({
  providedIn: 'root'
})

export class ReadyForBasicCompanyProfileCounterService {

  private readonly readyForBasicCompanyProfileCount: Observable<string>;
  private reloadReadyForBasicCompanyProfileCounter: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private companySearchService: CompanySearchService) {
    this.readyForBasicCompanyProfileCount = this.reloadReadyForBasicCompanyProfileCounter.pipe(debounceTime(2000),
      switchMap(() => this.companySearchService.searchCompany({ basicCompanyProfile: [CompanySearchFilterBasicCompanyProfileStatus.Ready], take: 0 })),
      map(result => result.totalCount.toString())
    );
  }

  public getCount(): Observable<string> {
    return this.readyForBasicCompanyProfileCount;
  }

  public refreshCounter(): void {
    this.reloadReadyForBasicCompanyProfileCounter.next(true);
  }
}
