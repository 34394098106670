/**
 * Sa.Reseller.Api
 *
 * OpenAPI spec version: v2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Role - Rolle
 */
export type UserRoleType = 'Undefined' | 'SystemAdmin' | 'Reseller' | 'ResellerOfferManager' | 'ResellerSalesManager';

export const UserRoleType = {
    Undefined: 'Undefined' as UserRoleType,
    SystemAdmin: 'SystemAdmin' as UserRoleType,
    Reseller: 'Reseller' as UserRoleType,
    ResellerOfferManager: 'ResellerOfferManager' as UserRoleType,
    ResellerSalesManager: 'ResellerSalesManager' as UserRoleType
}
