import { IEnvironment } from './environment.type';

export const environment: IEnvironment = {
  production: false,
  environmentName: 'SA Feature',
  apiEndpoint: 'https://sare-feature.azurewebsites.net',
  rendererApiEndpoint: 'https://renderer-feature.azurewebsites.net',
  apiKey: {'X-Api-Key': 'aa9f3bca318e40a389a9128a282f0258'},
  sentryDSN: 'https://1433dc2be1f146608c0413ae4d49e17c@sentry.statec.io/69'

};
