/**
 * Sa.Reseller.Api
 *
 * OpenAPI spec version: v2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CompanyProfile } from '../model/companyProfile';
import { CompanyProfileComment } from '../model/companyProfileComment';
import { CompanyProfileCreate } from '../model/companyProfileCreate';
import { CompanyProfileEdit } from '../model/companyProfileEdit';
import { CompanyProfileExtend } from '../model/companyProfileExtend';
import { CompanyProfileTrackingCode } from '../model/companyProfileTrackingCode';
import { CreatedModel } from '../model/createdModel';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { CompanyCompanyProfileServiceInterface }                            from './companyCompanyProfile.serviceInterface';


@Injectable({
  providedIn: 'root'
})
export class CompanyCompanyProfileService implements CompanyCompanyProfileServiceInterface {

    protected basePath = '';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (let consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Activate request for the specified company profile type and specified company
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param type The profile type of the company you wish to upload and publish. 1: Basic, 2: Premium
     * @param companyId The Id of the company the profile belongs to
     * @param resellerId The id of your reseller
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companyProfileActivateRequestAsync(type: 'Basis' | 'Premium', companyId: number, resellerId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public companyProfileActivateRequestAsync(type: 'Basis' | 'Premium', companyId: number, resellerId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public companyProfileActivateRequestAsync(type: 'Basis' | 'Premium', companyId: number, resellerId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public companyProfileActivateRequestAsync(type: 'Basis' | 'Premium', companyId: number, resellerId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling companyProfileActivateRequestAsync.');
        }
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling companyProfileActivateRequestAsync.');
        }
        if (resellerId === null || resellerId === undefined) {
            throw new Error('Required parameter resellerId was null or undefined when calling companyProfileActivateRequestAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.put<any>(`${this.basePath}/api/v2/resellers/${encodeURIComponent(String(resellerId))}/companies/${encodeURIComponent(String(companyId))}/companyProfiles/${encodeURIComponent(String(type))}/requestActivation`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates the metadata for a new Company Profile
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param companyId The Id of the company the profile belongs to
     * @param newCompanyProfile The metadata object for the new Company Profile
     * @param resellerId The id of your reseller
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companyProfileCreateAsync(companyId: number, newCompanyProfile: CompanyProfileCreate, resellerId: number, observe?: 'body', reportProgress?: boolean): Observable<CreatedModel>;
    public companyProfileCreateAsync(companyId: number, newCompanyProfile: CompanyProfileCreate, resellerId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreatedModel>>;
    public companyProfileCreateAsync(companyId: number, newCompanyProfile: CompanyProfileCreate, resellerId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreatedModel>>;
    public companyProfileCreateAsync(companyId: number, newCompanyProfile: CompanyProfileCreate, resellerId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling companyProfileCreateAsync.');
        }
        if (newCompanyProfile === null || newCompanyProfile === undefined) {
            throw new Error('Required parameter newCompanyProfile was null or undefined when calling companyProfileCreateAsync.');
        }
        if (resellerId === null || resellerId === undefined) {
            throw new Error('Required parameter resellerId was null or undefined when calling companyProfileCreateAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<CreatedModel>(`${this.basePath}/api/v2/resellers/${encodeURIComponent(String(resellerId))}/companies/${encodeURIComponent(String(companyId))}/companyProfiles`,
            newCompanyProfile,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deactivate request for the specified company profile type and specified company
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param type The profile type of the company you wish to upload and publish. 1: Basic, 2: Premium
     * @param companyId The Id of the company the profile belongs to
     * @param resellerId The id of your reseller
     * @param comment 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companyProfileDeactivateRequestAsync(type: 'Basis' | 'Premium', companyId: number, resellerId: number, comment: CompanyProfileComment, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public companyProfileDeactivateRequestAsync(type: 'Basis' | 'Premium', companyId: number, resellerId: number, comment: CompanyProfileComment, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public companyProfileDeactivateRequestAsync(type: 'Basis' | 'Premium', companyId: number, resellerId: number, comment: CompanyProfileComment, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public companyProfileDeactivateRequestAsync(type: 'Basis' | 'Premium', companyId: number, resellerId: number, comment: CompanyProfileComment, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling companyProfileDeactivateRequestAsync.');
        }
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling companyProfileDeactivateRequestAsync.');
        }
        if (resellerId === null || resellerId === undefined) {
            throw new Error('Required parameter resellerId was null or undefined when calling companyProfileDeactivateRequestAsync.');
        }
        if (comment === null || comment === undefined) {
            throw new Error('Required parameter comment was null or undefined when calling companyProfileDeactivateRequestAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/api/v2/resellers/${encodeURIComponent(String(resellerId))}/companies/${encodeURIComponent(String(companyId))}/companyProfiles/${encodeURIComponent(String(type))}/requestDeactivation`,
            comment,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates a company Profile
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param companyId 
     * @param type 
     * @param editProfile 
     * @param resellerId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companyProfileEditCompanyProfile(companyId: number, type: 'Basis' | 'Premium', editProfile: CompanyProfileEdit, resellerId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public companyProfileEditCompanyProfile(companyId: number, type: 'Basis' | 'Premium', editProfile: CompanyProfileEdit, resellerId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public companyProfileEditCompanyProfile(companyId: number, type: 'Basis' | 'Premium', editProfile: CompanyProfileEdit, resellerId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public companyProfileEditCompanyProfile(companyId: number, type: 'Basis' | 'Premium', editProfile: CompanyProfileEdit, resellerId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling companyProfileEditCompanyProfile.');
        }
        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling companyProfileEditCompanyProfile.');
        }
        if (editProfile === null || editProfile === undefined) {
            throw new Error('Required parameter editProfile was null or undefined when calling companyProfileEditCompanyProfile.');
        }
        if (resellerId === null || resellerId === undefined) {
            throw new Error('Required parameter resellerId was null or undefined when calling companyProfileEditCompanyProfile.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/api/v2/resellers/${encodeURIComponent(String(resellerId))}/companies/${encodeURIComponent(String(companyId))}/companyProfiles/${encodeURIComponent(String(type))}`,
            editProfile,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates the Tracking Code for the specified company profile.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param companyId The Id of the company the profile belongs to
     * @param type The profile type of the company you wish to upload and publish. 1: Basic, 2: Premium
     * @param trackingCode The new tracking code of the company profile
     * @param resellerId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companyProfileEditCompanyProfileTrackingCode(companyId: number, type: 'Basis' | 'Premium', trackingCode: CompanyProfileTrackingCode, resellerId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public companyProfileEditCompanyProfileTrackingCode(companyId: number, type: 'Basis' | 'Premium', trackingCode: CompanyProfileTrackingCode, resellerId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public companyProfileEditCompanyProfileTrackingCode(companyId: number, type: 'Basis' | 'Premium', trackingCode: CompanyProfileTrackingCode, resellerId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public companyProfileEditCompanyProfileTrackingCode(companyId: number, type: 'Basis' | 'Premium', trackingCode: CompanyProfileTrackingCode, resellerId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling companyProfileEditCompanyProfileTrackingCode.');
        }
        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling companyProfileEditCompanyProfileTrackingCode.');
        }
        if (trackingCode === null || trackingCode === undefined) {
            throw new Error('Required parameter trackingCode was null or undefined when calling companyProfileEditCompanyProfileTrackingCode.');
        }
        if (resellerId === null || resellerId === undefined) {
            throw new Error('Required parameter resellerId was null or undefined when calling companyProfileEditCompanyProfileTrackingCode.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/api/v2/resellers/${encodeURIComponent(String(resellerId))}/companies/${encodeURIComponent(String(companyId))}/companyProfiles/${encodeURIComponent(String(type))}/trackingCode`,
            trackingCode,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Extends the specified CompanyProfile setting a price. SEGA Users can just specify price 0
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param type The profile type of the company you wish to upload and publish. 1: Basic, 2: Premium
     * @param companyProfileExtendModel Price
     * @param companyId The Id of the company the profile belongs to
     * @param resellerId The id of your reseller
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companyProfileExtendAsync(type: 'Basis' | 'Premium', companyProfileExtendModel: CompanyProfileExtend, companyId: number, resellerId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public companyProfileExtendAsync(type: 'Basis' | 'Premium', companyProfileExtendModel: CompanyProfileExtend, companyId: number, resellerId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public companyProfileExtendAsync(type: 'Basis' | 'Premium', companyProfileExtendModel: CompanyProfileExtend, companyId: number, resellerId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public companyProfileExtendAsync(type: 'Basis' | 'Premium', companyProfileExtendModel: CompanyProfileExtend, companyId: number, resellerId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling companyProfileExtendAsync.');
        }
        if (companyProfileExtendModel === null || companyProfileExtendModel === undefined) {
            throw new Error('Required parameter companyProfileExtendModel was null or undefined when calling companyProfileExtendAsync.');
        }
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling companyProfileExtendAsync.');
        }
        if (resellerId === null || resellerId === undefined) {
            throw new Error('Required parameter resellerId was null or undefined when calling companyProfileExtendAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/api/v2/resellers/${encodeURIComponent(String(resellerId))}/companies/${encodeURIComponent(String(companyId))}/companyProfiles/${encodeURIComponent(String(type))}/extend`,
            companyProfileExtendModel,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves the list of allowed actions for the specified company profile from the system.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager, ResellerSalesManager
     * @param type
     * @param companyId
     * @param resellerId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companyProfileGetCompanyProfileActionsByTypeAsync(type: 'Basis' | 'Premium', companyId: number, resellerId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public companyProfileGetCompanyProfileActionsByTypeAsync(type: 'Basis' | 'Premium', companyId: number, resellerId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public companyProfileGetCompanyProfileActionsByTypeAsync(type: 'Basis' | 'Premium', companyId: number, resellerId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public companyProfileGetCompanyProfileActionsByTypeAsync(type: 'Basis' | 'Premium', companyId: number, resellerId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling companyProfileGetCompanyProfileActionsByTypeAsync.');
        }
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling companyProfileGetCompanyProfileActionsByTypeAsync.');
        }
        if (resellerId === null || resellerId === undefined) {
            throw new Error('Required parameter resellerId was null or undefined when calling companyProfileGetCompanyProfileActionsByTypeAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<string>>(`${this.basePath}/api/v2/resellers/${encodeURIComponent(String(resellerId))}/companies/${encodeURIComponent(String(companyId))}/companyProfiles/${encodeURIComponent(String(type))}/actions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves an existing company profile from the system by company id and company profile type
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager, ResellerSalesManager
     * @param type
     * @param companyId
     * @param resellerId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companyProfileGetCompanyProfileByTypeAsync(type: 'Basis' | 'Premium', companyId: number, resellerId: number, observe?: 'body', reportProgress?: boolean): Observable<CompanyProfile>;
    public companyProfileGetCompanyProfileByTypeAsync(type: 'Basis' | 'Premium', companyId: number, resellerId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CompanyProfile>>;
    public companyProfileGetCompanyProfileByTypeAsync(type: 'Basis' | 'Premium', companyId: number, resellerId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CompanyProfile>>;
    public companyProfileGetCompanyProfileByTypeAsync(type: 'Basis' | 'Premium', companyId: number, resellerId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling companyProfileGetCompanyProfileByTypeAsync.');
        }
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling companyProfileGetCompanyProfileByTypeAsync.');
        }
        if (resellerId === null || resellerId === undefined) {
            throw new Error('Required parameter resellerId was null or undefined when calling companyProfileGetCompanyProfileByTypeAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<CompanyProfile>(`${this.basePath}/api/v2/resellers/${encodeURIComponent(String(resellerId))}/companies/${encodeURIComponent(String(companyId))}/companyProfiles/${encodeURIComponent(String(type))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * returns the url of the company profile.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param companyId 
     * @param type 
     * @param resellerId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companyProfileGetCompanyProfileUrl(companyId: number, type: 'Basis' | 'Premium', resellerId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public companyProfileGetCompanyProfileUrl(companyId: number, type: 'Basis' | 'Premium', resellerId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public companyProfileGetCompanyProfileUrl(companyId: number, type: 'Basis' | 'Premium', resellerId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public companyProfileGetCompanyProfileUrl(companyId: number, type: 'Basis' | 'Premium', resellerId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling companyProfileGetCompanyProfileUrl.');
        }
        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling companyProfileGetCompanyProfileUrl.');
        }
        if (resellerId === null || resellerId === undefined) {
            throw new Error('Required parameter resellerId was null or undefined when calling companyProfileGetCompanyProfileUrl.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/api/v2/resellers/${encodeURIComponent(String(resellerId))}/companies/${encodeURIComponent(String(companyId))}/companyProfiles/${encodeURIComponent(String(type))}/companyProfileUrl`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Uploads a new Company Profile zip file and activates its publication for the date specified in the profile metadata
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param type The profile type of the company you wish to upload and publish. 1: Basic, 2: Premium
     * @param companyId The Id of the company the profile belongs to
     * @param resellerId The id of your reseller
     * @param file Company profile zip file for upload
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companyProfileUpdateFileAndPublishAsync(type: 'Basis' | 'Premium', companyId: number, resellerId: number, file: Blob, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public companyProfileUpdateFileAndPublishAsync(type: 'Basis' | 'Premium', companyId: number, resellerId: number, file: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public companyProfileUpdateFileAndPublishAsync(type: 'Basis' | 'Premium', companyId: number, resellerId: number, file: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public companyProfileUpdateFileAndPublishAsync(type: 'Basis' | 'Premium', companyId: number, resellerId: number, file: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling companyProfileUpdateFileAndPublishAsync.');
        }
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling companyProfileUpdateFileAndPublishAsync.');
        }
        if (resellerId === null || resellerId === undefined) {
            throw new Error('Required parameter resellerId was null or undefined when calling companyProfileUpdateFileAndPublishAsync.');
        }
        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling companyProfileUpdateFileAndPublishAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            // @ts-ignore
            formParams = formParams.append('file', <any>file) || formParams;
        }

        return this.httpClient.put<any>(`${this.basePath}/api/v2/resellers/${encodeURIComponent(String(resellerId))}/companies/${encodeURIComponent(String(companyId))}/companyProfiles/${encodeURIComponent(String(type))}/uploadAndRequestActivation`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
