/**
 * Sa.Reseller.Api
 *
 * OpenAPI spec version: v2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export type CompanySearchFilterSortSpec = 'ByNumberASC' | 'ByNumberDESC' | 'ByNameASC' | 'ByNameDESC' | 'BySegaActiveASC' | 'BySegaActiveDESC' | 'ByTopCompanyASC' | 'ByTopCompanyDESC' | 'ByCompanyProfileV3ActiveASC' | 'ByCompanyProfileV3ActiveDESC' | 'ByCompanyProfileV2ActiveASC' | 'ByCompanyProfileV2ActiveDESC';

export const CompanySearchFilterSortSpec = {
    ByNumberASC: 'ByNumberASC' as CompanySearchFilterSortSpec,
    ByNumberDESC: 'ByNumberDESC' as CompanySearchFilterSortSpec,
    ByNameASC: 'ByNameASC' as CompanySearchFilterSortSpec,
    ByNameDESC: 'ByNameDESC' as CompanySearchFilterSortSpec,
    BySegaActiveASC: 'BySegaActiveASC' as CompanySearchFilterSortSpec,
    BySegaActiveDESC: 'BySegaActiveDESC' as CompanySearchFilterSortSpec,
    ByTopCompanyASC: 'ByTopCompanyASC' as CompanySearchFilterSortSpec,
    ByTopCompanyDESC: 'ByTopCompanyDESC' as CompanySearchFilterSortSpec,
    ByCompanyProfileV3ActiveASC: 'ByCompanyProfileV3ActiveASC' as CompanySearchFilterSortSpec,
    ByCompanyProfileV3ActiveDESC: 'ByCompanyProfileV3ActiveDESC' as CompanySearchFilterSortSpec,
    ByCompanyProfileV2ActiveASC: 'ByCompanyProfileV2ActiveASC' as CompanySearchFilterSortSpec,
    ByCompanyProfileV2ActiveDESC: 'ByCompanyProfileV2ActiveDESC' as CompanySearchFilterSortSpec
}
