import {NgModule, Provider} from '@angular/core';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {ErrorInterceptor} from './error.interceptor';
import {SpinnerInterceptor} from './spinner.interceptor';
import {OpenOfferCountInterceptor} from './open-offer-count.interceptor';
import {environment as currentEnvironment} from '../../../environments/environment';
import {environment as e2eEnvironment} from '../../../environments/environment.e2e';
import {ReadyForBasicCompanyProfileCountInterceptor} from './ready-for-basic-company-profile-count.interceptor';

const baseInterceptorProviders: Provider[] = [
  {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true}
];

// saves time in e2e tests
const none2eInterceptorProviders: Provider[] = [
  {provide: HTTP_INTERCEPTORS, useClass: OpenOfferCountInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: ReadyForBasicCompanyProfileCountInterceptor, multi: true}
];

@NgModule({
  providers: [...baseInterceptorProviders, ...currentEnvironment.environmentName !== e2eEnvironment.environmentName ? none2eInterceptorProviders : []]
})
export class HttpInterceptorsModule {
}
