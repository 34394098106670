import { Injectable } from '@angular/core';
import Dexie from 'dexie';
import { Observable, of } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/fromPromise';

@Injectable({
  providedIn: 'root',
})
export class IndexedDatabaseService {

  public db: Dexie;
  public DB_NAME = 'stellenanzeigen';

  public recreateDB(): Observable<Dexie> {
    if (this.db) {
      return of(this.db);
    }

    this.db = new Dexie(this.DB_NAME);
    this.db.version(1).stores({
      attachment: 'id,bytes,name'
    });
    this.db.version(2).stores({
      attachment: 'id,bytes,name',
      offerOrderFormContainer: ''
    });
    this.db.version(3).stores({
      attachment: 'id,bytes,name',
      offerOrderFormContainer: '',
      companyProfileV3RenderData: ''
    });
    return fromPromise(this.db.open());
  }

  public deleteDB(): Observable<void> {
    this.db = undefined;
    return fromPromise(Dexie.delete(this.DB_NAME));
  }
}
