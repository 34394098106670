import {
  Config, CREATE_EDIT_CORRECT_DISPLAY, DISPLAY, OfferOrderProperty, OfferProperty, ProductProperty
} from './product-config.model';
import {
  EXCLUDE_WITH_CHIFFRE,
  TABLE_ENTRY_NOT_BOOKED_BY_RESELLER
} from './modifiers/modifiers.model';

// eslint-disable-next-line
export namespace SegaKontingentRegioOnlineOnlyConfig {

  // Html-Layout
  const html: Config = new Config();
  html.product = [
    {property: ProductProperty.companyName, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: ProductProperty.companyNumber, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: ProductProperty.companyLink, modes: DISPLAY},
    {property: ProductProperty.product, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: ProductProperty.number, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: ProductProperty.offerOrderStatus, modes: CREATE_EDIT_CORRECT_DISPLAY}
  ];
  html.offerOrder = [
    {property: OfferOrderProperty.chiffre, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.attachment, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.tableEntry, modes: CREATE_EDIT_CORRECT_DISPLAY, modifiers: TABLE_ENTRY_NOT_BOOKED_BY_RESELLER},
    {property: OfferOrderProperty.topJob, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.jobBlitz, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.duration, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.eMail, modes: CREATE_EDIT_CORRECT_DISPLAY, modifiers: EXCLUDE_WITH_CHIFFRE},
    {property: OfferOrderProperty.applicationUrl, modes: CREATE_EDIT_CORRECT_DISPLAY, modifiers: EXCLUDE_WITH_CHIFFRE},
    {property: OfferOrderProperty.homepage, modes: CREATE_EDIT_CORRECT_DISPLAY, modifiers: EXCLUDE_WITH_CHIFFRE},
    {property: OfferOrderProperty.chiffreContactDetails, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.searchTerm, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.publishingDate, modes: CREATE_EDIT_CORRECT_DISPLAY}
  ];
  html.offer = [
    {property: OfferProperty.title, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferProperty.externalLink, modes: DISPLAY},
    {property: OfferProperty.externalId, modes: DISPLAY},
    {property: OfferProperty.location, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferProperty.categoryGroupIds, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferProperty.titleAddition, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferProperty.searchKeywords, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferProperty.startAndEndDates, modes: DISPLAY},
    {property: OfferProperty.statusAndWorkflowToDo, modes: DISPLAY},
    {property: OfferProperty.clicks, modes: DISPLAY},
  ];
  export const HTML: Config = html;
}
