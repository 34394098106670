/**
 * Sa.Reseller.Api
 *
 * OpenAPI spec version: v2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export type CompanyProfileTypeEnum = 'Basis' | 'Premium';

export const CompanyProfileTypeEnum = {
    Basis: 'Basis' as CompanyProfileTypeEnum,
    Premium: 'Premium' as CompanyProfileTypeEnum
}
