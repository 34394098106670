/**
 * Sa.Reseller.Api
 *
 * OpenAPI spec version: v2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export type OfferOrderAndOfferSearchFilterSortOrder = 'ByOfferOrderNumberASC' | 'ByOfferOrderNumberDESC' | 'ByCompanyNumberASC' | 'ByCompanyNumberDESC' | 'ByTopJobASC' | 'ByTopJobDESC' | 'ByJobBlitzASC' | 'ByJobBlitzDESC' | 'ByRefreshASC' | 'ByRefreshDESC' | 'ByTableEntryASC' | 'ByTableEntryDESC' | 'ByOfferOrderStatusASC' | 'ByOfferOrderStatusDESC' | 'ByProductTypeASC' | 'ByProductTypeDESC' | 'ByPublishDateASC' | 'ByPublishDateDESC' | 'ByCreatedAtASC' | 'ByCreatedAtDESC' | 'ByCompanyNameASC' | 'ByCompanyNameDESC' | 'ByProductVariantASC' | 'ByProductVariantDESC' | 'ByKalaydoASC' | 'ByKalaydoDESC' | 'ByItJobsASC' | 'ByItJobsDESC';

export const OfferOrderAndOfferSearchFilterSortOrder = {
    ByOfferOrderNumberASC: 'ByOfferOrderNumberASC' as OfferOrderAndOfferSearchFilterSortOrder,
    ByOfferOrderNumberDESC: 'ByOfferOrderNumberDESC' as OfferOrderAndOfferSearchFilterSortOrder,
    ByCompanyNumberASC: 'ByCompanyNumberASC' as OfferOrderAndOfferSearchFilterSortOrder,
    ByCompanyNumberDESC: 'ByCompanyNumberDESC' as OfferOrderAndOfferSearchFilterSortOrder,
    ByTopJobASC: 'ByTopJobASC' as OfferOrderAndOfferSearchFilterSortOrder,
    ByTopJobDESC: 'ByTopJobDESC' as OfferOrderAndOfferSearchFilterSortOrder,
    ByJobBlitzASC: 'ByJobBlitzASC' as OfferOrderAndOfferSearchFilterSortOrder,
    ByJobBlitzDESC: 'ByJobBlitzDESC' as OfferOrderAndOfferSearchFilterSortOrder,
    ByRefreshASC: 'ByRefreshASC' as OfferOrderAndOfferSearchFilterSortOrder,
    ByRefreshDESC: 'ByRefreshDESC' as OfferOrderAndOfferSearchFilterSortOrder,
    ByTableEntryASC: 'ByTableEntryASC' as OfferOrderAndOfferSearchFilterSortOrder,
    ByTableEntryDESC: 'ByTableEntryDESC' as OfferOrderAndOfferSearchFilterSortOrder,
    ByOfferOrderStatusASC: 'ByOfferOrderStatusASC' as OfferOrderAndOfferSearchFilterSortOrder,
    ByOfferOrderStatusDESC: 'ByOfferOrderStatusDESC' as OfferOrderAndOfferSearchFilterSortOrder,
    ByProductTypeASC: 'ByProductTypeASC' as OfferOrderAndOfferSearchFilterSortOrder,
    ByProductTypeDESC: 'ByProductTypeDESC' as OfferOrderAndOfferSearchFilterSortOrder,
    ByPublishDateASC: 'ByPublishDateASC' as OfferOrderAndOfferSearchFilterSortOrder,
    ByPublishDateDESC: 'ByPublishDateDESC' as OfferOrderAndOfferSearchFilterSortOrder,
    ByCreatedAtASC: 'ByCreatedAtASC' as OfferOrderAndOfferSearchFilterSortOrder,
    ByCreatedAtDESC: 'ByCreatedAtDESC' as OfferOrderAndOfferSearchFilterSortOrder,
    ByCompanyNameASC: 'ByCompanyNameASC' as OfferOrderAndOfferSearchFilterSortOrder,
    ByCompanyNameDESC: 'ByCompanyNameDESC' as OfferOrderAndOfferSearchFilterSortOrder,
    ByProductVariantASC: 'ByProductVariantASC' as OfferOrderAndOfferSearchFilterSortOrder,
    ByProductVariantDESC: 'ByProductVariantDESC' as OfferOrderAndOfferSearchFilterSortOrder,
    ByKalaydoASC: 'ByKalaydoASC' as OfferOrderAndOfferSearchFilterSortOrder,
    ByKalaydoDESC: 'ByKalaydoDESC' as OfferOrderAndOfferSearchFilterSortOrder,
    ByItJobsASC: 'ByItJobsASC' as OfferOrderAndOfferSearchFilterSortOrder,
    ByItJobsDESC: 'ByItJobsDESC' as OfferOrderAndOfferSearchFilterSortOrder
}
