import { Component, OnInit } from '@angular/core';
import { NavElement } from '../../nav.model';
import { UserPermissionsService } from '../../../shared/services/user-role-authentication/user-permissions.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})

export class NavComponent implements OnInit {
  public navMainLinks$: Observable<NavElement[]>;
  constructor(private userRoleAuthenticationService: UserPermissionsService) {
  }

  public ngOnInit(): void {
    this.navMainLinks$ = this.userRoleAuthenticationService.getNavigationLinksAsync()
      .pipe(
        map(links =>
          links.filter(link => link.name !== 'Downloads'
            && link.name !== 'Benutzerverwaltung'
            && link.name !== 'Chiffre-Adressenverwaltung'
            && link.name !== 'Verwaltung interner Produktname')));
  }
}
