import { KeyValuePair } from '../../utils/arrays/array.helper';

export class TableFilterSettings {
  filter: FilterControlSetting[];
  values?: FilterControlValue[] = [];
}

export class TableFilterValues {
  values: FilterControlValue[];

  constructor() {
    this.values = [];
  }
}

export class FilterControlValue extends KeyValuePair {
  key: string;
  value: Object;
}

export class FilterControlSetting {
  key: string;
  label: string;
  controlType: ControlTypeEnum;
  multipleChoiceOptions?: MultipleChoiceOptionGrouping;
  disabled?: boolean;
}

export enum ControlTypeEnum {
  /* eslint-disable */
  Button = <any>'Button',
  Radio = <any>'Radio',
  TextInput = <any>'TextInput',
  CompanyInput = <any>'CompanyInput',
  TimeSpan = <any>'7TimeSpan',
  Date = <any>'8Date',
  CheckBox = <any>'CheckBox',
  CheckBoxGroup = <any>'CheckBoxGroup',
  Toggle = <any>'Toggle',
  MultiSelect = <any>'9MultiSelect'

  /* eslint-enable */
}

export interface MultipleChoiceOption {
  value: string;
  label: string;
}

export interface MultipleChoiceOptionGroup{
  name: string;
  options: MultipleChoiceOption[];
}

export interface MultipleChoiceOptionGrouping {
  groups?: MultipleChoiceOptionGroup[];
  options?: MultipleChoiceOption[];
}
