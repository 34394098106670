import { NgModule } from '@angular/core';
import { ConfirmDialogComponent } from './shared/components/confirm-dialog/confirm-dialog.component';
import { AppCommonsModule } from './app-commons.module';

@NgModule({
    imports: [
        // all dialog related modules and and general components are registered here
        AppCommonsModule,
    ],
    declarations: [
        ConfirmDialogComponent
    ],
    exports: [
        ConfirmDialogComponent
    ]
})

export class AppDialogModule {
}
