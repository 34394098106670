import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { AppPipesModule } from './app-pipes.module';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from './shared/components/spinner/spinner.component';
import { CustomMaterialModule } from './custom-material.module';
import { DisplayPropertyModule } from './shared/directives/display-property.module';
import {MatNativeDateModule} from '@angular/material/core';
import { EllipsisComponent } from './shared/components/ellipses/ellipsis.component';

import {DateRangeComponent} from "./shared/components/table-filter-component/date-range/date-range.component";
import {ReactiveFormsModule} from "@angular/forms";

@NgModule({
  // Angular and Material dependencies used/needed in almost every module are bundled here
  imports: [
    CommonModule,
    CustomMaterialModule,
    FlexLayoutModule,
    RouterModule,
    MatNativeDateModule,
    AppPipesModule,
    DisplayPropertyModule,
    ReactiveFormsModule
  ],
  exports: [
    CommonModule,
    CustomMaterialModule,
    FlexLayoutModule,
    RouterModule,
    AppPipesModule,
    SpinnerComponent,
    DateRangeComponent,
    EllipsisComponent,
    DisplayPropertyModule
  ],
  declarations: [
    SpinnerComponent,
    EllipsisComponent,
    DateRangeComponent
  ]
})

export class AppCommonsModule {
}
