<nav fxLayoutGap="0.5em">
  <span *ngFor="let navLink of navMainLinks$ | async">
    <a mat-button color="accent" type="button"
       #rla="routerLinkActive"
       class="e2eNavLink"
       [ngClass]="{'active': rla.isActive }"
       [routerLink]="navLink.path"
       routerLinkActive="active"
       [routerLinkActiveOptions]="{ exact: false }">{{ navLink.name }}</a>
  </span>
</nav>
