import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { OpenOfferOrdersCounterService } from '../../main-layout/header/open-offer-orders-counter/open-offer-orders-counter.service';

@Injectable({
  providedIn: 'root',
})
export class OpenOfferCountInterceptor implements HttpInterceptor {

  // These are backend calls that occur usually after a navigation
  // or when an offer order is manipulated (e.g. changes state).
  private trigger: string[] = [
    'CurrentResellerUserData', // after login
    'offerOrders', // actions, table load, details
    'companies', // navigation
    'videos', // navigation
    'users', // navigation
    'files', // navigation
    // Sadly we do not trigger anything when navigation to "home"
  ];

  constructor(private offerCounter: OpenOfferOrdersCounterService) {
  }

  intercept(req: HttpRequest<Object>, next: HttpHandler): Observable<HttpEvent<Object>> {
    return next.handle(req).pipe(tap(() => {
      this.trigger.forEach((url: string) => {
        if (req.url.includes(url) && !req.urlWithParams.includes('offerOrdersWithOffers?offerOrderStates=Offen&take=0')
        && !req.urlWithParams.includes('companies?basicCompanyProfile=Ready&take=0')) {
          this.offerCounter.refreshCounter();
        }
      });
    }));
  }
}
