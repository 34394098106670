/**
 * Sa.Reseller.Api
 *
 * OpenAPI spec version: v2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export type WorkflowStatusEnum = 'WaitingOnApproval';

export const WorkflowStatusEnum = {
    WaitingOnApproval: 'WaitingOnApproval' as WorkflowStatusEnum
}
