import {NgModule} from '@angular/core';
import {DisplayPropertyDirective} from './display-property.directive';
import {DisplayJobBlitzRefreshDirective} from './display-job-blitz-refresh.directive';

@NgModule({
  declarations: [
    DisplayPropertyDirective,
    DisplayJobBlitzRefreshDirective
  ],
  exports: [
    DisplayPropertyDirective,
    DisplayJobBlitzRefreshDirective
  ]
})

export class DisplayPropertyModule {
}
