import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { UserCookieService } from '../../shared/services/cookies/user-cookie/user-cookie.service';
import { ApiLoginService } from '../../shared/services/api-login.service';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/operators';
import { FilterStorageService } from '../../shared/components/table-filter-component/filter-storage/filter-storage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnDestroy {
  username: string;
  private componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(private router: Router,
              private userCookieService: UserCookieService,
              private sareLogin: ApiLoginService,
              private filterStorage: FilterStorageService) {
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  public logout(): void {
    this.router.navigate(['/login'])
      .then((result: boolean) => result ? this.sareLogin.logout()
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe() : undefined)
          .then(() => this.filterStorage.clearFilterValues());
  }

  public getUserName(): string {
    return this.userCookieService.getUserDataCookie().userName;
  }
}
