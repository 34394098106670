/**
 * This class contains helper static methods to generate GUIDs
 */
export class GuidHelper {

  /**
   * Generate a unique GUID as a string
   * @returns {string}
   */
  public static getGuid(): string {
    return GuidHelper.s4() + GuidHelper.s4() + '-' + GuidHelper.s4() + '-' + GuidHelper.s4() + '-' +
      GuidHelper.s4() + '-' + GuidHelper.s4() + GuidHelper.s4() + GuidHelper.s4();
  }

  private static s4(): string {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
}
