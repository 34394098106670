import {
  ControlTypeEnum, FilterControlSetting, MultipleChoiceOption, TableFilterSettings
} from '../../../../shared/components/table-filter-component/table-filter.model';
import { OfferOrderStatusPipe } from '../../../../shared/pipes/offer-order-status-pipe';
import { OfferOrderFilterEnum } from './offer-order-filter.enum';

/**
 * static method used to retrieve one properly built Offer State or OfferOrder State choice option configuration for
 * a table filter.
 * @param status Is the status value to configure as option. The label of the status will be passed through {StatusPipe}
 * @returns {MultipleChoiceOption}
 */
export const buildStatusOption: Function = ((status: OfferOrderFilterEnum): MultipleChoiceOption => {
  const option: MultipleChoiceOption = {label: status, value: status};
  option.label =
    status === OfferOrderFilterEnum.WaitingOnApproval
      ? OfferOrderStatusPipe.transform(status) + ' / Wird veröffentlicht'
      : OfferOrderStatusPipe.transform(status);
  return option;
});

export const generalOfferOrderFilterSettings: TableFilterSettings = {
  filter: [
    {key: 'name', label: 'Positionsname', controlType: ControlTypeEnum.TextInput},
    {key: 'externalOfferId', label: 'Anzeigennummer', controlType: ControlTypeEnum.TextInput},
    {key: 'offerOnlineDate', label: 'Online am', controlType: ControlTypeEnum.Date},
    {key: 'offerOrderNumber', label: 'Auftragsnummer', controlType: ControlTypeEnum.TextInput},
    {key: 'company', label: 'Inserentennummer/ Inserentenname', controlType: ControlTypeEnum.CompanyInput},
    {key: 'createdOnRange', label: 'Erstellt am', controlType: ControlTypeEnum.TimeSpan},
    {key: 'webPublicationDateRange', label: 'Erstveröffentlichung', controlType: ControlTypeEnum.TimeSpan},
    {
      key: 'statuses', label: 'Auftragsstatus', controlType: ControlTypeEnum.MultiSelect,
      multipleChoiceOptions: {options: Object.values(OfferOrderFilterEnum).map(value => buildStatusOption(value))}
    }
  ]
};

/* filter settings dependent on features booked */
export const sebuFilterSettings: FilterControlSetting = {
  key: 'isSebu',
  label: 'Sebu',
  controlType: ControlTypeEnum.Toggle
};
export const topJobFilterSettings: FilterControlSetting = {
  key: 'topJob',
  label: 'Top Job',
  controlType: ControlTypeEnum.Toggle
};
export const refreshFilterSettings: FilterControlSetting = {
  key: 'refresh',
  label: 'Refresh',
  controlType: ControlTypeEnum.Toggle
};
export const jobBlitzFilterSettings: FilterControlSetting = {
  key: 'jobBlitz',
  label: 'Job Blitz',
  controlType: ControlTypeEnum.Toggle
};
export const kalaydoFilterSettings: FilterControlSetting = {
  key: 'kalaydo',
  label: 'Kalaydo',
  controlType: ControlTypeEnum.Toggle
};
export const itJobsFilterSettings: FilterControlSetting = {
  key: 'itJobs',
  label: 'IT Jobs',
  controlType: ControlTypeEnum.Toggle
};

export const tenhilFilterSettings: FilterControlSetting = {
  key: 'tenhil',
  label: 'Tenhil',
  controlType: ControlTypeEnum.Toggle
};

export const socialMediaFilterSettings: FilterControlSetting = {
  key: 'socialMedia',
  label: 'Social Media',
  controlType: ControlTypeEnum.Toggle
};

export const tableEntryFilterSettings: FilterControlSetting = {
  key: 'tableEntry',
  label: 'Tabelleneintrag',
  controlType: ControlTypeEnum.Toggle
};

export const OFFER_ORDER_MANAGEMENT_KEY = 'offer-order-management';
