import { NavElement, NavLinkEnum } from './nav.model';

export class NavLinksProvider {
  private static readonly NAV_LINKS: NavElement[] = [
    { id: NavLinkEnum.home, name: 'Startseite', path: '/home'},
    { id: NavLinkEnum.offerOrderManagement, name: 'Auftragsverwaltung', path: '/offer-order', iconName: 'assignment'},
    { id: NavLinkEnum.companyManagement, name: 'Inserentenverwaltung', path: '/company', iconName: 'group'},
    { id: NavLinkEnum.chiffreAddressesManagement, name: 'Chiffre-Adressenverwaltung', path: '/chiffre-addresses', iconName: 'place'},
    { id: NavLinkEnum.downloads, name: 'Downloads', path: '/downloads', iconName: 'file_download'},
    { id: NavLinkEnum.userManagement, name: 'Benutzerverwaltung', path: '/user-administration', iconName: 'account_circle'},
    { id: NavLinkEnum.videoManagement, name: 'Videoverwaltung', path: '/video-administration', iconName: 'video_library' },
    { id: NavLinkEnum.statistics, name: 'Statistik', path: '/statistics-administration', iconName: 'insert_chart' },
    { id: NavLinkEnum.internalProductName, name: 'Verwaltung interner Produktname', path: '/internal-product-name-administration', iconName: 'inventory' },
  ];

  public static getNavLinks(): NavElement[] {
    return [].concat(NavLinksProvider.NAV_LINKS);
  }
}
