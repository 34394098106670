/**
 * Sa.Reseller.Api
 *
 * OpenAPI spec version: v2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Attachment } from '../model/attachment';
import { OpticalCharacterRecognitionResult } from '../model/opticalCharacterRecognitionResult';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { GlobalServiceInterface }                            from './global.serviceInterface';


@Injectable({
  providedIn: 'root'
})
export class GlobalService implements GlobalServiceInterface {

    protected basePath = '';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (let consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Converts a given PDF attachment to a png attachment
     * This endpoint is not async as the implementation is completely in memory and does not               require any async I/O.  - Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param pdfDocument PDF document
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public imageRecognitionConvertPdfToImage(pdfDocument: Attachment, observe?: 'body', reportProgress?: boolean): Observable<Attachment>;
    public imageRecognitionConvertPdfToImage(pdfDocument: Attachment, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Attachment>>;
    public imageRecognitionConvertPdfToImage(pdfDocument: Attachment, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Attachment>>;
    public imageRecognitionConvertPdfToImage(pdfDocument: Attachment, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (pdfDocument === null || pdfDocument === undefined) {
            throw new Error('Required parameter pdfDocument was null or undefined when calling imageRecognitionConvertPdfToImage.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<Attachment>(`${this.basePath}/api/v3/imageRecognition/convertPdfToImage`,
            pdfDocument,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Recognizes text in a given image
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param image The image in which the text should be recognized
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public recognizeCharactersInImages(image: Attachment, observe?: 'body', reportProgress?: boolean): Observable<OpticalCharacterRecognitionResult>;
    public recognizeCharactersInImages(image: Attachment, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OpticalCharacterRecognitionResult>>;
    public recognizeCharactersInImages(image: Attachment, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OpticalCharacterRecognitionResult>>;
    public recognizeCharactersInImages(image: Attachment, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (image === null || image === undefined) {
            throw new Error('Required parameter image was null or undefined when calling recognizeCharactersInImages.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<OpticalCharacterRecognitionResult>(`${this.basePath}/api/v3/imageRecognition/recognizeTextInImage`,
            image,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
