import { Component } from '@angular/core';
import {Observable} from 'rxjs';
import { BASIC_COMPANY_PROFILE_COUNTER } from '../../shared/header-display-texts';
import { DisplayTextDictionaries } from '../../../shared/utils/classes/display-text-dictionaries';
import { TableFilterValues } from '../../../shared/components/table-filter-component/table-filter.model';
import { Router } from '@angular/router';
import { ReadyForBasicCompanyProfileCounterService } from './ready-for-basic-company-profile-counter-service';
import { CompanySearchFilterBasicCompanyProfileStatus } from '../../../shared/reseller-api/client';
import { CompanySearchService } from '../../../company/shared/services/company-search/company-search.service';
import { FilterStorageService } from '../../../shared/components/table-filter-component/filter-storage/filter-storage.service';
import { COMPANY_KEY } from '../../../shared/components/company-search/company-storage-keys';
@Component({
  selector: 'app-ready-for-basic-company-profile-counter',
  templateUrl: './ready-for-basic-company-profile-counter.component.html',
  styleUrls: ['./ready-for-basic-company-profile-counter.component.scss']
})
export class ReadyForBasicCompanyProfileCounterComponent {

  numberOpenBasicCompanyProfiles$: Observable<string>;
  public displayTexts: DisplayTextDictionaries = BASIC_COMPANY_PROFILE_COUNTER;

  constructor(private readonly router: Router,
              private readonly companySearchService: CompanySearchService,
              private readonly tableFilterValuesService: FilterStorageService,
              private readonly readyForBasicCompanyProfileCounterService: ReadyForBasicCompanyProfileCounterService) {
    this.numberOpenBasicCompanyProfiles$ = this.readyForBasicCompanyProfileCounterService.getCount();
  }

  public goToTable(): void {
    this.setTableFilterValues();
    this.router.navigate(['/company']);
  }

  private setTableFilterValues(): void {
    const basicCompanyFilterValues: TableFilterValues = {
      values: [
        {
          key: 'basicCompanyProfile',
          value: [CompanySearchFilterBasicCompanyProfileStatus.Ready]
        }
      ]
    };
    this.tableFilterValuesService.storeFilterValues(basicCompanyFilterValues, COMPANY_KEY);
  }
}
