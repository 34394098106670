import * as Raven from 'raven-js';
import { ErrorHandler } from '@angular/core';
import { environment } from '../../../../environments/environment';

Raven
  .config(environment.sentryDSN)
  .install();

export class RavenErrorHandler implements ErrorHandler {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  handleError(err: any): void {
    Raven.captureException(err.originalError || err);
  }
}

export function provideErrorHandler(): RavenErrorHandler | ErrorHandler {
  return environment.production ? new RavenErrorHandler() : new ErrorHandler();
}
