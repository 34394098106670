import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiLoginService } from './shared/services/api-login.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent {

  public showHeader$: Observable<boolean>;

  // this is needed to show and hide navi & footer on the loginpage
  constructor(public router: Router,
              private apiLogin: ApiLoginService) {
    this.showHeader$ = this.apiLogin.isLoggedIn$;
  }

  public scrollToTop(): void {
    window.scrollTo(0, 0);
  }
}
