import { Inject, Injectable } from '@angular/core';
import { SARE_API_CONFIG } from '../reseller-api/sare-api.module';
import { Configuration, User, UserService } from '../reseller-api/client';
import { BehaviorSubject, Observable } from 'rxjs';
import { ResellerCacheService } from './reseller/reseller.service';
import { IndexedDatabaseService } from './indexed-database/indexed-database.service';
import { UserCookieService } from './cookies/user-cookie/user-cookie.service';
import { RENDERER_API_CONFIG } from '../renderer-api/renderer-api.module';
import { UserCookie } from './cookies/user-cookie/user-cookie';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApiLoginService {

  /**
   * Observable property that emits the loggedIn values as they change. Don't use for log in logic to avoid loops.
   * @returns {Observable<boolean>}
   */
  public get isLoggedIn$(): Observable<boolean> {
    return this._isLoggedIn$.asObservable();
  }

  /**
   * Variable used for retrieving synchronously the current logged in state. Use it to avoid observable loops
   * @returns {boolean}
   */
  public isLoggedInSnapshot = false;

  private _isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private resellerService: ResellerCacheService,
              private userApi: UserService,
              private indexedDbService: IndexedDatabaseService,
              private userCookieService: UserCookieService,
              @Inject(SARE_API_CONFIG) private sareApiConfig: Configuration,
              @Inject(RENDERER_API_CONFIG) private rendererApiConfig: Configuration) {
    this._isLoggedIn$.subscribe(loggedIn => this.isLoggedInSnapshot = loggedIn);
  }

  public login(username: string, password: string): Observable<boolean> {

    this.cleanup();
    this.sareApiConfig.username = username;
    this.sareApiConfig.password = password;
    this.rendererApiConfig.username = username;
    this.rendererApiConfig.password = password;

    return this.indexedDbService.recreateDB().pipe(
      exhaustMap(() =>
        this.userApi.userGetCurrentUser().pipe(
          tap((userData: User) => {
            // set cookies and flag
            this.userCookieService.setUserDataCookie(username, password, userData);
            this._isLoggedIn$.next(true);
          }),
          // eslint-disable-next-line
          map((userData: User) => true),
          // eslint-disable-next-line
          catchError((error) => {
            // No user data found
            return this.logout().pipe(map(() => false));
          })
        )
      )
    );
  }

  public loginWithCookie(): Observable<boolean> {
    const userCookie: UserCookie = this.userCookieService.getUserDataCookie();
    let action$: Observable<boolean>;
    if (userCookie && userCookie.userName && userCookie.password) {
      action$ = this.login(userCookie.userName, userCookie.password);
    } else {
      action$ = this.logout().pipe(map(() => false));
    }
    return action$;
  }

  public logout(): Observable<void> {
    this.sareApiConfig.username = undefined;
    this.sareApiConfig.password = undefined;
    this.rendererApiConfig.username = undefined;
    this.rendererApiConfig.password = undefined;
    this._isLoggedIn$.next(false);
    this.cleanup();
    return this.indexedDbService.deleteDB();
  }

  private cleanup(): void {
    this.userCookieService.deleteUserDataCookie();
    sessionStorage.clear();
    localStorage.clear();
    this.resellerService.resetReseller();
  }
}
