import { NgModule } from '@angular/core';
import { HeaderComponent } from './header/header.component';
import { NavComponent } from './header/nav/nav.component';
import { FooterComponent } from './footer/footer.component';
import { AppCommonsModule } from '../app-commons.module';
import { OpenOfferOrdersCounterComponent } from './header/open-offer-orders-counter/open-offer-orders-counter.component';
import { ReadyForBasicCompanyProfileCounterComponent } from './header/ready-for-basic-company-profile-counter/ready-for-basic-company-profile-counter.component';

@NgModule({
  imports: [
    AppCommonsModule
  ],
  declarations: [
    HeaderComponent,
    NavComponent,
    FooterComponent,
    OpenOfferOrdersCounterComponent,
    ReadyForBasicCompanyProfileCounterComponent
  ],
  exports: [
    HeaderComponent,
    FooterComponent
  ]
})
export class MainLayoutModule {
}
