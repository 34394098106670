<div  class="login-page-wrapper" fxLayout="row" fxLayoutAlign="center center">
  <div fxLayout="column" fxFlex="27" fxLayoutGap="2em">
    <div class="login-header">
      <img src="../images/stellenanzeigen-logo.svg" width="485" style= "text-align:left; margin-left:-6px">
    </div>
    <form name="form" (ngSubmit)="onSubmit()" fxLayout="column" fxLayoutGap="1em">
      <mat-form-field [ngClass]="{'warn': loginFailed }">
        <input matInput [(ngModel)]="user.userName" class="e2eInputUsername" name="userName" type="text" placeholder="Benutzername" required>
      </mat-form-field>
      <mat-form-field [ngClass]="{'warn': loginFailed }">
        <input matInput [(ngModel)]="user.password" class="e2eInputPassword" name="password" type="password" placeholder="Passwort" required>
        <mat-hint *ngIf="loginFailed" ><span class="warn">Passwort oder Benutzername ungültig.</span></mat-hint>
      </mat-form-field>
      <div>
        <button mat-raised-button id="login-button" class="e2eButtonLogin" type="submit" color="accent">Anmelden</button>
      </div>
    </form>
  </div>
</div>

