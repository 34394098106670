/**
 * Sa.Reseller.Api
 *
 * OpenAPI spec version: v2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Attachment } from '../model/attachment';
import { CompanyIdModel } from '../model/companyIdModel';
import { NODataPagedResultOfferListItem } from '../model/nODataPagedResultOfferListItem';
import { NODataPagedResultOfferOrderListItem } from '../model/nODataPagedResultOfferOrderListItem';
import { NODataPagedResultOfferOrderViewItem } from '../model/nODataPagedResultOfferOrderViewItem';
import { OfferOrderActionsV3 } from '../model/offerOrderActionsV3';
import { OfferOrderCommentV3 } from '../model/offerOrderCommentV3';
import { OfferOrderCorrectV3 } from '../model/offerOrderCorrectV3';
import { OfferOrderCreateV3 } from '../model/offerOrderCreateV3';
import { OfferOrderEditV3 } from '../model/offerOrderEditV3';
import { OfferOrderStandardLayoutRenderRequestV3 } from '../model/offerOrderStandardLayoutRenderRequestV3';
import { OfferOrderUpdateOfferOrderNumberV3 } from '../model/offerOrderUpdateOfferOrderNumberV3';
import { OfferOrderUpgradeV3 } from '../model/offerOrderUpgradeV3';
import { OfferOrderV3 } from '../model/offerOrderV3';
import { OfferV3 } from '../model/offerV3';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { OfferServiceInterface }                            from './offer.serviceInterface';


@Injectable({
  providedIn: 'root'
})
export class OfferService implements OfferServiceInterface {

    protected basePath = '';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (let consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Exports a CSV for offer orders by using a search filter.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager, ResellerSalesManager
     * @param offerName
     * @param externalOfferId
     * @param companySearchTerm
     * @param onlineAt
     * @param offerOrderNumber
     * @param productTypes
     * @param products
     * @param companyId
     * @param includeCanceledOfferOrders
     * @param publishedAtOrAfter
     * @param publishedAtOrBefore
     * @param createdAtOrAfter
     * @param createdAtOrBefore
     * @param offerOrderStates
     * @param topJob
     * @param tableEntry
     * @param jobBlitz
     * @param kalaydo
     * @param itJobs
     * @param tenhil
     * @param refresh
     * @param isSebu
     * @param socialMedia
     * @param workflowStates
     * @param skip
     * @param take
     * @param resellerId If you are system admin, you can restrict searches to a reseller if you set this field.  Leave empty if you want to search for ALL records or if you are not system admin.  If you are system admin and set this field to any but your own reseller id you will  get a validation error.
     * @param orderBy
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportOfferOrders(offerName?: string, externalOfferId?: number, companySearchTerm?: string, onlineAt?: Date, offerOrderNumber?: string, productTypes?: Array<string>, products?: Array<string>, companyId?: number, includeCanceledOfferOrders?: boolean, publishedAtOrAfter?: Date, publishedAtOrBefore?: Date, createdAtOrAfter?: Date, createdAtOrBefore?: Date, offerOrderStates?: Array<string>, topJob?: boolean, tableEntry?: boolean, jobBlitz?: boolean, kalaydo?: boolean, itJobs?: boolean, tenhil?: boolean, refresh?: boolean, isSebu?: boolean, socialMedia?: boolean, workflowStates?: Array<string>, skip?: number, take?: number, resellerId?: number, orderBy?: 'ByOfferOrderNumberASC' | 'ByOfferOrderNumberDESC' | 'ByCompanyNumberASC' | 'ByCompanyNumberDESC' | 'ByTopJobASC' | 'ByTopJobDESC' | 'ByJobBlitzASC' | 'ByJobBlitzDESC' | 'ByRefreshASC' | 'ByRefreshDESC' | 'ByTableEntryASC' | 'ByTableEntryDESC' | 'ByOfferOrderStatusASC' | 'ByOfferOrderStatusDESC' | 'ByProductTypeASC' | 'ByProductTypeDESC' | 'ByPublishDateASC' | 'ByPublishDateDESC' | 'ByCreatedAtASC' | 'ByCreatedAtDESC' | 'ByCompanyNameASC' | 'ByCompanyNameDESC' | 'ByProductVariantASC' | 'ByProductVariantDESC' | 'ByKalaydoASC' | 'ByKalaydoDESC' | 'ByItJobsASC' | 'ByItJobsDESC', observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public exportOfferOrders(offerName?: string, externalOfferId?: number, companySearchTerm?: string, onlineAt?: Date, offerOrderNumber?: string, productTypes?: Array<string>, products?: Array<string>, companyId?: number, includeCanceledOfferOrders?: boolean, publishedAtOrAfter?: Date, publishedAtOrBefore?: Date, createdAtOrAfter?: Date, createdAtOrBefore?: Date, offerOrderStates?: Array<string>, topJob?: boolean, tableEntry?: boolean, jobBlitz?: boolean, kalaydo?: boolean, itJobs?: boolean, tenhil?: boolean, refresh?: boolean, isSebu?: boolean, socialMedia?: boolean, workflowStates?: Array<string>, skip?: number, take?: number, resellerId?: number, orderBy?: 'ByOfferOrderNumberASC' | 'ByOfferOrderNumberDESC' | 'ByCompanyNumberASC' | 'ByCompanyNumberDESC' | 'ByTopJobASC' | 'ByTopJobDESC' | 'ByJobBlitzASC' | 'ByJobBlitzDESC' | 'ByRefreshASC' | 'ByRefreshDESC' | 'ByTableEntryASC' | 'ByTableEntryDESC' | 'ByOfferOrderStatusASC' | 'ByOfferOrderStatusDESC' | 'ByProductTypeASC' | 'ByProductTypeDESC' | 'ByPublishDateASC' | 'ByPublishDateDESC' | 'ByCreatedAtASC' | 'ByCreatedAtDESC' | 'ByCompanyNameASC' | 'ByCompanyNameDESC' | 'ByProductVariantASC' | 'ByProductVariantDESC' | 'ByKalaydoASC' | 'ByKalaydoDESC' | 'ByItJobsASC' | 'ByItJobsDESC', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public exportOfferOrders(offerName?: string, externalOfferId?: number, companySearchTerm?: string, onlineAt?: Date, offerOrderNumber?: string, productTypes?: Array<string>, products?: Array<string>, companyId?: number, includeCanceledOfferOrders?: boolean, publishedAtOrAfter?: Date, publishedAtOrBefore?: Date, createdAtOrAfter?: Date, createdAtOrBefore?: Date, offerOrderStates?: Array<string>, topJob?: boolean, tableEntry?: boolean, jobBlitz?: boolean, kalaydo?: boolean, itJobs?: boolean, tenhil?: boolean, refresh?: boolean, isSebu?: boolean, socialMedia?: boolean, workflowStates?: Array<string>, skip?: number, take?: number, resellerId?: number, orderBy?: 'ByOfferOrderNumberASC' | 'ByOfferOrderNumberDESC' | 'ByCompanyNumberASC' | 'ByCompanyNumberDESC' | 'ByTopJobASC' | 'ByTopJobDESC' | 'ByJobBlitzASC' | 'ByJobBlitzDESC' | 'ByRefreshASC' | 'ByRefreshDESC' | 'ByTableEntryASC' | 'ByTableEntryDESC' | 'ByOfferOrderStatusASC' | 'ByOfferOrderStatusDESC' | 'ByProductTypeASC' | 'ByProductTypeDESC' | 'ByPublishDateASC' | 'ByPublishDateDESC' | 'ByCreatedAtASC' | 'ByCreatedAtDESC' | 'ByCompanyNameASC' | 'ByCompanyNameDESC' | 'ByProductVariantASC' | 'ByProductVariantDESC' | 'ByKalaydoASC' | 'ByKalaydoDESC' | 'ByItJobsASC' | 'ByItJobsDESC', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public exportOfferOrders(offerName?: string, externalOfferId?: number, companySearchTerm?: string, onlineAt?: Date, offerOrderNumber?: string, productTypes?: Array<string>, products?: Array<string>, companyId?: number, includeCanceledOfferOrders?: boolean, publishedAtOrAfter?: Date, publishedAtOrBefore?: Date, createdAtOrAfter?: Date, createdAtOrBefore?: Date, offerOrderStates?: Array<string>, topJob?: boolean, tableEntry?: boolean, jobBlitz?: boolean, kalaydo?: boolean, itJobs?: boolean, tenhil?: boolean, refresh?: boolean, isSebu?: boolean, socialMedia?: boolean, workflowStates?: Array<string>, skip?: number, take?: number, resellerId?: number, orderBy?: 'ByOfferOrderNumberASC' | 'ByOfferOrderNumberDESC' | 'ByCompanyNumberASC' | 'ByCompanyNumberDESC' | 'ByTopJobASC' | 'ByTopJobDESC' | 'ByJobBlitzASC' | 'ByJobBlitzDESC' | 'ByRefreshASC' | 'ByRefreshDESC' | 'ByTableEntryASC' | 'ByTableEntryDESC' | 'ByOfferOrderStatusASC' | 'ByOfferOrderStatusDESC' | 'ByProductTypeASC' | 'ByProductTypeDESC' | 'ByPublishDateASC' | 'ByPublishDateDESC' | 'ByCreatedAtASC' | 'ByCreatedAtDESC' | 'ByCompanyNameASC' | 'ByCompanyNameDESC' | 'ByProductVariantASC' | 'ByProductVariantDESC' | 'ByKalaydoASC' | 'ByKalaydoDESC' | 'ByItJobsASC' | 'ByItJobsDESC', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (offerName !== undefined) {
            queryParameters = queryParameters.set('offerName', <any>offerName);
        }
        if (externalOfferId !== undefined) {
            queryParameters = queryParameters.set('externalOfferId', <any>externalOfferId);
        }
        if (companySearchTerm !== undefined) {
            queryParameters = queryParameters.set('companySearchTerm', <any>companySearchTerm);
        }
        if (onlineAt !== undefined) {
            queryParameters = queryParameters.set('onlineAt', <any>onlineAt.toISOString());
        }
        if (offerOrderNumber !== undefined) {
            queryParameters = queryParameters.set('offerOrderNumber', <any>offerOrderNumber);
        }
        if (productTypes) {
            productTypes.forEach((element) => {
                queryParameters = queryParameters.append('productTypes', <any>element);
            })
        }
        if (products) {
            products.forEach((element) => {
                queryParameters = queryParameters.append('products', <any>element);
            })
        }
        if (companyId !== undefined) {
            queryParameters = queryParameters.set('companyId', <any>companyId);
        }
        if (includeCanceledOfferOrders !== undefined) {
            queryParameters = queryParameters.set('includeCanceledOfferOrders', <any>includeCanceledOfferOrders);
        }
        if (publishedAtOrAfter !== undefined) {
            queryParameters = queryParameters.set('publishedAtOrAfter', <any>publishedAtOrAfter.toISOString());
        }
        if (publishedAtOrBefore !== undefined) {
            queryParameters = queryParameters.set('publishedAtOrBefore', <any>publishedAtOrBefore.toISOString());
        }
        if (createdAtOrAfter !== undefined) {
            queryParameters = queryParameters.set('createdAtOrAfter', <any>createdAtOrAfter.toISOString());
        }
        if (createdAtOrBefore !== undefined) {
            queryParameters = queryParameters.set('createdAtOrBefore', <any>createdAtOrBefore.toISOString());
        }
        if (offerOrderStates) {
            offerOrderStates.forEach((element) => {
                queryParameters = queryParameters.append('offerOrderStates', <any>element);
            })
        }
        if (topJob !== undefined) {
            queryParameters = queryParameters.set('topJob', <any>topJob);
        }
        if (tableEntry !== undefined) {
            queryParameters = queryParameters.set('tableEntry', <any>tableEntry);
        }
        if (jobBlitz !== undefined) {
            queryParameters = queryParameters.set('jobBlitz', <any>jobBlitz);
        }
        if (kalaydo !== undefined) {
            queryParameters = queryParameters.set('kalaydo', <any>kalaydo);
        }
        if (itJobs !== undefined) {
            queryParameters = queryParameters.set('itJobs', <any>itJobs);
        }
        if (tenhil !== undefined) {
            queryParameters = queryParameters.set('tenhil', <any>tenhil);
        }
        if (refresh !== undefined) {
            queryParameters = queryParameters.set('refresh', <any>refresh);
        }
        if (isSebu !== undefined) {
            queryParameters = queryParameters.set('isSebu', <any>isSebu);
        }
        if (socialMedia !== undefined) {
            queryParameters = queryParameters.set('socialMedia', <any>socialMedia);
        }
        if (workflowStates) {
            workflowStates.forEach((element) => {
                queryParameters = queryParameters.append('workflowStates', <any>element);
            })
        }
        if (skip !== undefined) {
            queryParameters = queryParameters.set('skip', <any>skip);
        }
        if (take !== undefined) {
            queryParameters = queryParameters.set('take', <any>take);
        }
        if (resellerId !== undefined) {
            queryParameters = queryParameters.set('resellerId', <any>resellerId);
        }
        if (orderBy !== undefined) {
            queryParameters = queryParameters.set('orderBy', <any>orderBy);
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get(`${this.basePath}/api/v3/offerOrders/csv`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Exports a CSV for offers by using a search filter.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager, ResellerSalesManager
     * @param offerName
     * @param externalOfferId
     * @param companySearchTerm
     * @param onlineAt
     * @param offerOrderNumber
     * @param offerStates
     * @param workflowStates
     * @param products
     * @param productTypes
     * @param productVariants
     * @param originApiClients
     * @param companyId
     * @param isSebuOffer
     * @param includeCanceledOfferOrders
     * @param publishedAtOrAfter
     * @param publishedAtOrBefore
     * @param createdAtOrAfter
     * @param createdAtOrBefore
     * @param offerOrderStates
     * @param topJob
     * @param jobBlitz
     * @param kalaydo
     * @param itJobs
     * @param refresh
     * @param skip
     * @param take
     * @param resellerId If you are system admin, you can restrict searches to a reseller if you set this field.  Leave empty if you want to search for ALL records or if you are not system admin.  If you are system admin and set this field to any but your own reseller id you will  get a validation error.
     * @param orderBy
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportOffers(offerName?: string, externalOfferId?: number, companySearchTerm?: string, onlineAt?: Date, offerOrderNumber?: string, offerStates?: Array<string>, workflowStates?: Array<string>, products?: Array<string>, productTypes?: Array<string>, productVariants?: Array<string>, originApiClients?: Array<string>, companyId?: number, isSebuOffer?: boolean, includeCanceledOfferOrders?: boolean, publishedAtOrAfter?: Date, publishedAtOrBefore?: Date, createdAtOrAfter?: Date, createdAtOrBefore?: Date, offerOrderStates?: Array<string>, topJob?: boolean, jobBlitz?: boolean, kalaydo?: boolean, itJobs?: boolean, refresh?: boolean, skip?: number, take?: number, resellerId?: number, orderBy?: 'ByPositionNameASC' | 'ByPositionNameDESC' | 'ByOfferOrderNumberASC' | 'ByOfferOrderNumberDESC' | 'ByCompanyNumberASC' | 'ByCompanyNumberDESC' | 'ByOfferStatusASC' | 'ByOfferStatusDESC' | 'ByLocationASC' | 'ByLocationDESC' | 'ByStartDateASC' | 'ByStartDateDESC' | 'ByEndDateASC' | 'ByEndDateDESC' | 'ByExternalOfferIdASC' | 'ByExternalOfferIdDESC' | 'ByClicksASC' | 'ByClicksDESC' | 'ByCreatedAtASC' | 'ByCreatedAtDESC' | 'ByPublishDateASC' | 'ByPublishDateDESC' | 'ByOfferOrderStatusASC' | 'ByOfferOrderStatusDESC' | 'ByTopJobASC' | 'ByTopJobDESC' | 'ByJobBlitzASC' | 'ByJobBlitzDESC' | 'ByKalaydoASC' | 'ByKalaydoDESC' | 'ByItJobsASC' | 'ByItJobsDESC', observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public exportOffers(offerName?: string, externalOfferId?: number, companySearchTerm?: string, onlineAt?: Date, offerOrderNumber?: string, offerStates?: Array<string>, workflowStates?: Array<string>, products?: Array<string>, productTypes?: Array<string>, productVariants?: Array<string>, originApiClients?: Array<string>, companyId?: number, isSebuOffer?: boolean, includeCanceledOfferOrders?: boolean, publishedAtOrAfter?: Date, publishedAtOrBefore?: Date, createdAtOrAfter?: Date, createdAtOrBefore?: Date, offerOrderStates?: Array<string>, topJob?: boolean, jobBlitz?: boolean, kalaydo?: boolean, itJobs?: boolean, refresh?: boolean, skip?: number, take?: number, resellerId?: number, orderBy?: 'ByPositionNameASC' | 'ByPositionNameDESC' | 'ByOfferOrderNumberASC' | 'ByOfferOrderNumberDESC' | 'ByCompanyNumberASC' | 'ByCompanyNumberDESC' | 'ByOfferStatusASC' | 'ByOfferStatusDESC' | 'ByLocationASC' | 'ByLocationDESC' | 'ByStartDateASC' | 'ByStartDateDESC' | 'ByEndDateASC' | 'ByEndDateDESC' | 'ByExternalOfferIdASC' | 'ByExternalOfferIdDESC' | 'ByClicksASC' | 'ByClicksDESC' | 'ByCreatedAtASC' | 'ByCreatedAtDESC' | 'ByPublishDateASC' | 'ByPublishDateDESC' | 'ByOfferOrderStatusASC' | 'ByOfferOrderStatusDESC' | 'ByTopJobASC' | 'ByTopJobDESC' | 'ByJobBlitzASC' | 'ByJobBlitzDESC' | 'ByKalaydoASC' | 'ByKalaydoDESC' | 'ByItJobsASC' | 'ByItJobsDESC', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public exportOffers(offerName?: string, externalOfferId?: number, companySearchTerm?: string, onlineAt?: Date, offerOrderNumber?: string, offerStates?: Array<string>, workflowStates?: Array<string>, products?: Array<string>, productTypes?: Array<string>, productVariants?: Array<string>, originApiClients?: Array<string>, companyId?: number, isSebuOffer?: boolean, includeCanceledOfferOrders?: boolean, publishedAtOrAfter?: Date, publishedAtOrBefore?: Date, createdAtOrAfter?: Date, createdAtOrBefore?: Date, offerOrderStates?: Array<string>, topJob?: boolean, jobBlitz?: boolean, kalaydo?: boolean, itJobs?: boolean, refresh?: boolean, skip?: number, take?: number, resellerId?: number, orderBy?: 'ByPositionNameASC' | 'ByPositionNameDESC' | 'ByOfferOrderNumberASC' | 'ByOfferOrderNumberDESC' | 'ByCompanyNumberASC' | 'ByCompanyNumberDESC' | 'ByOfferStatusASC' | 'ByOfferStatusDESC' | 'ByLocationASC' | 'ByLocationDESC' | 'ByStartDateASC' | 'ByStartDateDESC' | 'ByEndDateASC' | 'ByEndDateDESC' | 'ByExternalOfferIdASC' | 'ByExternalOfferIdDESC' | 'ByClicksASC' | 'ByClicksDESC' | 'ByCreatedAtASC' | 'ByCreatedAtDESC' | 'ByPublishDateASC' | 'ByPublishDateDESC' | 'ByOfferOrderStatusASC' | 'ByOfferOrderStatusDESC' | 'ByTopJobASC' | 'ByTopJobDESC' | 'ByJobBlitzASC' | 'ByJobBlitzDESC' | 'ByKalaydoASC' | 'ByKalaydoDESC' | 'ByItJobsASC' | 'ByItJobsDESC', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public exportOffers(offerName?: string, externalOfferId?: number, companySearchTerm?: string, onlineAt?: Date, offerOrderNumber?: string, offerStates?: Array<string>, workflowStates?: Array<string>, products?: Array<string>, productTypes?: Array<string>, productVariants?: Array<string>, originApiClients?: Array<string>, companyId?: number, isSebuOffer?: boolean, includeCanceledOfferOrders?: boolean, publishedAtOrAfter?: Date, publishedAtOrBefore?: Date, createdAtOrAfter?: Date, createdAtOrBefore?: Date, offerOrderStates?: Array<string>, topJob?: boolean, jobBlitz?: boolean, kalaydo?: boolean, itJobs?: boolean, refresh?: boolean, skip?: number, take?: number, resellerId?: number, orderBy?: 'ByPositionNameASC' | 'ByPositionNameDESC' | 'ByOfferOrderNumberASC' | 'ByOfferOrderNumberDESC' | 'ByCompanyNumberASC' | 'ByCompanyNumberDESC' | 'ByOfferStatusASC' | 'ByOfferStatusDESC' | 'ByLocationASC' | 'ByLocationDESC' | 'ByStartDateASC' | 'ByStartDateDESC' | 'ByEndDateASC' | 'ByEndDateDESC' | 'ByExternalOfferIdASC' | 'ByExternalOfferIdDESC' | 'ByClicksASC' | 'ByClicksDESC' | 'ByCreatedAtASC' | 'ByCreatedAtDESC' | 'ByPublishDateASC' | 'ByPublishDateDESC' | 'ByOfferOrderStatusASC' | 'ByOfferOrderStatusDESC' | 'ByTopJobASC' | 'ByTopJobDESC' | 'ByJobBlitzASC' | 'ByJobBlitzDESC' | 'ByKalaydoASC' | 'ByKalaydoDESC' | 'ByItJobsASC' | 'ByItJobsDESC', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (offerName !== undefined) {
            queryParameters = queryParameters.set('offerName', <any>offerName);
        }
        if (externalOfferId !== undefined) {
            queryParameters = queryParameters.set('externalOfferId', <any>externalOfferId);
        }
        if (companySearchTerm !== undefined) {
            queryParameters = queryParameters.set('companySearchTerm', <any>companySearchTerm);
        }
        if (onlineAt !== undefined) {
            queryParameters = queryParameters.set('onlineAt', <any>onlineAt.toISOString());
        }
        if (offerOrderNumber !== undefined) {
            queryParameters = queryParameters.set('offerOrderNumber', <any>offerOrderNumber);
        }
        if (offerStates) {
            offerStates.forEach((element) => {
                queryParameters = queryParameters.append('offerStates', <any>element);
            })
        }
        if (workflowStates) {
            workflowStates.forEach((element) => {
                queryParameters = queryParameters.append('workflowStates', <any>element);
            })
        }
        if (products) {
            products.forEach((element) => {
                queryParameters = queryParameters.append('products', <any>element);
            })
        }
        if (productTypes) {
            productTypes.forEach((element) => {
                queryParameters = queryParameters.append('productTypes', <any>element);
            })
        }
        if (productVariants) {
            productVariants.forEach((element) => {
                queryParameters = queryParameters.append('productVariants', <any>element);
            })
        }
        if (originApiClients) {
            originApiClients.forEach((element) => {
                queryParameters = queryParameters.append('originApiClients', <any>element);
            })
        }
        if (companyId !== undefined) {
            queryParameters = queryParameters.set('companyId', <any>companyId);
        }
        if (isSebuOffer !== undefined) {
            queryParameters = queryParameters.set('isSebuOffer', <any>isSebuOffer);
        }
        if (includeCanceledOfferOrders !== undefined) {
            queryParameters = queryParameters.set('includeCanceledOfferOrders', <any>includeCanceledOfferOrders);
        }
        if (publishedAtOrAfter !== undefined) {
            queryParameters = queryParameters.set('publishedAtOrAfter', <any>publishedAtOrAfter.toISOString());
        }
        if (publishedAtOrBefore !== undefined) {
            queryParameters = queryParameters.set('publishedAtOrBefore', <any>publishedAtOrBefore.toISOString());
        }
        if (createdAtOrAfter !== undefined) {
            queryParameters = queryParameters.set('createdAtOrAfter', <any>createdAtOrAfter.toISOString());
        }
        if (createdAtOrBefore !== undefined) {
            queryParameters = queryParameters.set('createdAtOrBefore', <any>createdAtOrBefore.toISOString());
        }
        if (offerOrderStates) {
            offerOrderStates.forEach((element) => {
                queryParameters = queryParameters.append('offerOrderStates', <any>element);
            })
        }
        if (topJob !== undefined) {
            queryParameters = queryParameters.set('topJob', <any>topJob);
        }
        if (jobBlitz !== undefined) {
            queryParameters = queryParameters.set('jobBlitz', <any>jobBlitz);
        }
        if (kalaydo !== undefined) {
            queryParameters = queryParameters.set('kalaydo', <any>kalaydo);
        }
        if (itJobs !== undefined) {
            queryParameters = queryParameters.set('itJobs', <any>itJobs);
        }
        if (refresh !== undefined) {
            queryParameters = queryParameters.set('refresh', <any>refresh);
        }
        if (skip !== undefined) {
            queryParameters = queryParameters.set('skip', <any>skip);
        }
        if (take !== undefined) {
            queryParameters = queryParameters.set('take', <any>take);
        }
        if (resellerId !== undefined) {
            queryParameters = queryParameters.set('resellerId', <any>resellerId);
        }
        if (orderBy !== undefined) {
            queryParameters = queryParameters.set('orderBy', <any>orderBy);
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get(`${this.basePath}/api/v3/offers/csv`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the OfferOrder attachment.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param offerOrderId The offer order id.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOfferOrderAttachment(offerOrderId: number, observe?: 'body', reportProgress?: boolean): Observable<Attachment>;
    public getOfferOrderAttachment(offerOrderId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Attachment>>;
    public getOfferOrderAttachment(offerOrderId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Attachment>>;
    public getOfferOrderAttachment(offerOrderId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (offerOrderId === null || offerOrderId === undefined) {
            throw new Error('Required parameter offerOrderId was null or undefined when calling getOfferOrderAttachment.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Attachment>(`${this.basePath}/api/v3/offerOrders/${encodeURIComponent(String(offerOrderId))}/attachment`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Activates an Offer Order and its Offers.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param offerOrderId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public offerOrderV3ActivateOfferOrderAsync(offerOrderId: number, observe?: 'body', reportProgress?: boolean): Observable<OfferOrderV3>;
    public offerOrderV3ActivateOfferOrderAsync(offerOrderId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OfferOrderV3>>;
    public offerOrderV3ActivateOfferOrderAsync(offerOrderId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OfferOrderV3>>;
    public offerOrderV3ActivateOfferOrderAsync(offerOrderId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (offerOrderId === null || offerOrderId === undefined) {
            throw new Error('Required parameter offerOrderId was null or undefined when calling offerOrderV3ActivateOfferOrderAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.put<OfferOrderV3>(`${this.basePath}/api/v3/offerOrders/${encodeURIComponent(String(offerOrderId))}/activate`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Cancels an Offer Order.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param offerOrderId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public offerOrderV3CancelOfferOrderAsync(offerOrderId: number, observe?: 'body', reportProgress?: boolean): Observable<OfferOrderV3>;
    public offerOrderV3CancelOfferOrderAsync(offerOrderId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OfferOrderV3>>;
    public offerOrderV3CancelOfferOrderAsync(offerOrderId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OfferOrderV3>>;
    public offerOrderV3CancelOfferOrderAsync(offerOrderId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (offerOrderId === null || offerOrderId === undefined) {
            throw new Error('Required parameter offerOrderId was null or undefined when calling offerOrderV3CancelOfferOrderAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.put<OfferOrderV3>(`${this.basePath}/api/v3/offerOrders/${encodeURIComponent(String(offerOrderId))}/cancel`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Corrects an Offer Order.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param offerOrderCorrect
     * @param offerOrderId
     * @param updateRenderData
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public offerOrderV3CorrectOfferOrderAsync(offerOrderCorrect: OfferOrderCorrectV3, offerOrderId: number, updateRenderData: boolean, observe?: 'body', reportProgress?: boolean): Observable<OfferOrderV3>;
    public offerOrderV3CorrectOfferOrderAsync(offerOrderCorrect: OfferOrderCorrectV3, offerOrderId: number, updateRenderData: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OfferOrderV3>>;
    public offerOrderV3CorrectOfferOrderAsync(offerOrderCorrect: OfferOrderCorrectV3, offerOrderId: number, updateRenderData: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OfferOrderV3>>;
    public offerOrderV3CorrectOfferOrderAsync(offerOrderCorrect: OfferOrderCorrectV3, offerOrderId: number, updateRenderData: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (offerOrderCorrect === null || offerOrderCorrect === undefined) {
            throw new Error('Required parameter offerOrderCorrect was null or undefined when calling offerOrderV3CorrectOfferOrderAsync.');
        }
        if (offerOrderId === null || offerOrderId === undefined) {
            throw new Error('Required parameter offerOrderId was null or undefined when calling offerOrderV3CorrectOfferOrderAsync.');
        }
        if (updateRenderData === null || updateRenderData === undefined) {
            throw new Error('Required parameter updateRenderData was null or undefined when calling offerOrderV3CorrectOfferOrderAsync.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateRenderData !== undefined) {
            queryParameters = queryParameters.set('updateRenderData', <any>updateRenderData);
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<OfferOrderV3>(`${this.basePath}/api/v3/offerOrders/${encodeURIComponent(String(offerOrderId))}/correct`,
            offerOrderCorrect,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates an Offer Order.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param offerOrderCreate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public offerOrderV3CreateOfferOrderAsync(offerOrderCreate: OfferOrderCreateV3, observe?: 'body', reportProgress?: boolean): Observable<OfferOrderV3>;
    public offerOrderV3CreateOfferOrderAsync(offerOrderCreate: OfferOrderCreateV3, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OfferOrderV3>>;
    public offerOrderV3CreateOfferOrderAsync(offerOrderCreate: OfferOrderCreateV3, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OfferOrderV3>>;
    public offerOrderV3CreateOfferOrderAsync(offerOrderCreate: OfferOrderCreateV3, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (offerOrderCreate === null || offerOrderCreate === undefined) {
            throw new Error('Required parameter offerOrderCreate was null or undefined when calling offerOrderV3CreateOfferOrderAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<OfferOrderV3>(`${this.basePath}/api/v3/offerOrders`,
            offerOrderCreate,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deactivates an Offer Order and its Offers.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param offerOrderId
     * @param offerOrderCommentedAction
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public offerOrderV3DeactivateOfferOrderAsync(offerOrderId: number, offerOrderCommentedAction: OfferOrderCommentV3, observe?: 'body', reportProgress?: boolean): Observable<OfferOrderV3>;
    public offerOrderV3DeactivateOfferOrderAsync(offerOrderId: number, offerOrderCommentedAction: OfferOrderCommentV3, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OfferOrderV3>>;
    public offerOrderV3DeactivateOfferOrderAsync(offerOrderId: number, offerOrderCommentedAction: OfferOrderCommentV3, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OfferOrderV3>>;
    public offerOrderV3DeactivateOfferOrderAsync(offerOrderId: number, offerOrderCommentedAction: OfferOrderCommentV3, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (offerOrderId === null || offerOrderId === undefined) {
            throw new Error('Required parameter offerOrderId was null or undefined when calling offerOrderV3DeactivateOfferOrderAsync.');
        }
        if (offerOrderCommentedAction === null || offerOrderCommentedAction === undefined) {
            throw new Error('Required parameter offerOrderCommentedAction was null or undefined when calling offerOrderV3DeactivateOfferOrderAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<OfferOrderV3>(`${this.basePath}/api/v3/offerOrders/${encodeURIComponent(String(offerOrderId))}/deactivate`,
            offerOrderCommentedAction,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes the offerOrder.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param offerOrderId The offer order id.
     * @param deleteSebuCompany Delete the SEBU company.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public offerOrderV3DeleteAsync(offerOrderId: number, deleteSebuCompany?: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public offerOrderV3DeleteAsync(offerOrderId: number, deleteSebuCompany?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public offerOrderV3DeleteAsync(offerOrderId: number, deleteSebuCompany?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public offerOrderV3DeleteAsync(offerOrderId: number, deleteSebuCompany?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (offerOrderId === null || offerOrderId === undefined) {
            throw new Error('Required parameter offerOrderId was null or undefined when calling offerOrderV3DeleteAsync.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (deleteSebuCompany !== undefined) {
            queryParameters = queryParameters.set('deleteSebuCompany', <any>deleteSebuCompany);
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/v3/offerOrders/${encodeURIComponent(String(offerOrderId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Edits the OfferOrder attachment.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param offerOrderId The Offer Order ID.
     * @param zipToken A token for fetching the rendered offer order from the renderer.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public offerOrderV3EditAttachmentByZipTokenOfferOrderAsync(offerOrderId: number, zipToken: string, observe?: 'body', reportProgress?: boolean): Observable<OfferOrderV3>;
    public offerOrderV3EditAttachmentByZipTokenOfferOrderAsync(offerOrderId: number, zipToken: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OfferOrderV3>>;
    public offerOrderV3EditAttachmentByZipTokenOfferOrderAsync(offerOrderId: number, zipToken: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OfferOrderV3>>;
    public offerOrderV3EditAttachmentByZipTokenOfferOrderAsync(offerOrderId: number, zipToken: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (offerOrderId === null || offerOrderId === undefined) {
            throw new Error('Required parameter offerOrderId was null or undefined when calling offerOrderV3EditAttachmentByZipTokenOfferOrderAsync.');
        }
        if (zipToken === null || zipToken === undefined) {
            throw new Error('Required parameter zipToken was null or undefined when calling offerOrderV3EditAttachmentByZipTokenOfferOrderAsync.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (zipToken !== undefined) {
            queryParameters = queryParameters.set('zipToken', <any>zipToken);
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.put<OfferOrderV3>(`${this.basePath}/api/v3/offerOrders/${encodeURIComponent(String(offerOrderId))}/zipToken`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Edits the OfferOrder attachment.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param offerOrderId The Offer Order ID.
     * @param attachment The Offer Order attachment
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public offerOrderV3EditAttachmentOfferOrderAsync(offerOrderId: number, attachment: Attachment, observe?: 'body', reportProgress?: boolean): Observable<OfferOrderV3>;
    public offerOrderV3EditAttachmentOfferOrderAsync(offerOrderId: number, attachment: Attachment, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OfferOrderV3>>;
    public offerOrderV3EditAttachmentOfferOrderAsync(offerOrderId: number, attachment: Attachment, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OfferOrderV3>>;
    public offerOrderV3EditAttachmentOfferOrderAsync(offerOrderId: number, attachment: Attachment, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (offerOrderId === null || offerOrderId === undefined) {
            throw new Error('Required parameter offerOrderId was null or undefined when calling offerOrderV3EditAttachmentOfferOrderAsync.');
        }
        if (attachment === null || attachment === undefined) {
            throw new Error('Required parameter attachment was null or undefined when calling offerOrderV3EditAttachmentOfferOrderAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<OfferOrderV3>(`${this.basePath}/api/v3/offerOrders/${encodeURIComponent(String(offerOrderId))}/attachment`,
            attachment,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Edits an Offer Order.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param offerOrderEdit The object containing the changes
     * @param offerOrderId The id of the offer order
     * @param updateRenderData If true, update the render data as well. Setting this to true and RenderData to null will delete the render data from the offer  order.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public offerOrderV3EditOfferOrderAsync(offerOrderEdit: OfferOrderEditV3, offerOrderId: number, updateRenderData: boolean, observe?: 'body', reportProgress?: boolean): Observable<OfferOrderV3>;
    public offerOrderV3EditOfferOrderAsync(offerOrderEdit: OfferOrderEditV3, offerOrderId: number, updateRenderData: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OfferOrderV3>>;
    public offerOrderV3EditOfferOrderAsync(offerOrderEdit: OfferOrderEditV3, offerOrderId: number, updateRenderData: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OfferOrderV3>>;
    public offerOrderV3EditOfferOrderAsync(offerOrderEdit: OfferOrderEditV3, offerOrderId: number, updateRenderData: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (offerOrderEdit === null || offerOrderEdit === undefined) {
            throw new Error('Required parameter offerOrderEdit was null or undefined when calling offerOrderV3EditOfferOrderAsync.');
        }
        if (offerOrderId === null || offerOrderId === undefined) {
            throw new Error('Required parameter offerOrderId was null or undefined when calling offerOrderV3EditOfferOrderAsync.');
        }
        if (updateRenderData === null || updateRenderData === undefined) {
            throw new Error('Required parameter updateRenderData was null or undefined when calling offerOrderV3EditOfferOrderAsync.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateRenderData !== undefined) {
            queryParameters = queryParameters.set('updateRenderData', <any>updateRenderData);
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<OfferOrderV3>(`${this.basePath}/api/v3/offerOrders/${encodeURIComponent(String(offerOrderId))}/edit`,
            offerOrderEdit,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets all possible Offer Order actions.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager, ResellerSalesManager
     * @param offerOrderId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public offerOrderV3GetOfferOrderActionsAsync(offerOrderId: number, observe?: 'body', reportProgress?: boolean): Observable<OfferOrderActionsV3>;
    public offerOrderV3GetOfferOrderActionsAsync(offerOrderId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OfferOrderActionsV3>>;
    public offerOrderV3GetOfferOrderActionsAsync(offerOrderId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OfferOrderActionsV3>>;
    public offerOrderV3GetOfferOrderActionsAsync(offerOrderId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (offerOrderId === null || offerOrderId === undefined) {
            throw new Error('Required parameter offerOrderId was null or undefined when calling offerOrderV3GetOfferOrderActionsAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<OfferOrderActionsV3>(`${this.basePath}/api/v3/offerOrders/${encodeURIComponent(String(offerOrderId))}/actions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets an Offer Order.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager, ResellerSalesManager
     * @param offerOrderId
     * @param includeRenderData
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public offerOrderV3GetOfferOrderAsync(offerOrderId: number, includeRenderData: boolean, observe?: 'body', reportProgress?: boolean): Observable<OfferOrderV3>;
    public offerOrderV3GetOfferOrderAsync(offerOrderId: number, includeRenderData: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OfferOrderV3>>;
    public offerOrderV3GetOfferOrderAsync(offerOrderId: number, includeRenderData: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OfferOrderV3>>;
    public offerOrderV3GetOfferOrderAsync(offerOrderId: number, includeRenderData: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (offerOrderId === null || offerOrderId === undefined) {
            throw new Error('Required parameter offerOrderId was null or undefined when calling offerOrderV3GetOfferOrderAsync.');
        }
        if (includeRenderData === null || includeRenderData === undefined) {
            throw new Error('Required parameter includeRenderData was null or undefined when calling offerOrderV3GetOfferOrderAsync.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (includeRenderData !== undefined) {
            queryParameters = queryParameters.set('includeRenderData', <any>includeRenderData);
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<OfferOrderV3>(`${this.basePath}/api/v3/offerOrders/${encodeURIComponent(String(offerOrderId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets offer order id by offer order number.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager, ResellerSalesManager
     * @param offerOrderNumber
     * @param companyId
     * @param resellerId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public offerOrderV3GetOfferOrderIdAsync(offerOrderNumber: string, companyId: number, resellerId: number, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public offerOrderV3GetOfferOrderIdAsync(offerOrderNumber: string, companyId: number, resellerId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public offerOrderV3GetOfferOrderIdAsync(offerOrderNumber: string, companyId: number, resellerId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public offerOrderV3GetOfferOrderIdAsync(offerOrderNumber: string, companyId: number, resellerId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (offerOrderNumber === null || offerOrderNumber === undefined) {
            throw new Error('Required parameter offerOrderNumber was null or undefined when calling offerOrderV3GetOfferOrderIdAsync.');
        }
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling offerOrderV3GetOfferOrderIdAsync.');
        }
        if (resellerId === null || resellerId === undefined) {
            throw new Error('Required parameter resellerId was null or undefined when calling offerOrderV3GetOfferOrderIdAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<number>(`${this.basePath}/api/v3/resellers/${encodeURIComponent(String(resellerId))}/companies/${encodeURIComponent(String(companyId))}/offerOrders/${encodeURIComponent(String(offerOrderNumber))}/offerOrderId`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets all possible Offer Order upgrades.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager, ResellerSalesManager
     * @param offerOrderId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public offerOrderV3GetOfferOrderUpgradesAsync(offerOrderId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public offerOrderV3GetOfferOrderUpgradesAsync(offerOrderId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public offerOrderV3GetOfferOrderUpgradesAsync(offerOrderId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public offerOrderV3GetOfferOrderUpgradesAsync(offerOrderId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (offerOrderId === null || offerOrderId === undefined) {
            throw new Error('Required parameter offerOrderId was null or undefined when calling offerOrderV3GetOfferOrderUpgradesAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<string>>(`${this.basePath}/api/v3/offerOrders/${encodeURIComponent(String(offerOrderId))}/upgrades`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Validates if offer order number already exists for the reseller.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager, ResellerSalesManager
     * @param offerOrderNumber
     * @param resellerId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public offerOrderV3OfferOrderByNumberAsync(offerOrderNumber: string, resellerId?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public offerOrderV3OfferOrderByNumberAsync(offerOrderNumber: string, resellerId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public offerOrderV3OfferOrderByNumberAsync(offerOrderNumber: string, resellerId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public offerOrderV3OfferOrderByNumberAsync(offerOrderNumber: string, resellerId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (offerOrderNumber === null || offerOrderNumber === undefined) {
            throw new Error('Required parameter offerOrderNumber was null or undefined when calling offerOrderV3OfferOrderByNumberAsync.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (offerOrderNumber !== undefined) {
            queryParameters = queryParameters.set('offerOrderNumber', <any>offerOrderNumber);
        }
        if (resellerId !== undefined) {
            queryParameters = queryParameters.set('resellerId', <any>resellerId);
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.head<any>(`${this.basePath}/api/v3/offerOrders`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Changes the company of an OfferOrder.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param offerOrderId The OfferOrder number and company Id.
     * @param companyIdModel New company Id
     * @param deleteSebuCompany Delete the SEBU company.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public offerOrderV3ReassignOfferOrderAsync(offerOrderId: number, companyIdModel: CompanyIdModel, deleteSebuCompany?: boolean, observe?: 'body', reportProgress?: boolean): Observable<OfferOrderV3>;
    public offerOrderV3ReassignOfferOrderAsync(offerOrderId: number, companyIdModel: CompanyIdModel, deleteSebuCompany?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OfferOrderV3>>;
    public offerOrderV3ReassignOfferOrderAsync(offerOrderId: number, companyIdModel: CompanyIdModel, deleteSebuCompany?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OfferOrderV3>>;
    public offerOrderV3ReassignOfferOrderAsync(offerOrderId: number, companyIdModel: CompanyIdModel, deleteSebuCompany?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (offerOrderId === null || offerOrderId === undefined) {
            throw new Error('Required parameter offerOrderId was null or undefined when calling offerOrderV3ReassignOfferOrderAsync.');
        }
        if (companyIdModel === null || companyIdModel === undefined) {
            throw new Error('Required parameter companyIdModel was null or undefined when calling offerOrderV3ReassignOfferOrderAsync.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (deleteSebuCompany !== undefined) {
            queryParameters = queryParameters.set('deleteSebuCompany', <any>deleteSebuCompany);
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<OfferOrderV3>(`${this.basePath}/api/v3/offerOrders/${encodeURIComponent(String(offerOrderId))}/reassign`,
            companyIdModel,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Renders the standard layout of an offer order. Pure calculation, no side effects.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param renderData
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public offerOrderV3RenderStandardLayoutAsync(renderData: OfferOrderStandardLayoutRenderRequestV3, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public offerOrderV3RenderStandardLayoutAsync(renderData: OfferOrderStandardLayoutRenderRequestV3, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public offerOrderV3RenderStandardLayoutAsync(renderData: OfferOrderStandardLayoutRenderRequestV3, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public offerOrderV3RenderStandardLayoutAsync(renderData: OfferOrderStandardLayoutRenderRequestV3, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (renderData === null || renderData === undefined) {
            throw new Error('Required parameter renderData was null or undefined when calling offerOrderV3RenderStandardLayoutAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<string>(`${this.basePath}/api/v3/offerOrders/render`,
            renderData,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Searches for offer orders and offers by using a search filter.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager, ResellerSalesManager
     * @param offerName
     * @param externalOfferId
     * @param companySearchTerm
     * @param onlineAt
     * @param offerOrderNumber
     * @param productTypes
     * @param products
     * @param companyId
     * @param includeCanceledOfferOrders
     * @param publishedAtOrAfter
     * @param publishedAtOrBefore
     * @param createdAtOrAfter
     * @param createdAtOrBefore
     * @param offerOrderStates
     * @param topJob
     * @param tableEntry
     * @param jobBlitz
     * @param kalaydo
     * @param itJobs
     * @param tenhil
     * @param refresh
     * @param isSebu
     * @param socialMedia
     * @param workflowStates
     * @param skip
     * @param take
     * @param resellerId If you are system admin, you can restrict searches to a reseller if you set this field.  Leave empty if you want to search for ALL records or if you are not system admin.  If you are system admin and set this field to any but your own reseller id you will  get a validation error.
     * @param orderBy
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public offerOrderV3SearchOfferOrdersAndOffersAsync(offerName?: string, externalOfferId?: number, companySearchTerm?: string, onlineAt?: Date, offerOrderNumber?: string, productTypes?: Array<string>, products?: Array<string>, companyId?: number, includeCanceledOfferOrders?: boolean, publishedAtOrAfter?: Date, publishedAtOrBefore?: Date, createdAtOrAfter?: Date, createdAtOrBefore?: Date, offerOrderStates?: Array<string>, topJob?: boolean, tableEntry?: boolean, jobBlitz?: boolean, kalaydo?: boolean, itJobs?: boolean, tenhil?: boolean, refresh?: boolean, isSebu?: boolean, socialMedia?: boolean, workflowStates?: Array<string>, skip?: number, take?: number, resellerId?: number, orderBy?: 'ByOfferOrderNumberASC' | 'ByOfferOrderNumberDESC' | 'ByCompanyNumberASC' | 'ByCompanyNumberDESC' | 'ByTopJobASC' | 'ByTopJobDESC' | 'ByJobBlitzASC' | 'ByJobBlitzDESC' | 'ByRefreshASC' | 'ByRefreshDESC' | 'ByTableEntryASC' | 'ByTableEntryDESC' | 'ByOfferOrderStatusASC' | 'ByOfferOrderStatusDESC' | 'ByProductTypeASC' | 'ByProductTypeDESC' | 'ByPublishDateASC' | 'ByPublishDateDESC' | 'ByCreatedAtASC' | 'ByCreatedAtDESC' | 'ByCompanyNameASC' | 'ByCompanyNameDESC' | 'ByProductVariantASC' | 'ByProductVariantDESC' | 'ByKalaydoASC' | 'ByKalaydoDESC' | 'ByItJobsASC' | 'ByItJobsDESC', observe?: 'body', reportProgress?: boolean): Observable<NODataPagedResultOfferOrderViewItem>;
    public offerOrderV3SearchOfferOrdersAndOffersAsync(offerName?: string, externalOfferId?: number, companySearchTerm?: string, onlineAt?: Date, offerOrderNumber?: string, productTypes?: Array<string>, products?: Array<string>, companyId?: number, includeCanceledOfferOrders?: boolean, publishedAtOrAfter?: Date, publishedAtOrBefore?: Date, createdAtOrAfter?: Date, createdAtOrBefore?: Date, offerOrderStates?: Array<string>, topJob?: boolean, tableEntry?: boolean, jobBlitz?: boolean, kalaydo?: boolean, itJobs?: boolean, tenhil?: boolean, refresh?: boolean, isSebu?: boolean, socialMedia?: boolean, workflowStates?: Array<string>, skip?: number, take?: number, resellerId?: number, orderBy?: 'ByOfferOrderNumberASC' | 'ByOfferOrderNumberDESC' | 'ByCompanyNumberASC' | 'ByCompanyNumberDESC' | 'ByTopJobASC' | 'ByTopJobDESC' | 'ByJobBlitzASC' | 'ByJobBlitzDESC' | 'ByRefreshASC' | 'ByRefreshDESC' | 'ByTableEntryASC' | 'ByTableEntryDESC' | 'ByOfferOrderStatusASC' | 'ByOfferOrderStatusDESC' | 'ByProductTypeASC' | 'ByProductTypeDESC' | 'ByPublishDateASC' | 'ByPublishDateDESC' | 'ByCreatedAtASC' | 'ByCreatedAtDESC' | 'ByCompanyNameASC' | 'ByCompanyNameDESC' | 'ByProductVariantASC' | 'ByProductVariantDESC' | 'ByKalaydoASC' | 'ByKalaydoDESC' | 'ByItJobsASC' | 'ByItJobsDESC', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<NODataPagedResultOfferOrderViewItem>>;
    public offerOrderV3SearchOfferOrdersAndOffersAsync(offerName?: string, externalOfferId?: number, companySearchTerm?: string, onlineAt?: Date, offerOrderNumber?: string, productTypes?: Array<string>, products?: Array<string>, companyId?: number, includeCanceledOfferOrders?: boolean, publishedAtOrAfter?: Date, publishedAtOrBefore?: Date, createdAtOrAfter?: Date, createdAtOrBefore?: Date, offerOrderStates?: Array<string>, topJob?: boolean, tableEntry?: boolean, jobBlitz?: boolean, kalaydo?: boolean, itJobs?: boolean, tenhil?: boolean, refresh?: boolean, isSebu?: boolean, socialMedia?: boolean, workflowStates?: Array<string>, skip?: number, take?: number, resellerId?: number, orderBy?: 'ByOfferOrderNumberASC' | 'ByOfferOrderNumberDESC' | 'ByCompanyNumberASC' | 'ByCompanyNumberDESC' | 'ByTopJobASC' | 'ByTopJobDESC' | 'ByJobBlitzASC' | 'ByJobBlitzDESC' | 'ByRefreshASC' | 'ByRefreshDESC' | 'ByTableEntryASC' | 'ByTableEntryDESC' | 'ByOfferOrderStatusASC' | 'ByOfferOrderStatusDESC' | 'ByProductTypeASC' | 'ByProductTypeDESC' | 'ByPublishDateASC' | 'ByPublishDateDESC' | 'ByCreatedAtASC' | 'ByCreatedAtDESC' | 'ByCompanyNameASC' | 'ByCompanyNameDESC' | 'ByProductVariantASC' | 'ByProductVariantDESC' | 'ByKalaydoASC' | 'ByKalaydoDESC' | 'ByItJobsASC' | 'ByItJobsDESC', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<NODataPagedResultOfferOrderViewItem>>;
    public offerOrderV3SearchOfferOrdersAndOffersAsync(offerName?: string, externalOfferId?: number, companySearchTerm?: string, onlineAt?: Date, offerOrderNumber?: string, productTypes?: Array<string>, products?: Array<string>, companyId?: number, includeCanceledOfferOrders?: boolean, publishedAtOrAfter?: Date, publishedAtOrBefore?: Date, createdAtOrAfter?: Date, createdAtOrBefore?: Date, offerOrderStates?: Array<string>, topJob?: boolean, tableEntry?: boolean, jobBlitz?: boolean, kalaydo?: boolean, itJobs?: boolean, tenhil?: boolean, refresh?: boolean, isSebu?: boolean, socialMedia?: boolean, workflowStates?: Array<string>, skip?: number, take?: number, resellerId?: number, orderBy?: 'ByOfferOrderNumberASC' | 'ByOfferOrderNumberDESC' | 'ByCompanyNumberASC' | 'ByCompanyNumberDESC' | 'ByTopJobASC' | 'ByTopJobDESC' | 'ByJobBlitzASC' | 'ByJobBlitzDESC' | 'ByRefreshASC' | 'ByRefreshDESC' | 'ByTableEntryASC' | 'ByTableEntryDESC' | 'ByOfferOrderStatusASC' | 'ByOfferOrderStatusDESC' | 'ByProductTypeASC' | 'ByProductTypeDESC' | 'ByPublishDateASC' | 'ByPublishDateDESC' | 'ByCreatedAtASC' | 'ByCreatedAtDESC' | 'ByCompanyNameASC' | 'ByCompanyNameDESC' | 'ByProductVariantASC' | 'ByProductVariantDESC' | 'ByKalaydoASC' | 'ByKalaydoDESC' | 'ByItJobsASC' | 'ByItJobsDESC', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (offerName !== undefined) {
            queryParameters = queryParameters.set('offerName', <any>offerName);
        }
        if (externalOfferId !== undefined) {
            queryParameters = queryParameters.set('externalOfferId', <any>externalOfferId);
        }
        if (companySearchTerm !== undefined) {
            queryParameters = queryParameters.set('companySearchTerm', <any>companySearchTerm);
        }
        if (onlineAt !== undefined) {
            queryParameters = queryParameters.set('onlineAt', <any>onlineAt.toISOString());
        }
        if (offerOrderNumber !== undefined) {
            queryParameters = queryParameters.set('offerOrderNumber', <any>offerOrderNumber);
        }
        if (productTypes) {
            productTypes.forEach((element) => {
                queryParameters = queryParameters.append('productTypes', <any>element);
            })
        }
        if (products) {
            products.forEach((element) => {
                queryParameters = queryParameters.append('products', <any>element);
            })
        }
        if (companyId !== undefined) {
            queryParameters = queryParameters.set('companyId', <any>companyId);
        }
        if (includeCanceledOfferOrders !== undefined) {
            queryParameters = queryParameters.set('includeCanceledOfferOrders', <any>includeCanceledOfferOrders);
        }
        if (publishedAtOrAfter !== undefined) {
            queryParameters = queryParameters.set('publishedAtOrAfter', <any>publishedAtOrAfter.toISOString());
        }
        if (publishedAtOrBefore !== undefined) {
            queryParameters = queryParameters.set('publishedAtOrBefore', <any>publishedAtOrBefore.toISOString());
        }
        if (createdAtOrAfter !== undefined) {
            queryParameters = queryParameters.set('createdAtOrAfter', <any>createdAtOrAfter.toISOString());
        }
        if (createdAtOrBefore !== undefined) {
            queryParameters = queryParameters.set('createdAtOrBefore', <any>createdAtOrBefore.toISOString());
        }
        if (offerOrderStates) {
            offerOrderStates.forEach((element) => {
                queryParameters = queryParameters.append('offerOrderStates', <any>element);
            })
        }
        if (topJob !== undefined) {
            queryParameters = queryParameters.set('topJob', <any>topJob);
        }
        if (tableEntry !== undefined) {
            queryParameters = queryParameters.set('tableEntry', <any>tableEntry);
        }
        if (jobBlitz !== undefined) {
            queryParameters = queryParameters.set('jobBlitz', <any>jobBlitz);
        }
        if (kalaydo !== undefined) {
            queryParameters = queryParameters.set('kalaydo', <any>kalaydo);
        }
        if (itJobs !== undefined) {
            queryParameters = queryParameters.set('itJobs', <any>itJobs);
        }
        if (tenhil !== undefined) {
            queryParameters = queryParameters.set('tenhil', <any>tenhil);
        }
        if (refresh !== undefined) {
            queryParameters = queryParameters.set('refresh', <any>refresh);
        }
        if (isSebu !== undefined) {
            queryParameters = queryParameters.set('isSebu', <any>isSebu);
        }
        if (socialMedia !== undefined) {
            queryParameters = queryParameters.set('socialMedia', <any>socialMedia);
        }
        if (workflowStates) {
            workflowStates.forEach((element) => {
                queryParameters = queryParameters.append('workflowStates', <any>element);
            })
        }
        if (skip !== undefined) {
            queryParameters = queryParameters.set('skip', <any>skip);
        }
        if (take !== undefined) {
            queryParameters = queryParameters.set('take', <any>take);
        }
        if (resellerId !== undefined) {
            queryParameters = queryParameters.set('resellerId', <any>resellerId);
        }
        if (orderBy !== undefined) {
            queryParameters = queryParameters.set('orderBy', <any>orderBy);
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'text/csv',
            'application/octet-stream'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<NODataPagedResultOfferOrderViewItem>(`${this.basePath}/api/v3/offerOrdersWithOffers`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Searches for offer orders by using a search filter.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager, ResellerSalesManager
     * @param offerOrderNumber
     * @param companySearchTerm
     * @param publishedAtOrAfter
     * @param publishedAtOrBefore
     * @param createdAtOrAfter
     * @param createdAtOrBefore
     * @param offerOrderStates
     * @param products
     * @param productTypes
     * @param productVariants
     * @param originApiClients
     * @param topJob
     * @param jobBlitz
     * @param kalaydo
     * @param itJobs
     * @param socialMedia
     * @param companyId
     * @param isSebuOfferOrder
     * @param refresh
     * @param includeCanceledOfferOrders
     * @param skip
     * @param take
     * @param resellerId If you are system admin, you can restrict searches to a reseller if you set this field.  Leave empty if you want to search for ALL records or if you are not system admin.  If you are system admin and set this field to any but your own reseller id you will  get a validation error.
     * @param orderBy
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public offerOrderV3SearchOfferOrdersAsync(offerOrderNumber?: string, companySearchTerm?: string, publishedAtOrAfter?: Date, publishedAtOrBefore?: Date, createdAtOrAfter?: Date, createdAtOrBefore?: Date, offerOrderStates?: Array<string>, products?: Array<string>, productTypes?: Array<string>, productVariants?: Array<string>, originApiClients?: Array<string>, topJob?: boolean, jobBlitz?: boolean, kalaydo?: boolean, itJobs?: boolean, socialMedia?: boolean, companyId?: number, isSebuOfferOrder?: boolean, refresh?: boolean, includeCanceledOfferOrders?: boolean, skip?: number, take?: number, resellerId?: number, orderBy?: 'ByCompanyNumberASC' | 'ByCompanyNumberDESC' | 'ByCreatedAtASC' | 'ByCreatedAtDESC' | 'ByOfferOrderNumberASC' | 'ByOfferOrderNumberDESC' | 'ByOfferOrderStatusASC' | 'ByOfferOrderStatusDESC' | 'ByProductTypeASC' | 'ByProductTypeDESC' | 'ByPublishDateASC' | 'ByPublishDateDESC' | 'ByTopJobASC' | 'ByTopJobDESC' | 'ByJobBlitzASC' | 'ByJobBlitzDESC' | 'ByKalaydoASC' | 'ByKalaydoDESC' | 'ByItJobsASC' | 'ByItJobsDESC', observe?: 'body', reportProgress?: boolean): Observable<NODataPagedResultOfferOrderListItem>;
    public offerOrderV3SearchOfferOrdersAsync(offerOrderNumber?: string, companySearchTerm?: string, publishedAtOrAfter?: Date, publishedAtOrBefore?: Date, createdAtOrAfter?: Date, createdAtOrBefore?: Date, offerOrderStates?: Array<string>, products?: Array<string>, productTypes?: Array<string>, productVariants?: Array<string>, originApiClients?: Array<string>, topJob?: boolean, jobBlitz?: boolean, kalaydo?: boolean, itJobs?: boolean, socialMedia?: boolean, companyId?: number, isSebuOfferOrder?: boolean, refresh?: boolean, includeCanceledOfferOrders?: boolean, skip?: number, take?: number, resellerId?: number, orderBy?: 'ByCompanyNumberASC' | 'ByCompanyNumberDESC' | 'ByCreatedAtASC' | 'ByCreatedAtDESC' | 'ByOfferOrderNumberASC' | 'ByOfferOrderNumberDESC' | 'ByOfferOrderStatusASC' | 'ByOfferOrderStatusDESC' | 'ByProductTypeASC' | 'ByProductTypeDESC' | 'ByPublishDateASC' | 'ByPublishDateDESC' | 'ByTopJobASC' | 'ByTopJobDESC' | 'ByJobBlitzASC' | 'ByJobBlitzDESC' | 'ByKalaydoASC' | 'ByKalaydoDESC' | 'ByItJobsASC' | 'ByItJobsDESC', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<NODataPagedResultOfferOrderListItem>>;
    public offerOrderV3SearchOfferOrdersAsync(offerOrderNumber?: string, companySearchTerm?: string, publishedAtOrAfter?: Date, publishedAtOrBefore?: Date, createdAtOrAfter?: Date, createdAtOrBefore?: Date, offerOrderStates?: Array<string>, products?: Array<string>, productTypes?: Array<string>, productVariants?: Array<string>, originApiClients?: Array<string>, topJob?: boolean, jobBlitz?: boolean, kalaydo?: boolean, itJobs?: boolean, socialMedia?: boolean, companyId?: number, isSebuOfferOrder?: boolean, refresh?: boolean, includeCanceledOfferOrders?: boolean, skip?: number, take?: number, resellerId?: number, orderBy?: 'ByCompanyNumberASC' | 'ByCompanyNumberDESC' | 'ByCreatedAtASC' | 'ByCreatedAtDESC' | 'ByOfferOrderNumberASC' | 'ByOfferOrderNumberDESC' | 'ByOfferOrderStatusASC' | 'ByOfferOrderStatusDESC' | 'ByProductTypeASC' | 'ByProductTypeDESC' | 'ByPublishDateASC' | 'ByPublishDateDESC' | 'ByTopJobASC' | 'ByTopJobDESC' | 'ByJobBlitzASC' | 'ByJobBlitzDESC' | 'ByKalaydoASC' | 'ByKalaydoDESC' | 'ByItJobsASC' | 'ByItJobsDESC', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<NODataPagedResultOfferOrderListItem>>;
    public offerOrderV3SearchOfferOrdersAsync(offerOrderNumber?: string, companySearchTerm?: string, publishedAtOrAfter?: Date, publishedAtOrBefore?: Date, createdAtOrAfter?: Date, createdAtOrBefore?: Date, offerOrderStates?: Array<string>, products?: Array<string>, productTypes?: Array<string>, productVariants?: Array<string>, originApiClients?: Array<string>, topJob?: boolean, jobBlitz?: boolean, kalaydo?: boolean, itJobs?: boolean, socialMedia?: boolean, companyId?: number, isSebuOfferOrder?: boolean, refresh?: boolean, includeCanceledOfferOrders?: boolean, skip?: number, take?: number, resellerId?: number, orderBy?: 'ByCompanyNumberASC' | 'ByCompanyNumberDESC' | 'ByCreatedAtASC' | 'ByCreatedAtDESC' | 'ByOfferOrderNumberASC' | 'ByOfferOrderNumberDESC' | 'ByOfferOrderStatusASC' | 'ByOfferOrderStatusDESC' | 'ByProductTypeASC' | 'ByProductTypeDESC' | 'ByPublishDateASC' | 'ByPublishDateDESC' | 'ByTopJobASC' | 'ByTopJobDESC' | 'ByJobBlitzASC' | 'ByJobBlitzDESC' | 'ByKalaydoASC' | 'ByKalaydoDESC' | 'ByItJobsASC' | 'ByItJobsDESC', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (offerOrderNumber !== undefined) {
            queryParameters = queryParameters.set('offerOrderNumber', <any>offerOrderNumber);
        }
        if (companySearchTerm !== undefined) {
            queryParameters = queryParameters.set('companySearchTerm', <any>companySearchTerm);
        }
        if (publishedAtOrAfter !== undefined) {
            queryParameters = queryParameters.set('publishedAtOrAfter', <any>publishedAtOrAfter.toISOString());
        }
        if (publishedAtOrBefore !== undefined) {
            queryParameters = queryParameters.set('publishedAtOrBefore', <any>publishedAtOrBefore.toISOString());
        }
        if (createdAtOrAfter !== undefined) {
            queryParameters = queryParameters.set('createdAtOrAfter', <any>createdAtOrAfter.toISOString());
        }
        if (createdAtOrBefore !== undefined) {
            queryParameters = queryParameters.set('createdAtOrBefore', <any>createdAtOrBefore.toISOString());
        }
        if (offerOrderStates) {
            offerOrderStates.forEach((element) => {
                queryParameters = queryParameters.append('offerOrderStates', <any>element);
            })
        }
        if (products) {
            products.forEach((element) => {
                queryParameters = queryParameters.append('products', <any>element);
            })
        }
        if (productTypes) {
            productTypes.forEach((element) => {
                queryParameters = queryParameters.append('productTypes', <any>element);
            })
        }
        if (productVariants) {
            productVariants.forEach((element) => {
                queryParameters = queryParameters.append('productVariants', <any>element);
            })
        }
        if (originApiClients) {
            originApiClients.forEach((element) => {
                queryParameters = queryParameters.append('originApiClients', <any>element);
            })
        }
        if (topJob !== undefined) {
            queryParameters = queryParameters.set('topJob', <any>topJob);
        }
        if (jobBlitz !== undefined) {
            queryParameters = queryParameters.set('jobBlitz', <any>jobBlitz);
        }
        if (kalaydo !== undefined) {
            queryParameters = queryParameters.set('kalaydo', <any>kalaydo);
        }
        if (itJobs !== undefined) {
            queryParameters = queryParameters.set('itJobs', <any>itJobs);
        }
        if (socialMedia !== undefined) {
            queryParameters = queryParameters.set('socialMedia', <any>socialMedia);
        }
        if (companyId !== undefined) {
            queryParameters = queryParameters.set('companyId', <any>companyId);
        }
        if (isSebuOfferOrder !== undefined) {
            queryParameters = queryParameters.set('isSebuOfferOrder', <any>isSebuOfferOrder);
        }
        if (refresh !== undefined) {
            queryParameters = queryParameters.set('refresh', <any>refresh);
        }
        if (includeCanceledOfferOrders !== undefined) {
            queryParameters = queryParameters.set('includeCanceledOfferOrders', <any>includeCanceledOfferOrders);
        }
        if (skip !== undefined) {
            queryParameters = queryParameters.set('skip', <any>skip);
        }
        if (take !== undefined) {
            queryParameters = queryParameters.set('take', <any>take);
        }
        if (resellerId !== undefined) {
            queryParameters = queryParameters.set('resellerId', <any>resellerId);
        }
        if (orderBy !== undefined) {
            queryParameters = queryParameters.set('orderBy', <any>orderBy);
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'text/csv',
            'application/octet-stream'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<NODataPagedResultOfferOrderListItem>(`${this.basePath}/api/v3/offerOrders`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sends an Offer Order.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param offerOrderId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public offerOrderV3SendOfferOrderAsync(offerOrderId: number, observe?: 'body', reportProgress?: boolean): Observable<OfferOrderV3>;
    public offerOrderV3SendOfferOrderAsync(offerOrderId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OfferOrderV3>>;
    public offerOrderV3SendOfferOrderAsync(offerOrderId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OfferOrderV3>>;
    public offerOrderV3SendOfferOrderAsync(offerOrderId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (offerOrderId === null || offerOrderId === undefined) {
            throw new Error('Required parameter offerOrderId was null or undefined when calling offerOrderV3SendOfferOrderAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.put<OfferOrderV3>(`${this.basePath}/api/v3/offerOrders/${encodeURIComponent(String(offerOrderId))}/send`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Changes the number of an OfferOrder.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager, ResellerSalesManager
     * @param offerOrderId
     * @param offerOrderNumber
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public offerOrderV3UpdateOfferOrderNumberAsync(offerOrderId: number, offerOrderNumber: OfferOrderUpdateOfferOrderNumberV3, observe?: 'body', reportProgress?: boolean): Observable<OfferOrderV3>;
    public offerOrderV3UpdateOfferOrderNumberAsync(offerOrderId: number, offerOrderNumber: OfferOrderUpdateOfferOrderNumberV3, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OfferOrderV3>>;
    public offerOrderV3UpdateOfferOrderNumberAsync(offerOrderId: number, offerOrderNumber: OfferOrderUpdateOfferOrderNumberV3, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OfferOrderV3>>;
    public offerOrderV3UpdateOfferOrderNumberAsync(offerOrderId: number, offerOrderNumber: OfferOrderUpdateOfferOrderNumberV3, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (offerOrderId === null || offerOrderId === undefined) {
            throw new Error('Required parameter offerOrderId was null or undefined when calling offerOrderV3UpdateOfferOrderNumberAsync.');
        }
        if (offerOrderNumber === null || offerOrderNumber === undefined) {
            throw new Error('Required parameter offerOrderNumber was null or undefined when calling offerOrderV3UpdateOfferOrderNumberAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<OfferOrderV3>(`${this.basePath}/api/v3/offerOrders/${encodeURIComponent(String(offerOrderId))}/updateOfferOrderNumber`,
            offerOrderNumber,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Extends TopJob by offer order.
     * Allowed roles: SystemAdmin, Reseller
     * @param offerOrderId 
     * @param immediately 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public offerOrderV3UpdateWorkflowTopJob(offerOrderId: number, immediately: boolean, observe?: 'body', reportProgress?: boolean): Observable<OfferOrderV3>;
    public offerOrderV3UpdateWorkflowTopJob(offerOrderId: number, immediately: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OfferOrderV3>>;
    public offerOrderV3UpdateWorkflowTopJob(offerOrderId: number, immediately: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OfferOrderV3>>;
    public offerOrderV3UpdateWorkflowTopJob(offerOrderId: number, immediately: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (offerOrderId === null || offerOrderId === undefined) {
            throw new Error('Required parameter offerOrderId was null or undefined when calling offerOrderV3UpdateWorkflowTopJob.');
        }
        if (immediately === null || immediately === undefined) {
            throw new Error('Required parameter immediately was null or undefined when calling offerOrderV3UpdateWorkflowTopJob.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (immediately !== undefined) {
            queryParameters = queryParameters.set('immediately', <any>immediately);
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.put<OfferOrderV3>(`${this.basePath}/api/v3/offerOrders/${encodeURIComponent(String(offerOrderId))}/UpdateWorkflowTopJob`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Upgrades an Offer Order.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param offerOrderId
     * @param offerOrderUpgrade
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public offerOrderV3UpgradeOfferOrderAsync(offerOrderId: number, offerOrderUpgrade: OfferOrderUpgradeV3, observe?: 'body', reportProgress?: boolean): Observable<OfferOrderV3>;
    public offerOrderV3UpgradeOfferOrderAsync(offerOrderId: number, offerOrderUpgrade: OfferOrderUpgradeV3, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OfferOrderV3>>;
    public offerOrderV3UpgradeOfferOrderAsync(offerOrderId: number, offerOrderUpgrade: OfferOrderUpgradeV3, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OfferOrderV3>>;
    public offerOrderV3UpgradeOfferOrderAsync(offerOrderId: number, offerOrderUpgrade: OfferOrderUpgradeV3, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (offerOrderId === null || offerOrderId === undefined) {
            throw new Error('Required parameter offerOrderId was null or undefined when calling offerOrderV3UpgradeOfferOrderAsync.');
        }
        if (offerOrderUpgrade === null || offerOrderUpgrade === undefined) {
            throw new Error('Required parameter offerOrderUpgrade was null or undefined when calling offerOrderV3UpgradeOfferOrderAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<OfferOrderV3>(`${this.basePath}/api/v3/offerOrders/${encodeURIComponent(String(offerOrderId))}/upgrade`,
            offerOrderUpgrade,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Activates an Offer.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param offerId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public offerV3ActivateOfferAsync(offerId: number, observe?: 'body', reportProgress?: boolean): Observable<OfferV3>;
    public offerV3ActivateOfferAsync(offerId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OfferV3>>;
    public offerV3ActivateOfferAsync(offerId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OfferV3>>;
    public offerV3ActivateOfferAsync(offerId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (offerId === null || offerId === undefined) {
            throw new Error('Required parameter offerId was null or undefined when calling offerV3ActivateOfferAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.put<OfferV3>(`${this.basePath}/api/v3/offers/${encodeURIComponent(String(offerId))}/activate`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deactivates an Offer.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param offerId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public offerV3DeactivateOfferAsync(offerId: number, observe?: 'body', reportProgress?: boolean): Observable<OfferV3>;
    public offerV3DeactivateOfferAsync(offerId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OfferV3>>;
    public offerV3DeactivateOfferAsync(offerId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OfferV3>>;
    public offerV3DeactivateOfferAsync(offerId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (offerId === null || offerId === undefined) {
            throw new Error('Required parameter offerId was null or undefined when calling offerV3DeactivateOfferAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.put<OfferV3>(`${this.basePath}/api/v3/offers/${encodeURIComponent(String(offerId))}/deactivate`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Extends an Offer.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param offerId The Offer ID
     * @param extendImmediately If the offer should be extended now or after expiration.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public offerV3ExtendOfferAsync(offerId: number, extendImmediately?: boolean, observe?: 'body', reportProgress?: boolean): Observable<OfferV3>;
    public offerV3ExtendOfferAsync(offerId: number, extendImmediately?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OfferV3>>;
    public offerV3ExtendOfferAsync(offerId: number, extendImmediately?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OfferV3>>;
    public offerV3ExtendOfferAsync(offerId: number, extendImmediately?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (offerId === null || offerId === undefined) {
            throw new Error('Required parameter offerId was null or undefined when calling offerV3ExtendOfferAsync.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (extendImmediately !== undefined) {
            queryParameters = queryParameters.set('extendImmediately', <any>extendImmediately);
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.put<OfferV3>(`${this.basePath}/api/v3/offers/${encodeURIComponent(String(offerId))}/extend`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a zip from Stellenanzeigen via SOAP
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager, ResellerSalesManager
     * @param offerId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public offerV3GetZipDownloadAsync(offerId: number, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public offerV3GetZipDownloadAsync(offerId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public offerV3GetZipDownloadAsync(offerId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public offerV3GetZipDownloadAsync(offerId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (offerId === null || offerId === undefined) {
            throw new Error('Required parameter offerId was null or undefined when calling offerV3GetZipDownloadAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get(`${this.basePath}/api/v3/offers/${encodeURIComponent(String(offerId))}/zip`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Searches for offers by using a search filter
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager, ResellerSalesManager
     * @param offerName
     * @param externalOfferId
     * @param companySearchTerm
     * @param onlineAt
     * @param offerOrderNumber
     * @param offerStates
     * @param workflowStates
     * @param products
     * @param productTypes
     * @param productVariants
     * @param originApiClients
     * @param companyId
     * @param isSebuOffer
     * @param includeCanceledOfferOrders
     * @param publishedAtOrAfter
     * @param publishedAtOrBefore
     * @param createdAtOrAfter
     * @param createdAtOrBefore
     * @param offerOrderStates
     * @param topJob
     * @param jobBlitz
     * @param kalaydo
     * @param itJobs
     * @param refresh
     * @param skip
     * @param take
     * @param resellerId If you are system admin, you can restrict searches to a reseller if you set this field.  Leave empty if you want to search for ALL records or if you are not system admin.  If you are system admin and set this field to any but your own reseller id you will  get a validation error.
     * @param orderBy
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public offerV3SearchViaResellerAsync(offerName?: string, externalOfferId?: number, companySearchTerm?: string, onlineAt?: Date, offerOrderNumber?: string, offerStates?: Array<string>, workflowStates?: Array<string>, products?: Array<string>, productTypes?: Array<string>, productVariants?: Array<string>, originApiClients?: Array<string>, companyId?: number, isSebuOffer?: boolean, includeCanceledOfferOrders?: boolean, publishedAtOrAfter?: Date, publishedAtOrBefore?: Date, createdAtOrAfter?: Date, createdAtOrBefore?: Date, offerOrderStates?: Array<string>, topJob?: boolean, jobBlitz?: boolean, kalaydo?: boolean, itJobs?: boolean, refresh?: boolean, skip?: number, take?: number, resellerId?: number, orderBy?: 'ByPositionNameASC' | 'ByPositionNameDESC' | 'ByOfferOrderNumberASC' | 'ByOfferOrderNumberDESC' | 'ByCompanyNumberASC' | 'ByCompanyNumberDESC' | 'ByOfferStatusASC' | 'ByOfferStatusDESC' | 'ByLocationASC' | 'ByLocationDESC' | 'ByStartDateASC' | 'ByStartDateDESC' | 'ByEndDateASC' | 'ByEndDateDESC' | 'ByExternalOfferIdASC' | 'ByExternalOfferIdDESC' | 'ByClicksASC' | 'ByClicksDESC' | 'ByCreatedAtASC' | 'ByCreatedAtDESC' | 'ByPublishDateASC' | 'ByPublishDateDESC' | 'ByOfferOrderStatusASC' | 'ByOfferOrderStatusDESC' | 'ByTopJobASC' | 'ByTopJobDESC' | 'ByJobBlitzASC' | 'ByJobBlitzDESC' | 'ByKalaydoASC' | 'ByKalaydoDESC' | 'ByItJobsASC' | 'ByItJobsDESC', observe?: 'body', reportProgress?: boolean): Observable<NODataPagedResultOfferListItem>;
    public offerV3SearchViaResellerAsync(offerName?: string, externalOfferId?: number, companySearchTerm?: string, onlineAt?: Date, offerOrderNumber?: string, offerStates?: Array<string>, workflowStates?: Array<string>, products?: Array<string>, productTypes?: Array<string>, productVariants?: Array<string>, originApiClients?: Array<string>, companyId?: number, isSebuOffer?: boolean, includeCanceledOfferOrders?: boolean, publishedAtOrAfter?: Date, publishedAtOrBefore?: Date, createdAtOrAfter?: Date, createdAtOrBefore?: Date, offerOrderStates?: Array<string>, topJob?: boolean, jobBlitz?: boolean, kalaydo?: boolean, itJobs?: boolean, refresh?: boolean, skip?: number, take?: number, resellerId?: number, orderBy?: 'ByPositionNameASC' | 'ByPositionNameDESC' | 'ByOfferOrderNumberASC' | 'ByOfferOrderNumberDESC' | 'ByCompanyNumberASC' | 'ByCompanyNumberDESC' | 'ByOfferStatusASC' | 'ByOfferStatusDESC' | 'ByLocationASC' | 'ByLocationDESC' | 'ByStartDateASC' | 'ByStartDateDESC' | 'ByEndDateASC' | 'ByEndDateDESC' | 'ByExternalOfferIdASC' | 'ByExternalOfferIdDESC' | 'ByClicksASC' | 'ByClicksDESC' | 'ByCreatedAtASC' | 'ByCreatedAtDESC' | 'ByPublishDateASC' | 'ByPublishDateDESC' | 'ByOfferOrderStatusASC' | 'ByOfferOrderStatusDESC' | 'ByTopJobASC' | 'ByTopJobDESC' | 'ByJobBlitzASC' | 'ByJobBlitzDESC' | 'ByKalaydoASC' | 'ByKalaydoDESC' | 'ByItJobsASC' | 'ByItJobsDESC', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<NODataPagedResultOfferListItem>>;
    public offerV3SearchViaResellerAsync(offerName?: string, externalOfferId?: number, companySearchTerm?: string, onlineAt?: Date, offerOrderNumber?: string, offerStates?: Array<string>, workflowStates?: Array<string>, products?: Array<string>, productTypes?: Array<string>, productVariants?: Array<string>, originApiClients?: Array<string>, companyId?: number, isSebuOffer?: boolean, includeCanceledOfferOrders?: boolean, publishedAtOrAfter?: Date, publishedAtOrBefore?: Date, createdAtOrAfter?: Date, createdAtOrBefore?: Date, offerOrderStates?: Array<string>, topJob?: boolean, jobBlitz?: boolean, kalaydo?: boolean, itJobs?: boolean, refresh?: boolean, skip?: number, take?: number, resellerId?: number, orderBy?: 'ByPositionNameASC' | 'ByPositionNameDESC' | 'ByOfferOrderNumberASC' | 'ByOfferOrderNumberDESC' | 'ByCompanyNumberASC' | 'ByCompanyNumberDESC' | 'ByOfferStatusASC' | 'ByOfferStatusDESC' | 'ByLocationASC' | 'ByLocationDESC' | 'ByStartDateASC' | 'ByStartDateDESC' | 'ByEndDateASC' | 'ByEndDateDESC' | 'ByExternalOfferIdASC' | 'ByExternalOfferIdDESC' | 'ByClicksASC' | 'ByClicksDESC' | 'ByCreatedAtASC' | 'ByCreatedAtDESC' | 'ByPublishDateASC' | 'ByPublishDateDESC' | 'ByOfferOrderStatusASC' | 'ByOfferOrderStatusDESC' | 'ByTopJobASC' | 'ByTopJobDESC' | 'ByJobBlitzASC' | 'ByJobBlitzDESC' | 'ByKalaydoASC' | 'ByKalaydoDESC' | 'ByItJobsASC' | 'ByItJobsDESC', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<NODataPagedResultOfferListItem>>;
    public offerV3SearchViaResellerAsync(offerName?: string, externalOfferId?: number, companySearchTerm?: string, onlineAt?: Date, offerOrderNumber?: string, offerStates?: Array<string>, workflowStates?: Array<string>, products?: Array<string>, productTypes?: Array<string>, productVariants?: Array<string>, originApiClients?: Array<string>, companyId?: number, isSebuOffer?: boolean, includeCanceledOfferOrders?: boolean, publishedAtOrAfter?: Date, publishedAtOrBefore?: Date, createdAtOrAfter?: Date, createdAtOrBefore?: Date, offerOrderStates?: Array<string>, topJob?: boolean, jobBlitz?: boolean, kalaydo?: boolean, itJobs?: boolean, refresh?: boolean, skip?: number, take?: number, resellerId?: number, orderBy?: 'ByPositionNameASC' | 'ByPositionNameDESC' | 'ByOfferOrderNumberASC' | 'ByOfferOrderNumberDESC' | 'ByCompanyNumberASC' | 'ByCompanyNumberDESC' | 'ByOfferStatusASC' | 'ByOfferStatusDESC' | 'ByLocationASC' | 'ByLocationDESC' | 'ByStartDateASC' | 'ByStartDateDESC' | 'ByEndDateASC' | 'ByEndDateDESC' | 'ByExternalOfferIdASC' | 'ByExternalOfferIdDESC' | 'ByClicksASC' | 'ByClicksDESC' | 'ByCreatedAtASC' | 'ByCreatedAtDESC' | 'ByPublishDateASC' | 'ByPublishDateDESC' | 'ByOfferOrderStatusASC' | 'ByOfferOrderStatusDESC' | 'ByTopJobASC' | 'ByTopJobDESC' | 'ByJobBlitzASC' | 'ByJobBlitzDESC' | 'ByKalaydoASC' | 'ByKalaydoDESC' | 'ByItJobsASC' | 'ByItJobsDESC', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (offerName !== undefined) {
            queryParameters = queryParameters.set('offerName', <any>offerName);
        }
        if (externalOfferId !== undefined) {
            queryParameters = queryParameters.set('externalOfferId', <any>externalOfferId);
        }
        if (companySearchTerm !== undefined) {
            queryParameters = queryParameters.set('companySearchTerm', <any>companySearchTerm);
        }
        if (onlineAt !== undefined) {
            queryParameters = queryParameters.set('onlineAt', <any>onlineAt.toISOString());
        }
        if (offerOrderNumber !== undefined) {
            queryParameters = queryParameters.set('offerOrderNumber', <any>offerOrderNumber);
        }
        if (offerStates) {
            offerStates.forEach((element) => {
                queryParameters = queryParameters.append('offerStates', <any>element);
            })
        }
        if (workflowStates) {
            workflowStates.forEach((element) => {
                queryParameters = queryParameters.append('workflowStates', <any>element);
            })
        }
        if (products) {
            products.forEach((element) => {
                queryParameters = queryParameters.append('products', <any>element);
            })
        }
        if (productTypes) {
            productTypes.forEach((element) => {
                queryParameters = queryParameters.append('productTypes', <any>element);
            })
        }
        if (productVariants) {
            productVariants.forEach((element) => {
                queryParameters = queryParameters.append('productVariants', <any>element);
            })
        }
        if (originApiClients) {
            originApiClients.forEach((element) => {
                queryParameters = queryParameters.append('originApiClients', <any>element);
            })
        }
        if (companyId !== undefined) {
            queryParameters = queryParameters.set('companyId', <any>companyId);
        }
        if (isSebuOffer !== undefined) {
            queryParameters = queryParameters.set('isSebuOffer', <any>isSebuOffer);
        }
        if (includeCanceledOfferOrders !== undefined) {
            queryParameters = queryParameters.set('includeCanceledOfferOrders', <any>includeCanceledOfferOrders);
        }
        if (publishedAtOrAfter !== undefined) {
            queryParameters = queryParameters.set('publishedAtOrAfter', <any>publishedAtOrAfter.toISOString());
        }
        if (publishedAtOrBefore !== undefined) {
            queryParameters = queryParameters.set('publishedAtOrBefore', <any>publishedAtOrBefore.toISOString());
        }
        if (createdAtOrAfter !== undefined) {
            queryParameters = queryParameters.set('createdAtOrAfter', <any>createdAtOrAfter.toISOString());
        }
        if (createdAtOrBefore !== undefined) {
            queryParameters = queryParameters.set('createdAtOrBefore', <any>createdAtOrBefore.toISOString());
        }
        if (offerOrderStates) {
            offerOrderStates.forEach((element) => {
                queryParameters = queryParameters.append('offerOrderStates', <any>element);
            })
        }
        if (topJob !== undefined) {
            queryParameters = queryParameters.set('topJob', <any>topJob);
        }
        if (jobBlitz !== undefined) {
            queryParameters = queryParameters.set('jobBlitz', <any>jobBlitz);
        }
        if (kalaydo !== undefined) {
            queryParameters = queryParameters.set('kalaydo', <any>kalaydo);
        }
        if (itJobs !== undefined) {
            queryParameters = queryParameters.set('itJobs', <any>itJobs);
        }
        if (refresh !== undefined) {
            queryParameters = queryParameters.set('refresh', <any>refresh);
        }
        if (skip !== undefined) {
            queryParameters = queryParameters.set('skip', <any>skip);
        }
        if (take !== undefined) {
            queryParameters = queryParameters.set('take', <any>take);
        }
        if (resellerId !== undefined) {
            queryParameters = queryParameters.set('resellerId', <any>resellerId);
        }
        if (orderBy !== undefined) {
            queryParameters = queryParameters.set('orderBy', <any>orderBy);
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'text/csv',
            'application/octet-stream'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<NODataPagedResultOfferListItem>(`${this.basePath}/api/v3/offers`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
