// eslint-disable-next-line
import { ChiffreAdressesOverviewComponent } from '../../../../chiffre-addresses/chiffre-adresses-overview/chiffre-adresses-overview.component';
// eslint-disable-next-line
import { UserAdministrationOverviewComponent } from '../../../../user-administration/user-administration-overview/user-administration-overview.component';
import { UserRolePermissionPolicy } from '../user-role-permissions-policy.model';
import { Injectable } from '@angular/core';
import { ResellerPermissions } from '../reseller-permissions.model';
import { NavLinkEnum } from '../../../../main-layout/nav.model';

@Injectable({
  providedIn: 'root',
})
export class OfferManagerPolicy extends UserRolePermissionPolicy {

  private static readonly forbiddenComponents: string[] = [
    'ChiffreAdressesOverviewComponent',
    'UserAdministrationOverviewComponent'
  ];

  private static readonly forbiddenNavigationLinks: NavLinkEnum[] = [
    NavLinkEnum.userManagement,
    NavLinkEnum.chiffreAddressesManagement
  ];

  constructor(resellerPermissions: ResellerPermissions) {
    super(OfferManagerPolicy.forbiddenComponents,
      OfferManagerPolicy.forbiddenNavigationLinks,
      resellerPermissions);
  }
}
