import { Injectable } from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from "@angular/material/snack-bar";

@Injectable({
  providedIn: 'root',
})
export class UserNotifyService {
  public config: MatSnackBarConfig = new MatSnackBarConfig();
  private autoHide = 5000;

  constructor(private snackBar: MatSnackBar) { }

  public notify(message: string, addExtraClassName?: string, autoHide?: number): void {

    this.config.duration = autoHide ? autoHide : this.autoHide;
    this.config.panelClass = addExtraClassName ? [addExtraClassName] : null;
    this.snackBar.open(message, null, this.config);
  }

  // We can use this later on, if we want to add buttons to the snackbar
  public notifyWithAction(message: string, action: boolean, actionButtonLabel: string, addExtraClassName?: string, autoHide?: number): void {

    this.config.duration = autoHide ? autoHide : this.autoHide;
    this.config.panelClass = addExtraClassName ? [addExtraClassName] : null;
    this.snackBar.open(message, action && actionButtonLabel, this.config);
  }

  public error(message: string, autoHide?: number): void {
    this.notify(message, 'error-snack-bar', autoHide);
  }

  public defaultError(): void {
    this.notify('Ein Fehler ist aufgetreten', 'error-snack-bar');
  }

  public errorWithAction(message: string, action: boolean, actionButtonLabel: string, autoHide?: number): void {
    this.notifyWithAction(message, action, actionButtonLabel, 'error-snack-bar', autoHide);
  }

  public regular(message: string, autoHide?: number): void {
    this.notify(message, 'default-snack-bar', autoHide);
  }
}
