import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SpinnerService } from '../components/spinner/spinner.service';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SpinnerInterceptor implements HttpInterceptor {

  // calls running in background (they shouldn't have a loading spinner)
  private readonly backgroundCalls: string[] = ['offerOrdersWithOffers?offerOrderStates=Offen&take=0', 'companies?basicCompanyProfile=Ready'];

  constructor(private spinner: SpinnerService) {
  }

  intercept(req: HttpRequest<Object>, next: HttpHandler): Observable<HttpEvent<Object>> {
    this.backgroundCalls.forEach(url => {
      if (req.urlWithParams.includes(url)) {
        return next.handle(req);
      }
    });
    this.spinner.start(2000);
    return next.handle(req)
      .pipe(finalize(() => this.spinner.stop()));
  }
}
