import { Pipe, PipeTransform } from '@angular/core';
import { OfferOrderFilterEnum } from '../../offer-order-management/offer-order-overview/filter/models/offer-order-filter.enum';
import { CustomFrontendStatusEnum } from '../../offer-order-management/shared/enums/custom-frontend-status.enum';
import { OfferOrderStatusEnum, WorkflowStatusEnum } from '../reseller-api/client';

@Pipe({
  name: 'OfferOrderStatusPipe'
})
/*
Defines the displayed OfferOrderStatus depending on the OfferOrder State and OfferWorkflow State
 */
export class OfferOrderStatusPipe implements PipeTransform {

  static transform(value: OfferOrderFilterEnum | OfferOrderStatusEnum | WorkflowStatusEnum | CustomFrontendStatusEnum): string {
    switch (value) {
      case 'Offen': // 1
        return 'Offen';
      case 'Geschaltet': // 3
      case 'Online':
        return 'Online';
      case 'Deaktiviert': // 4
        return 'Deaktiviert';
      case 'StorniertVorOnlineschaltung': // 5
        return 'storniert vor Onlineschaltung';
      case 'Abgelaufen': // 6
      case 'Offline':
        return 'Offline';
      case 'Versendet': // 2
      case 'InBearbeitung': // 10
        return 'In Bearbeitung';
      case 'UpgradeLaeuft': // 11
        return 'Online, wird upgegradet';
      case 'StorniertNachOnlineschaltung': // 99
        return 'Storniert nach Onlineschaltung';
      case 'WaitingOnApproval': // offer workflowStatus
        return 'Wartet auf Freigabe';
      case 'ToBeReleased':
        return 'Wird veröffentlicht'; // customFrontendStatus
    }
  }

    transform(value: OfferOrderFilterEnum | OfferOrderStatusEnum | WorkflowStatusEnum | CustomFrontendStatusEnum): string {
      return OfferOrderStatusPipe.transform(value);
    }
}

