/**
 * Sa.Reseller.Api
 *
 * OpenAPI spec version: v2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Attachment } from '../model/attachment';
import { Company } from '../model/company';
import { CompanyCreate } from '../model/companyCreate';
import { CompanyEdit } from '../model/companyEdit';
import { CompanyLogo } from '../model/companyLogo';
import { CompanyNameMatch } from '../model/companyNameMatch';
import { CompanyProductCreate } from '../model/companyProductCreate';
import { CompanyProductEdit } from '../model/companyProductEdit';
import { CompanySegaProduct } from '../model/companySegaProduct';
import { CompanyV3 } from '../model/companyV3';
import { CompanyVideoCreateEdit } from '../model/companyVideoCreateEdit';
import { CreateEditCompanyName } from '../model/createEditCompanyName';
import { CreatedModel } from '../model/createdModel';
import { NODataPagedResultCompanyListItem } from '../model/nODataPagedResultCompanyListItem';
import { NODataPagedResultCompanySearchByNumberAndNameResult } from '../model/nODataPagedResultCompanySearchByNumberAndNameResult';
import { OfferOrderV3 } from '../model/offerOrderV3';
import { SegaInvitationCreate } from '../model/segaInvitationCreate';
import { SegaInvitationGet } from '../model/segaInvitationGet';
import { VideoListItem } from '../model/videoListItem';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { CompanyServiceInterface }                            from './company.serviceInterface';


@Injectable({
  providedIn: 'root'
})
export class CompanyService implements CompanyServiceInterface {

    protected basePath = '';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (let consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Validates if company number already exists for the reseller.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager, ResellerSalesManager
     * @param companyNumber
     * @param resellerId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companyCompanyByCompanyNumberAsync(companyNumber: string, resellerId?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public companyCompanyByCompanyNumberAsync(companyNumber: string, resellerId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public companyCompanyByCompanyNumberAsync(companyNumber: string, resellerId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public companyCompanyByCompanyNumberAsync(companyNumber: string, resellerId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (companyNumber === null || companyNumber === undefined) {
            throw new Error('Required parameter companyNumber was null or undefined when calling companyCompanyByCompanyNumberAsync.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (companyNumber !== undefined) {
            queryParameters = queryParameters.set('companyNumber', <any>companyNumber);
        }
        if (resellerId !== undefined) {
            queryParameters = queryParameters.set('resellerId', <any>resellerId);
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.head<any>(`${this.basePath}/api/v3/companies`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes the company.
     * Allowed roles: SystemAdmin, Reseller
     * @param companyId Company id
     * @param resellerId Reseller id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companyDeleteAsync(companyId: number, resellerId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public companyDeleteAsync(companyId: number, resellerId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public companyDeleteAsync(companyId: number, resellerId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public companyDeleteAsync(companyId: number, resellerId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling companyDeleteAsync.');
        }
        if (resellerId === null || resellerId === undefined) {
            throw new Error('Required parameter resellerId was null or undefined when calling companyDeleteAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/v2/resellers/${encodeURIComponent(String(resellerId))}/companies/${encodeURIComponent(String(companyId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the company data from the system.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager, ResellerSalesManager
     * @param companyId The company ID.
     * @param resellerId Reseller id
     * @param lastOfferOrderUpdate Optional. If specified, only include offer orders and offers that were changed from this date on.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companyGetAsync(companyId: number, resellerId: number, lastOfferOrderUpdate?: Date, observe?: 'body', reportProgress?: boolean): Observable<Company>;
    public companyGetAsync(companyId: number, resellerId: number, lastOfferOrderUpdate?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Company>>;
    public companyGetAsync(companyId: number, resellerId: number, lastOfferOrderUpdate?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Company>>;
    public companyGetAsync(companyId: number, resellerId: number, lastOfferOrderUpdate?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling companyGetAsync.');
        }
        if (resellerId === null || resellerId === undefined) {
            throw new Error('Required parameter resellerId was null or undefined when calling companyGetAsync.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (lastOfferOrderUpdate !== undefined) {
            queryParameters = queryParameters.set('lastOfferOrderUpdate', <any>lastOfferOrderUpdate.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Company>(`${this.basePath}/api/v2/resellers/${encodeURIComponent(String(resellerId))}/companies/${encodeURIComponent(String(companyId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes the logo for the specified company.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param companyId The company Id
     * @param resellerId The reseller Id (optional)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companyLogoDeleteAsync(companyId: number, resellerId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public companyLogoDeleteAsync(companyId: number, resellerId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public companyLogoDeleteAsync(companyId: number, resellerId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public companyLogoDeleteAsync(companyId: number, resellerId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling companyLogoDeleteAsync.');
        }
        if (resellerId === null || resellerId === undefined) {
            throw new Error('Required parameter resellerId was null or undefined when calling companyLogoDeleteAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/v2/resellers/${encodeURIComponent(String(resellerId))}/companies/${encodeURIComponent(String(companyId))}/logo`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the logo for the specified company.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager, ResellerSalesManager
     * @param companyId Company id
     * @param resellerId Reseller id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companyLogoGetAsync(companyId: number, resellerId: number, observe?: 'body', reportProgress?: boolean): Observable<CompanyLogo>;
    public companyLogoGetAsync(companyId: number, resellerId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CompanyLogo>>;
    public companyLogoGetAsync(companyId: number, resellerId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CompanyLogo>>;
    public companyLogoGetAsync(companyId: number, resellerId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling companyLogoGetAsync.');
        }
        if (resellerId === null || resellerId === undefined) {
            throw new Error('Required parameter resellerId was null or undefined when calling companyLogoGetAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<CompanyLogo>(`${this.basePath}/api/v2/resellers/${encodeURIComponent(String(resellerId))}/companies/${encodeURIComponent(String(companyId))}/logo`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Checks if the logo exists.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager, ResellerSalesManager
     * @param companyId Company id
     * @param resellerId Reseller id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companyLogoHeadAsync(companyId: number, resellerId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public companyLogoHeadAsync(companyId: number, resellerId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public companyLogoHeadAsync(companyId: number, resellerId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public companyLogoHeadAsync(companyId: number, resellerId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling companyLogoHeadAsync.');
        }
        if (resellerId === null || resellerId === undefined) {
            throw new Error('Required parameter resellerId was null or undefined when calling companyLogoHeadAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.head<any>(`${this.basePath}/api/v2/resellers/${encodeURIComponent(String(resellerId))}/companies/${encodeURIComponent(String(companyId))}/logo`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates the logo for the specified company. Send as MIME multipart content. Accepted image type is gif, jpg, png with pixel size 100x45.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param companyId Company id
     * @param resellerId Reseller id
     * @param file Logo file for upload
     * @param fileName Company Profile filename
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companyLogoPutAsync(companyId: number, resellerId: number, file: Blob, fileName?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public companyLogoPutAsync(companyId: number, resellerId: number, file: Blob, fileName?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public companyLogoPutAsync(companyId: number, resellerId: number, file: Blob, fileName?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public companyLogoPutAsync(companyId: number, resellerId: number, file: Blob, fileName?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling companyLogoPutAsync.');
        }
        if (resellerId === null || resellerId === undefined) {
            throw new Error('Required parameter resellerId was null or undefined when calling companyLogoPutAsync.');
        }
        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling companyLogoPutAsync.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (fileName !== undefined) {
            queryParameters = queryParameters.set('fileName', <any>fileName);
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            // @ts-ignore
            formParams = formParams.append('file', <any>file) || formParams;
        }

        return this.httpClient.put<any>(`${this.basePath}/api/v2/resellers/${encodeURIComponent(String(resellerId))}/companies/${encodeURIComponent(String(companyId))}/logo`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create company name for specified company
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param companyId Company id
     * @param createCompanyName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companyNamesMatchCreateMatchingName(companyId: number, createCompanyName: CreateEditCompanyName, observe?: 'body', reportProgress?: boolean): Observable<CompanyNameMatch>;
    public companyNamesMatchCreateMatchingName(companyId: number, createCompanyName: CreateEditCompanyName, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CompanyNameMatch>>;
    public companyNamesMatchCreateMatchingName(companyId: number, createCompanyName: CreateEditCompanyName, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CompanyNameMatch>>;
    public companyNamesMatchCreateMatchingName(companyId: number, createCompanyName: CreateEditCompanyName, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling companyNamesMatchCreateMatchingName.');
        }
        if (createCompanyName === null || createCompanyName === undefined) {
            throw new Error('Required parameter createCompanyName was null or undefined when calling companyNamesMatchCreateMatchingName.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<CompanyNameMatch>(`${this.basePath}/api/v3/companies/${encodeURIComponent(String(companyId))}/namesMatches`,
            createCompanyName,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete specified company name for company
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param companyId Company id
     * @param nameMatchId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companyNamesMatchDeleteMatchingName(companyId: number, nameMatchId: number, observe?: 'body', reportProgress?: boolean): Observable<CompanyNameMatch>;
    public companyNamesMatchDeleteMatchingName(companyId: number, nameMatchId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CompanyNameMatch>>;
    public companyNamesMatchDeleteMatchingName(companyId: number, nameMatchId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CompanyNameMatch>>;
    public companyNamesMatchDeleteMatchingName(companyId: number, nameMatchId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling companyNamesMatchDeleteMatchingName.');
        }
        if (nameMatchId === null || nameMatchId === undefined) {
            throw new Error('Required parameter nameMatchId was null or undefined when calling companyNamesMatchDeleteMatchingName.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.delete<CompanyNameMatch>(`${this.basePath}/api/v3/companies/${encodeURIComponent(String(companyId))}/namesMatches/${encodeURIComponent(String(nameMatchId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get company names for specified company
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param companyId Company id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companyNamesMatchGetMatchingNames(companyId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<CompanyNameMatch>>;
    public companyNamesMatchGetMatchingNames(companyId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CompanyNameMatch>>>;
    public companyNamesMatchGetMatchingNames(companyId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CompanyNameMatch>>>;
    public companyNamesMatchGetMatchingNames(companyId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling companyNamesMatchGetMatchingNames.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<CompanyNameMatch>>(`${this.basePath}/api/v3/companies/${encodeURIComponent(String(companyId))}/namesMatches`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Edit company name for specified company
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param companyId Company id
     * @param nameMatchId 
     * @param editCompanyName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companyNamesMatchUpdateMatchingName(companyId: number, nameMatchId: number, editCompanyName: CreateEditCompanyName, observe?: 'body', reportProgress?: boolean): Observable<CompanyNameMatch>;
    public companyNamesMatchUpdateMatchingName(companyId: number, nameMatchId: number, editCompanyName: CreateEditCompanyName, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CompanyNameMatch>>;
    public companyNamesMatchUpdateMatchingName(companyId: number, nameMatchId: number, editCompanyName: CreateEditCompanyName, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CompanyNameMatch>>;
    public companyNamesMatchUpdateMatchingName(companyId: number, nameMatchId: number, editCompanyName: CreateEditCompanyName, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling companyNamesMatchUpdateMatchingName.');
        }
        if (nameMatchId === null || nameMatchId === undefined) {
            throw new Error('Required parameter nameMatchId was null or undefined when calling companyNamesMatchUpdateMatchingName.');
        }
        if (editCompanyName === null || editCompanyName === undefined) {
            throw new Error('Required parameter editCompanyName was null or undefined when calling companyNamesMatchUpdateMatchingName.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<CompanyNameMatch>(`${this.basePath}/api/v3/companies/${encodeURIComponent(String(companyId))}/namesMatches/${encodeURIComponent(String(nameMatchId))}`,
            editCompanyName,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Adds a new company to the system.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param companyCreate Company data to be added to the store
     * @param resellerId Reseller id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companyPostAsync(companyCreate: CompanyCreate, resellerId: number, observe?: 'body', reportProgress?: boolean): Observable<CreatedModel>;
    public companyPostAsync(companyCreate: CompanyCreate, resellerId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreatedModel>>;
    public companyPostAsync(companyCreate: CompanyCreate, resellerId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreatedModel>>;
    public companyPostAsync(companyCreate: CompanyCreate, resellerId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (companyCreate === null || companyCreate === undefined) {
            throw new Error('Required parameter companyCreate was null or undefined when calling companyPostAsync.');
        }
        if (resellerId === null || resellerId === undefined) {
            throw new Error('Required parameter resellerId was null or undefined when calling companyPostAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<CreatedModel>(`${this.basePath}/api/v2/resellers/${encodeURIComponent(String(resellerId))}/companies`,
            companyCreate,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Adds a new Sega subscription to the list of the company products
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param resellerId 
     * @param companyId 
     * @param product 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companyProductCreateCompanyProductAsync(resellerId: number, companyId: number, product: CompanyProductCreate, observe?: 'body', reportProgress?: boolean): Observable<CreatedModel>;
    public companyProductCreateCompanyProductAsync(resellerId: number, companyId: number, product: CompanyProductCreate, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreatedModel>>;
    public companyProductCreateCompanyProductAsync(resellerId: number, companyId: number, product: CompanyProductCreate, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreatedModel>>;
    public companyProductCreateCompanyProductAsync(resellerId: number, companyId: number, product: CompanyProductCreate, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (resellerId === null || resellerId === undefined) {
            throw new Error('Required parameter resellerId was null or undefined when calling companyProductCreateCompanyProductAsync.');
        }
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling companyProductCreateCompanyProductAsync.');
        }
        if (product === null || product === undefined) {
            throw new Error('Required parameter product was null or undefined when calling companyProductCreateCompanyProductAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<CreatedModel>(`${this.basePath}/api/v2/resellers/${encodeURIComponent(String(resellerId))}/companies/${encodeURIComponent(String(companyId))}/products`,
            product,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes the company Sega product
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param resellerId 
     * @param companyId 
     * @param productId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companyProductDeleteAsync(resellerId: number, companyId: number, productId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public companyProductDeleteAsync(resellerId: number, companyId: number, productId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public companyProductDeleteAsync(resellerId: number, companyId: number, productId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public companyProductDeleteAsync(resellerId: number, companyId: number, productId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (resellerId === null || resellerId === undefined) {
            throw new Error('Required parameter resellerId was null or undefined when calling companyProductDeleteAsync.');
        }
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling companyProductDeleteAsync.');
        }
        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling companyProductDeleteAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/v2/resellers/${encodeURIComponent(String(resellerId))}/companies/${encodeURIComponent(String(companyId))}/products/${encodeURIComponent(String(productId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves the data of one Sega product from the system by the given product id
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager, ResellerSalesManager
     * @param resellerId The reseller ID.
     * @param productId The id of the product for which we want to retrieve the Sega product data
     * @param companyId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companyProductGetCompanyProductAsync(resellerId: number, productId: number, companyId: number, observe?: 'body', reportProgress?: boolean): Observable<CompanySegaProduct>;
    public companyProductGetCompanyProductAsync(resellerId: number, productId: number, companyId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CompanySegaProduct>>;
    public companyProductGetCompanyProductAsync(resellerId: number, productId: number, companyId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CompanySegaProduct>>;
    public companyProductGetCompanyProductAsync(resellerId: number, productId: number, companyId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (resellerId === null || resellerId === undefined) {
            throw new Error('Required parameter resellerId was null or undefined when calling companyProductGetCompanyProductAsync.');
        }
        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling companyProductGetCompanyProductAsync.');
        }
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling companyProductGetCompanyProductAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<CompanySegaProduct>(`${this.basePath}/api/v2/resellers/${encodeURIComponent(String(resellerId))}/companies/${encodeURIComponent(String(companyId))}/products/${encodeURIComponent(String(productId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves a list of company Sega products from the system by the given company id
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager, ResellerSalesManager
     * @param resellerId 
     * @param companyId The Id of the company for which we want to retrieve the products
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companyProductGetCompanyProductsAsync(resellerId: number, companyId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<CompanySegaProduct>>;
    public companyProductGetCompanyProductsAsync(resellerId: number, companyId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CompanySegaProduct>>>;
    public companyProductGetCompanyProductsAsync(resellerId: number, companyId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CompanySegaProduct>>>;
    public companyProductGetCompanyProductsAsync(resellerId: number, companyId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (resellerId === null || resellerId === undefined) {
            throw new Error('Required parameter resellerId was null or undefined when calling companyProductGetCompanyProductsAsync.');
        }
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling companyProductGetCompanyProductsAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<CompanySegaProduct>>(`${this.basePath}/api/v2/resellers/${encodeURIComponent(String(resellerId))}/companies/${encodeURIComponent(String(companyId))}/products`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Changes a Sega product in the list of the company products
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param resellerId The reseller ID.
     * @param productId The product ID.
     * @param product The Sega product.
     * @param companyId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companyProductUpdateCompanyProductAsync(resellerId: number, productId: number, product: CompanyProductEdit, companyId: number, observe?: 'body', reportProgress?: boolean): Observable<CompanySegaProduct>;
    public companyProductUpdateCompanyProductAsync(resellerId: number, productId: number, product: CompanyProductEdit, companyId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CompanySegaProduct>>;
    public companyProductUpdateCompanyProductAsync(resellerId: number, productId: number, product: CompanyProductEdit, companyId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CompanySegaProduct>>;
    public companyProductUpdateCompanyProductAsync(resellerId: number, productId: number, product: CompanyProductEdit, companyId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (resellerId === null || resellerId === undefined) {
            throw new Error('Required parameter resellerId was null or undefined when calling companyProductUpdateCompanyProductAsync.');
        }
        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling companyProductUpdateCompanyProductAsync.');
        }
        if (product === null || product === undefined) {
            throw new Error('Required parameter product was null or undefined when calling companyProductUpdateCompanyProductAsync.');
        }
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling companyProductUpdateCompanyProductAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<CompanySegaProduct>(`${this.basePath}/api/v2/resellers/${encodeURIComponent(String(resellerId))}/companies/${encodeURIComponent(String(companyId))}/products/${encodeURIComponent(String(productId))}`,
            product,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Edits a company in the system.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param companyId Company id
     * @param resellerId Reseller id
     * @param companyEdit Company data to be added to the store
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companyPutAsync(companyId: number, resellerId: number, companyEdit: CompanyEdit, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public companyPutAsync(companyId: number, resellerId: number, companyEdit: CompanyEdit, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public companyPutAsync(companyId: number, resellerId: number, companyEdit: CompanyEdit, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public companyPutAsync(companyId: number, resellerId: number, companyEdit: CompanyEdit, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling companyPutAsync.');
        }
        if (resellerId === null || resellerId === undefined) {
            throw new Error('Required parameter resellerId was null or undefined when calling companyPutAsync.');
        }
        if (companyEdit === null || companyEdit === undefined) {
            throw new Error('Required parameter companyEdit was null or undefined when calling companyPutAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/api/v2/resellers/${encodeURIComponent(String(resellerId))}/companies/${encodeURIComponent(String(companyId))}`,
            companyEdit,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Query companies using a search filter
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager, ResellerSalesManager
     * @param companyNameSearchTerm A part of the company name. Matches (case insensitively) all company names that contain this string.
     * @param companyNumberSearchTerm A part of the company number. Matches (case insensitively) all company numbers that contain this string.
     * @param isTopCompany If set to true, all companies that have a TopCompanyExpirationDate and where this date lies in the present or future.  If set to false, all companies that don&#39;t have a TopCompanyExpirationDate or where this date lies in the past.
     * @param originApiClients
     * @param isSebuCompany If set, all company which have a value according to the flag.  If not set, return all companies.
     * @param segaCompany If set, searches for SEGA companies that have any of the specified subscription status.  If set to null, no filter.
     * @param minOfferOrderPublicationDate THIS FIELD WILL CURRENTLY BE IGNORED!  If set, lists only companies that have any offer order online after that date.
     * @param maxOfferOrderPublicationDate THIS FIELD WILL CURRENTLY BE IGNORED!  If set, lists only companies that have any offer order online before that date.
     * @param companyProfile If set, searches for CompanyProfile subscriptions that have any of the specified subscription status.  If set to null, no filter.
     * @param basicCompanyProfile If set, searches for Companies with active BasicCompanyProfile or are BasicCompanyProfileReady  BasicCompanyProfile Ready only includes companies where BasicCompanyProfile is not activated  If set to null, no filter.
     * @param skip
     * @param take
     * @param resellerId If you are system admin, you can restrict searches to a reseller if you set this field.  Leave empty if you want to search for ALL records or if you are not system admin.  If you are system admin and set this field to any but your own reseller id you will  get a validation error.
     * @param orderBy
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companySearchCompaniesAsync(companyNameSearchTerm?: string, companyNumberSearchTerm?: string, isTopCompany?: boolean, originApiClients?: Array<string>, isSebuCompany?: boolean, segaCompany?: Array<string>, minOfferOrderPublicationDate?: Date, maxOfferOrderPublicationDate?: Date, companyProfile?: Array<string>, basicCompanyProfile?: Array<string>, skip?: number, take?: number, resellerId?: number, orderBy?: 'ByNumberASC' | 'ByNumberDESC' | 'ByNameASC' | 'ByNameDESC' | 'BySegaActiveASC' | 'BySegaActiveDESC' | 'ByTopCompanyASC' | 'ByTopCompanyDESC' | 'ByCompanyProfileV3ActiveASC' | 'ByCompanyProfileV3ActiveDESC' | 'ByCompanyProfileV2ActiveASC' | 'ByCompanyProfileV2ActiveDESC', observe?: 'body', reportProgress?: boolean): Observable<NODataPagedResultCompanyListItem>;
    public companySearchCompaniesAsync(companyNameSearchTerm?: string, companyNumberSearchTerm?: string, isTopCompany?: boolean, originApiClients?: Array<string>, isSebuCompany?: boolean, segaCompany?: Array<string>, minOfferOrderPublicationDate?: Date, maxOfferOrderPublicationDate?: Date, companyProfile?: Array<string>, basicCompanyProfile?: Array<string>, skip?: number, take?: number, resellerId?: number, orderBy?: 'ByNumberASC' | 'ByNumberDESC' | 'ByNameASC' | 'ByNameDESC' | 'BySegaActiveASC' | 'BySegaActiveDESC' | 'ByTopCompanyASC' | 'ByTopCompanyDESC' | 'ByCompanyProfileV3ActiveASC' | 'ByCompanyProfileV3ActiveDESC' | 'ByCompanyProfileV2ActiveASC' | 'ByCompanyProfileV2ActiveDESC', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<NODataPagedResultCompanyListItem>>;
    public companySearchCompaniesAsync(companyNameSearchTerm?: string, companyNumberSearchTerm?: string, isTopCompany?: boolean, originApiClients?: Array<string>, isSebuCompany?: boolean, segaCompany?: Array<string>, minOfferOrderPublicationDate?: Date, maxOfferOrderPublicationDate?: Date, companyProfile?: Array<string>, basicCompanyProfile?: Array<string>, skip?: number, take?: number, resellerId?: number, orderBy?: 'ByNumberASC' | 'ByNumberDESC' | 'ByNameASC' | 'ByNameDESC' | 'BySegaActiveASC' | 'BySegaActiveDESC' | 'ByTopCompanyASC' | 'ByTopCompanyDESC' | 'ByCompanyProfileV3ActiveASC' | 'ByCompanyProfileV3ActiveDESC' | 'ByCompanyProfileV2ActiveASC' | 'ByCompanyProfileV2ActiveDESC', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<NODataPagedResultCompanyListItem>>;
    public companySearchCompaniesAsync(companyNameSearchTerm?: string, companyNumberSearchTerm?: string, isTopCompany?: boolean, originApiClients?: Array<string>, isSebuCompany?: boolean, segaCompany?: Array<string>, minOfferOrderPublicationDate?: Date, maxOfferOrderPublicationDate?: Date, companyProfile?: Array<string>, basicCompanyProfile?: Array<string>, skip?: number, take?: number, resellerId?: number, orderBy?: 'ByNumberASC' | 'ByNumberDESC' | 'ByNameASC' | 'ByNameDESC' | 'BySegaActiveASC' | 'BySegaActiveDESC' | 'ByTopCompanyASC' | 'ByTopCompanyDESC' | 'ByCompanyProfileV3ActiveASC' | 'ByCompanyProfileV3ActiveDESC' | 'ByCompanyProfileV2ActiveASC' | 'ByCompanyProfileV2ActiveDESC', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (companyNameSearchTerm !== undefined) {
            queryParameters = queryParameters.set('companyNameSearchTerm', <any>companyNameSearchTerm);
        }
        if (companyNumberSearchTerm !== undefined) {
            queryParameters = queryParameters.set('companyNumberSearchTerm', <any>companyNumberSearchTerm);
        }
        if (isTopCompany !== undefined) {
            queryParameters = queryParameters.set('isTopCompany', <any>isTopCompany);
        }
        if (originApiClients) {
            originApiClients.forEach((element) => {
                queryParameters = queryParameters.append('originApiClients', <any>element);
            })
        }
        if (isSebuCompany !== undefined) {
            queryParameters = queryParameters.set('isSebuCompany', <any>isSebuCompany);
        }
        if (segaCompany) {
            segaCompany.forEach((element) => {
                queryParameters = queryParameters.append('segaCompany', <any>element);
            })
        }
        if (minOfferOrderPublicationDate !== undefined) {
            queryParameters = queryParameters.set('minOfferOrderPublicationDate', <any>minOfferOrderPublicationDate.toISOString());
        }
        if (maxOfferOrderPublicationDate !== undefined) {
            queryParameters = queryParameters.set('maxOfferOrderPublicationDate', <any>maxOfferOrderPublicationDate.toISOString());
        }
        if (companyProfile) {
            companyProfile.forEach((element) => {
                queryParameters = queryParameters.append('companyProfile', <any>element);
            })
        }
        if (basicCompanyProfile) {
            basicCompanyProfile.forEach((element) => {
                queryParameters = queryParameters.append('basicCompanyProfile', <any>element);
            })
        }
        if (skip !== undefined) {
            queryParameters = queryParameters.set('skip', <any>skip);
        }
        if (take !== undefined) {
            queryParameters = queryParameters.set('take', <any>take);
        }
        if (resellerId !== undefined) {
            queryParameters = queryParameters.set('resellerId', <any>resellerId);
        }
        if (orderBy !== undefined) {
            queryParameters = queryParameters.set('orderBy', <any>orderBy);
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'text/csv',
            'application/octet-stream'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<NODataPagedResultCompanyListItem>(`${this.basePath}/api/v3/companies`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search companies for autocomplete using a search filter for name and number
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager, ResellerSalesManager
     * @param searchTerm The search query, can be a number or part of a company name.
     * @param skip
     * @param take
     * @param resellerId If you are system admin, you can restrict searches to a reseller if you set this field.  Leave empty if you want to search for ALL records or if you are not system admin.  If you are system admin and set this field to any but your own reseller id you will  get a validation error.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companySearchCompaniesUsingNameOrNumberAsync(searchTerm?: string, skip?: number, take?: number, resellerId?: number, observe?: 'body', reportProgress?: boolean): Observable<NODataPagedResultCompanySearchByNumberAndNameResult>;
    public companySearchCompaniesUsingNameOrNumberAsync(searchTerm?: string, skip?: number, take?: number, resellerId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<NODataPagedResultCompanySearchByNumberAndNameResult>>;
    public companySearchCompaniesUsingNameOrNumberAsync(searchTerm?: string, skip?: number, take?: number, resellerId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<NODataPagedResultCompanySearchByNumberAndNameResult>>;
    public companySearchCompaniesUsingNameOrNumberAsync(searchTerm?: string, skip?: number, take?: number, resellerId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (searchTerm !== undefined) {
            queryParameters = queryParameters.set('searchTerm', <any>searchTerm);
        }
        if (skip !== undefined) {
            queryParameters = queryParameters.set('skip', <any>skip);
        }
        if (take !== undefined) {
            queryParameters = queryParameters.set('take', <any>take);
        }
        if (resellerId !== undefined) {
            queryParameters = queryParameters.set('resellerId', <any>resellerId);
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<NODataPagedResultCompanySearchByNumberAndNameResult>(`${this.basePath}/api/v3/companies/searchNameAndNumber`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Upgrade a SEBU company to a regular one
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param companyId Company id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companyV3UpgradeSebuCompany(companyId: number, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public companyV3UpgradeSebuCompany(companyId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public companyV3UpgradeSebuCompany(companyId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public companyV3UpgradeSebuCompany(companyId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling companyV3UpgradeSebuCompany.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.post<string>(`${this.basePath}/api/v3/companies/${encodeURIComponent(String(companyId))}/upgradeSebuCompany`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes a Video with the given videoId.
     * Allowed roles: SystemAdmin, Reseller
     * @param videoId Id of the video to delete
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companyVideoDeleteVideo(videoId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public companyVideoDeleteVideo(videoId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public companyVideoDeleteVideo(videoId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public companyVideoDeleteVideo(videoId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (videoId === null || videoId === undefined) {
            throw new Error('Required parameter videoId was null or undefined when calling companyVideoDeleteVideo.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/v2/videos/${encodeURIComponent(String(videoId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns all videos related to the given resellerId.
     * Allowed roles: SystemAdmin, Reseller
     * @param resellerId The reseller id.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companyVideoGetVideos(resellerId?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<VideoListItem>>;
    public companyVideoGetVideos(resellerId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VideoListItem>>>;
    public companyVideoGetVideos(resellerId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VideoListItem>>>;
    public companyVideoGetVideos(resellerId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (resellerId !== undefined) {
            queryParameters = queryParameters.set('resellerId', <any>resellerId);
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<VideoListItem>>(`${this.basePath}/api/v2/videos`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Adds a new Video to database.
     * Allowed roles: SystemAdmin, Reseller
     * @param newVideo New Video object of type CompanyVideoCreate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companyVideoNewVideo(newVideo: CompanyVideoCreateEdit, observe?: 'body', reportProgress?: boolean): Observable<CreatedModel>;
    public companyVideoNewVideo(newVideo: CompanyVideoCreateEdit, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreatedModel>>;
    public companyVideoNewVideo(newVideo: CompanyVideoCreateEdit, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreatedModel>>;
    public companyVideoNewVideo(newVideo: CompanyVideoCreateEdit, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (newVideo === null || newVideo === undefined) {
            throw new Error('Required parameter newVideo was null or undefined when calling companyVideoNewVideo.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<CreatedModel>(`${this.basePath}/api/v2/videos`,
            newVideo,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates Video with given videoId.
     * Allowed roles: SystemAdmin, Reseller
     * @param updateVideo Data to update
     * @param videoId Id of the video to update
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companyVideoUpdateVideo(updateVideo: CompanyVideoCreateEdit, videoId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public companyVideoUpdateVideo(updateVideo: CompanyVideoCreateEdit, videoId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public companyVideoUpdateVideo(updateVideo: CompanyVideoCreateEdit, videoId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public companyVideoUpdateVideo(updateVideo: CompanyVideoCreateEdit, videoId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (updateVideo === null || updateVideo === undefined) {
            throw new Error('Required parameter updateVideo was null or undefined when calling companyVideoUpdateVideo.');
        }
        if (videoId === null || videoId === undefined) {
            throw new Error('Required parameter videoId was null or undefined when calling companyVideoUpdateVideo.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/api/v2/videos/${encodeURIComponent(String(videoId))}`,
            updateVideo,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Exports a CSV for companies by using a search filter.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager, ResellerSalesManager
     * @param companyNameSearchTerm A part of the company name. Matches (case insensitively) all company names that contain this string.
     * @param companyNumberSearchTerm A part of the company number. Matches (case insensitively) all company numbers that contain this string.
     * @param isTopCompany If set to true, all companies that have a TopCompanyExpirationDate and where this date lies in the present or future.  If set to false, all companies that don&#39;t have a TopCompanyExpirationDate or where this date lies in the past.
     * @param originApiClients
     * @param isSebuCompany If set, all company which have a value according to the flag.  If not set, return all companies.
     * @param segaCompany If set, searches for SEGA companies that have any of the specified subscription status.  If set to null, no filter.
     * @param minOfferOrderPublicationDate THIS FIELD WILL CURRENTLY BE IGNORED!  If set, lists only companies that have any offer order online after that date.
     * @param maxOfferOrderPublicationDate THIS FIELD WILL CURRENTLY BE IGNORED!  If set, lists only companies that have any offer order online before that date.
     * @param companyProfile If set, searches for CompanyProfile subscriptions that have any of the specified subscription status.  If set to null, no filter.
     * @param basicCompanyProfile If set, searches for Companies with active BasicCompanyProfile or are BasicCompanyProfileReady  BasicCompanyProfile Ready only includes companies where BasicCompanyProfile is not activated  If set to null, no filter.
     * @param skip
     * @param take
     * @param resellerId If you are system admin, you can restrict searches to a reseller if you set this field.  Leave empty if you want to search for ALL records or if you are not system admin.  If you are system admin and set this field to any but your own reseller id you will  get a validation error.
     * @param orderBy
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportCompanies(companyNameSearchTerm?: string, companyNumberSearchTerm?: string, isTopCompany?: boolean, originApiClients?: Array<string>, isSebuCompany?: boolean, segaCompany?: Array<string>, minOfferOrderPublicationDate?: Date, maxOfferOrderPublicationDate?: Date, companyProfile?: Array<string>, basicCompanyProfile?: Array<string>, skip?: number, take?: number, resellerId?: number, orderBy?: 'ByNumberASC' | 'ByNumberDESC' | 'ByNameASC' | 'ByNameDESC' | 'BySegaActiveASC' | 'BySegaActiveDESC' | 'ByTopCompanyASC' | 'ByTopCompanyDESC' | 'ByCompanyProfileV3ActiveASC' | 'ByCompanyProfileV3ActiveDESC' | 'ByCompanyProfileV2ActiveASC' | 'ByCompanyProfileV2ActiveDESC', observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public exportCompanies(companyNameSearchTerm?: string, companyNumberSearchTerm?: string, isTopCompany?: boolean, originApiClients?: Array<string>, isSebuCompany?: boolean, segaCompany?: Array<string>, minOfferOrderPublicationDate?: Date, maxOfferOrderPublicationDate?: Date, companyProfile?: Array<string>, basicCompanyProfile?: Array<string>, skip?: number, take?: number, resellerId?: number, orderBy?: 'ByNumberASC' | 'ByNumberDESC' | 'ByNameASC' | 'ByNameDESC' | 'BySegaActiveASC' | 'BySegaActiveDESC' | 'ByTopCompanyASC' | 'ByTopCompanyDESC' | 'ByCompanyProfileV3ActiveASC' | 'ByCompanyProfileV3ActiveDESC' | 'ByCompanyProfileV2ActiveASC' | 'ByCompanyProfileV2ActiveDESC', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public exportCompanies(companyNameSearchTerm?: string, companyNumberSearchTerm?: string, isTopCompany?: boolean, originApiClients?: Array<string>, isSebuCompany?: boolean, segaCompany?: Array<string>, minOfferOrderPublicationDate?: Date, maxOfferOrderPublicationDate?: Date, companyProfile?: Array<string>, basicCompanyProfile?: Array<string>, skip?: number, take?: number, resellerId?: number, orderBy?: 'ByNumberASC' | 'ByNumberDESC' | 'ByNameASC' | 'ByNameDESC' | 'BySegaActiveASC' | 'BySegaActiveDESC' | 'ByTopCompanyASC' | 'ByTopCompanyDESC' | 'ByCompanyProfileV3ActiveASC' | 'ByCompanyProfileV3ActiveDESC' | 'ByCompanyProfileV2ActiveASC' | 'ByCompanyProfileV2ActiveDESC', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public exportCompanies(companyNameSearchTerm?: string, companyNumberSearchTerm?: string, isTopCompany?: boolean, originApiClients?: Array<string>, isSebuCompany?: boolean, segaCompany?: Array<string>, minOfferOrderPublicationDate?: Date, maxOfferOrderPublicationDate?: Date, companyProfile?: Array<string>, basicCompanyProfile?: Array<string>, skip?: number, take?: number, resellerId?: number, orderBy?: 'ByNumberASC' | 'ByNumberDESC' | 'ByNameASC' | 'ByNameDESC' | 'BySegaActiveASC' | 'BySegaActiveDESC' | 'ByTopCompanyASC' | 'ByTopCompanyDESC' | 'ByCompanyProfileV3ActiveASC' | 'ByCompanyProfileV3ActiveDESC' | 'ByCompanyProfileV2ActiveASC' | 'ByCompanyProfileV2ActiveDESC', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (companyNameSearchTerm !== undefined) {
            queryParameters = queryParameters.set('companyNameSearchTerm', <any>companyNameSearchTerm);
        }
        if (companyNumberSearchTerm !== undefined) {
            queryParameters = queryParameters.set('companyNumberSearchTerm', <any>companyNumberSearchTerm);
        }
        if (isTopCompany !== undefined) {
            queryParameters = queryParameters.set('isTopCompany', <any>isTopCompany);
        }
        if (originApiClients) {
            originApiClients.forEach((element) => {
                queryParameters = queryParameters.append('originApiClients', <any>element);
            })
        }
        if (isSebuCompany !== undefined) {
            queryParameters = queryParameters.set('isSebuCompany', <any>isSebuCompany);
        }
        if (segaCompany) {
            segaCompany.forEach((element) => {
                queryParameters = queryParameters.append('segaCompany', <any>element);
            })
        }
        if (minOfferOrderPublicationDate !== undefined) {
            queryParameters = queryParameters.set('minOfferOrderPublicationDate', <any>minOfferOrderPublicationDate.toISOString());
        }
        if (maxOfferOrderPublicationDate !== undefined) {
            queryParameters = queryParameters.set('maxOfferOrderPublicationDate', <any>maxOfferOrderPublicationDate.toISOString());
        }
        if (companyProfile) {
            companyProfile.forEach((element) => {
                queryParameters = queryParameters.append('companyProfile', <any>element);
            })
        }
        if (basicCompanyProfile) {
            basicCompanyProfile.forEach((element) => {
                queryParameters = queryParameters.append('basicCompanyProfile', <any>element);
            })
        }
        if (skip !== undefined) {
            queryParameters = queryParameters.set('skip', <any>skip);
        }
        if (take !== undefined) {
            queryParameters = queryParameters.set('take', <any>take);
        }
        if (resellerId !== undefined) {
            queryParameters = queryParameters.set('resellerId', <any>resellerId);
        }
        if (orderBy !== undefined) {
            queryParameters = queryParameters.set('orderBy', <any>orderBy);
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get(`${this.basePath}/api/v3/companies/csv`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a company.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager, ResellerSalesManager
     * @param companyId The company ID.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompany(companyId: number, observe?: 'body', reportProgress?: boolean): Observable<CompanyV3>;
    public getCompany(companyId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CompanyV3>>;
    public getCompany(companyId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CompanyV3>>;
    public getCompany(companyId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getCompany.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<CompanyV3>(`${this.basePath}/api/v3/companies/${encodeURIComponent(String(companyId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a company&#39;s offer orders.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager, ResellerSalesManager
     * @param companyId The company ID.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanyOffers(companyId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<OfferOrderV3>>;
    public getCompanyOffers(companyId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<OfferOrderV3>>>;
    public getCompanyOffers(companyId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<OfferOrderV3>>>;
    public getCompanyOffers(companyId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getCompanyOffers.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<OfferOrderV3>>(`${this.basePath}/api/v3/companies/${encodeURIComponent(String(companyId))}/offerOrders`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new Sega Invitation for the specified company for the given email and sends a notification
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param resellerId The reseller ID.
     * @param companyId The company ID.
     * @param segaInvitationCreate The sega invitation create.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public segaInvitationCreateAsync(resellerId: number, companyId: number, segaInvitationCreate: SegaInvitationCreate, observe?: 'body', reportProgress?: boolean): Observable<SegaInvitationGet>;
    public segaInvitationCreateAsync(resellerId: number, companyId: number, segaInvitationCreate: SegaInvitationCreate, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SegaInvitationGet>>;
    public segaInvitationCreateAsync(resellerId: number, companyId: number, segaInvitationCreate: SegaInvitationCreate, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SegaInvitationGet>>;
    public segaInvitationCreateAsync(resellerId: number, companyId: number, segaInvitationCreate: SegaInvitationCreate, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (resellerId === null || resellerId === undefined) {
            throw new Error('Required parameter resellerId was null or undefined when calling segaInvitationCreateAsync.');
        }
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling segaInvitationCreateAsync.');
        }
        if (segaInvitationCreate === null || segaInvitationCreate === undefined) {
            throw new Error('Required parameter segaInvitationCreate was null or undefined when calling segaInvitationCreateAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<SegaInvitationGet>(`${this.basePath}/api/v2/resellers/${encodeURIComponent(String(resellerId))}/companies/${encodeURIComponent(String(companyId))}/SegaInvitations`,
            segaInvitationCreate,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Marks the Sega Invitation Data specified by the given code as expired
     * Allowed role: 
     * @param resellerId The reseller ID.
     * @param companyId The company ID.
     * @param segaInvitationCode segaInvitationCode field of the credential
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public segaInvitationExpireAsyncAdmin(resellerId: number, companyId: number, segaInvitationCode: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public segaInvitationExpireAsyncAdmin(resellerId: number, companyId: number, segaInvitationCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public segaInvitationExpireAsyncAdmin(resellerId: number, companyId: number, segaInvitationCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public segaInvitationExpireAsyncAdmin(resellerId: number, companyId: number, segaInvitationCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (resellerId === null || resellerId === undefined) {
            throw new Error('Required parameter resellerId was null or undefined when calling segaInvitationExpireAsyncAdmin.');
        }
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling segaInvitationExpireAsyncAdmin.');
        }
        if (segaInvitationCode === null || segaInvitationCode === undefined) {
            throw new Error('Required parameter segaInvitationCode was null or undefined when calling segaInvitationExpireAsyncAdmin.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.put<any>(`${this.basePath}/api/v2/resellers/${encodeURIComponent(String(resellerId))}/companies/${encodeURIComponent(String(companyId))}/SegaInvitations/${encodeURIComponent(String(segaInvitationCode))}/expire`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves a given Sega Invitation code from the system.
     * Allowed role: 
     * @param segaInvitationCode segaInvitationCode field of the credential
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public segaInvitationGetAsyncAdmin(segaInvitationCode: string, observe?: 'body', reportProgress?: boolean): Observable<SegaInvitationGet>;
    public segaInvitationGetAsyncAdmin(segaInvitationCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SegaInvitationGet>>;
    public segaInvitationGetAsyncAdmin(segaInvitationCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SegaInvitationGet>>;
    public segaInvitationGetAsyncAdmin(segaInvitationCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (segaInvitationCode === null || segaInvitationCode === undefined) {
            throw new Error('Required parameter segaInvitationCode was null or undefined when calling segaInvitationGetAsyncAdmin.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<SegaInvitationGet>(`${this.basePath}/api/v2/SegaInvitations/${encodeURIComponent(String(segaInvitationCode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves a list of sega invitations from the system by the given company id.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param companyId Company id for which to list the invitations for
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public segaInvitationListAsync(companyId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<SegaInvitationGet>>;
    public segaInvitationListAsync(companyId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SegaInvitationGet>>>;
    public segaInvitationListAsync(companyId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SegaInvitationGet>>>;
    public segaInvitationListAsync(companyId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling segaInvitationListAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<SegaInvitationGet>>(`${this.basePath}/api/v2/SegaInvitations/${encodeURIComponent(String(companyId))}/list`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Resends the notification containing the Sega Invitation data to the email contained in the credential
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param resellerId The reseller ID.
     * @param companyId The company ID.
     * @param segaInvitationCode segaInvitationCode field of the credential
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public segaInvitationResendEmailAsync(resellerId: number, companyId: number, segaInvitationCode: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public segaInvitationResendEmailAsync(resellerId: number, companyId: number, segaInvitationCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public segaInvitationResendEmailAsync(resellerId: number, companyId: number, segaInvitationCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public segaInvitationResendEmailAsync(resellerId: number, companyId: number, segaInvitationCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (resellerId === null || resellerId === undefined) {
            throw new Error('Required parameter resellerId was null or undefined when calling segaInvitationResendEmailAsync.');
        }
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling segaInvitationResendEmailAsync.');
        }
        if (segaInvitationCode === null || segaInvitationCode === undefined) {
            throw new Error('Required parameter segaInvitationCode was null or undefined when calling segaInvitationResendEmailAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.put<any>(`${this.basePath}/api/v2/resellers/${encodeURIComponent(String(resellerId))}/companies/${encodeURIComponent(String(companyId))}/SegaInvitations/${encodeURIComponent(String(segaInvitationCode))}/resend-email`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates the logo for the specified company. Accepted image types are gif, jpg and png with a size of 100x45px.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param companyId The company ID
     * @param logo The company logo
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCompanyLogo(companyId: number, logo: Attachment, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateCompanyLogo(companyId: number, logo: Attachment, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateCompanyLogo(companyId: number, logo: Attachment, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateCompanyLogo(companyId: number, logo: Attachment, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling updateCompanyLogo.');
        }
        if (logo === null || logo === undefined) {
            throw new Error('Required parameter logo was null or undefined when calling updateCompanyLogo.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/api/v3/companies/${encodeURIComponent(String(companyId))}/logo`,
            logo,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
