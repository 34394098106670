/**
 * Sa.Reseller.Api
 *
 * OpenAPI spec version: v2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export type OfferOrderStatusEnum = 'Offen' | 'Versendet' | 'Geschaltet' | 'Deaktiviert' | 'StorniertVorOnlineschaltung' | 'Abgelaufen' | 'InBearbeitung' | 'UpgradeLaeuft' | 'StorniertNachOnlineschaltung';

export const OfferOrderStatusEnum = {
    Offen: 'Offen' as OfferOrderStatusEnum,
    Versendet: 'Versendet' as OfferOrderStatusEnum,
    Geschaltet: 'Geschaltet' as OfferOrderStatusEnum,
    Deaktiviert: 'Deaktiviert' as OfferOrderStatusEnum,
    StorniertVorOnlineschaltung: 'StorniertVorOnlineschaltung' as OfferOrderStatusEnum,
    Abgelaufen: 'Abgelaufen' as OfferOrderStatusEnum,
    InBearbeitung: 'InBearbeitung' as OfferOrderStatusEnum,
    UpgradeLaeuft: 'UpgradeLaeuft' as OfferOrderStatusEnum,
    StorniertNachOnlineschaltung: 'StorniertNachOnlineschaltung' as OfferOrderStatusEnum
}
