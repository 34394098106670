<ng-container *ngIf="(numberOfOpenOfferOrders$ | async) as numberOfOpenOfferOrders">
  <ng-container *ngIf="numberOfOpenOfferOrders !== '0'; else noOpenOfferOrders" style="white-space: pre;">
    <div
       fxLayout="row" fxLayoutGap="0.5em" (click)="goToTable()"
       matTooltip="{{displayTexts['labels'].tooltip}}">
      <mat-chip-list fxLayoutAlign="center center">
        <mat-chip class="chip-warn counter" (click)="goToTable()">{{ numberOfOpenOfferOrders }}</mat-chip>
      </mat-chip-list>
      <span>
        {{ numberOfOpenOfferOrders !== '1' ? displayTexts['labels'].openOfferOrders : displayTexts['labels'].openOfferOrder }}
      </span>
    </div>
  </ng-container>
  <ng-template #noOpenOfferOrders>
    {{ displayTexts['labels'].noOpenOfferOrders }}
  </ng-template>
</ng-container>
