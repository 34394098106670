/**
 * Sa.Reseller.Api
 *
 * OpenAPI spec version: v2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export type OfferOrderTemplateEnum = 'Classic' | 'StandardHtml' | 'StandardLayout';

export const OfferOrderTemplateEnum = {
    Classic: 'Classic' as OfferOrderTemplateEnum,
    StandardHtml: 'StandardHtml' as OfferOrderTemplateEnum,
    StandardLayout: 'StandardLayout' as OfferOrderTemplateEnum
}
