import { NgModule } from '@angular/core';
import { LoginComponent } from './login.component';
import { FormsModule } from '@angular/forms';
import { AppCommonsModule } from '../app-commons.module';

@NgModule({
  imports: [
    AppCommonsModule,
    FormsModule
  ],
  declarations: [LoginComponent],
})

export class LoginModule { }
