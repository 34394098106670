<ng-container *ngIf="(numberOpenBasicCompanyProfiles$ | async) as numberOpenBasicCompanyProfiles$">
  <ng-container *ngIf="numberOpenBasicCompanyProfiles$ !== '0'; else noOpenBasicCompanyProfile">
    <div fxLayout="row" fxLayoutGap="0.5em" (click)="goToTable()" matTooltip="{{displayTexts['labels'].tooltip}}">
      <mat-chip-list fxLayoutAlign="center center">
        <mat-chip class="chip-warn counter" (click)="goToTable()">{{ numberOpenBasicCompanyProfiles$ }}</mat-chip>
      </mat-chip-list>
      <span>
        {{ numberOpenBasicCompanyProfiles$ === '1' ? displayTexts['labels'].oneOpenBasicCompanyProfile : displayTexts['labels'].openBasicCompanyProfile }}
      </span>
    </div>
  </ng-container>
  <ng-template #noOpenBasicCompanyProfile>
    {{ displayTexts['labels'].noOpenBasicCompanyProfile }}
  </ng-template>
</ng-container>
