/**
 * Sa.Reseller.Api
 *
 * OpenAPI spec version: v2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export type CompanyProfileV3ActionEnum = 'RequestActivation' | 'Activate' | 'RequestDeactivation' | 'Deactivate' | 'Expire' | 'Extend' | 'SaveDraft' | 'FetchDraft' | 'DeleteDraft' | 'Publish' | 'SendPreviewLink';

export const CompanyProfileV3ActionEnum = {
    RequestActivation: 'RequestActivation' as CompanyProfileV3ActionEnum,
    Activate: 'Activate' as CompanyProfileV3ActionEnum,
    RequestDeactivation: 'RequestDeactivation' as CompanyProfileV3ActionEnum,
    Deactivate: 'Deactivate' as CompanyProfileV3ActionEnum,
    Expire: 'Expire' as CompanyProfileV3ActionEnum,
    Extend: 'Extend' as CompanyProfileV3ActionEnum,
    SaveDraft: 'SaveDraft' as CompanyProfileV3ActionEnum,
    FetchDraft: 'FetchDraft' as CompanyProfileV3ActionEnum,
    DeleteDraft: 'DeleteDraft' as CompanyProfileV3ActionEnum,
    Publish: 'Publish' as CompanyProfileV3ActionEnum,
    SendPreviewLink: 'SendPreviewLink' as CompanyProfileV3ActionEnum
}
