/**
 * Sa.Reseller.Api
 *
 * OpenAPI spec version: v2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ChiffreAddress } from '../model/chiffreAddress';
import { ChiffreAddressDataFields } from '../model/chiffreAddressDataFields';
import { Reseller } from '../model/reseller';
import { ResellerFilesRoot } from '../model/resellerFilesRoot';
import { User } from '../model/user';
import { UserCreate } from '../model/userCreate';
import { UserEdit } from '../model/userEdit';
import { UserPasswordEdit } from '../model/userPasswordEdit';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { ResellerServiceInterface }                            from './reseller.serviceInterface';


@Injectable({
  providedIn: 'root'
})
export class ResellerService implements ResellerServiceInterface {

    protected basePath = '';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (let consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Creates a new chiffre address for a reseller.
     * Allowed roles: SystemAdmin, Reseller
     * @param resellerId Reseller id of the reseller we want to create chiffre address for
     * @param addressToCreate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resellerChiffreAddressesCreateAsync(resellerId: number, addressToCreate: ChiffreAddressDataFields, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public resellerChiffreAddressesCreateAsync(resellerId: number, addressToCreate: ChiffreAddressDataFields, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public resellerChiffreAddressesCreateAsync(resellerId: number, addressToCreate: ChiffreAddressDataFields, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public resellerChiffreAddressesCreateAsync(resellerId: number, addressToCreate: ChiffreAddressDataFields, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (resellerId === null || resellerId === undefined) {
            throw new Error('Required parameter resellerId was null or undefined when calling resellerChiffreAddressesCreateAsync.');
        }
        if (addressToCreate === null || addressToCreate === undefined) {
            throw new Error('Required parameter addressToCreate was null or undefined when calling resellerChiffreAddressesCreateAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<number>(`${this.basePath}/api/v2/resellers/${encodeURIComponent(String(resellerId))}/chiffreAddresses`,
            addressToCreate,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes a chiffre address
     * Allowed roles: SystemAdmin, Reseller
     * @param resellerId Reseller id of the reseller we want to delete chiffre address data for
     * @param chiffreAddressId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resellerChiffreAddressesDeleteAsync(resellerId: number, chiffreAddressId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public resellerChiffreAddressesDeleteAsync(resellerId: number, chiffreAddressId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public resellerChiffreAddressesDeleteAsync(resellerId: number, chiffreAddressId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public resellerChiffreAddressesDeleteAsync(resellerId: number, chiffreAddressId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (resellerId === null || resellerId === undefined) {
            throw new Error('Required parameter resellerId was null or undefined when calling resellerChiffreAddressesDeleteAsync.');
        }
        if (chiffreAddressId === null || chiffreAddressId === undefined) {
            throw new Error('Required parameter chiffreAddressId was null or undefined when calling resellerChiffreAddressesDeleteAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/v2/resellers/${encodeURIComponent(String(resellerId))}/chiffreAddresses/${encodeURIComponent(String(chiffreAddressId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates a chiffre address.
     * Allowed roles: SystemAdmin, Reseller
     * @param resellerId Reseller id of the reseller we want to update chiffre address data for
     * @param chiffreAddressId 
     * @param addressChanges 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resellerChiffreAddressesEditAsync(resellerId: number, chiffreAddressId: number, addressChanges: ChiffreAddressDataFields, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public resellerChiffreAddressesEditAsync(resellerId: number, chiffreAddressId: number, addressChanges: ChiffreAddressDataFields, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public resellerChiffreAddressesEditAsync(resellerId: number, chiffreAddressId: number, addressChanges: ChiffreAddressDataFields, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public resellerChiffreAddressesEditAsync(resellerId: number, chiffreAddressId: number, addressChanges: ChiffreAddressDataFields, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (resellerId === null || resellerId === undefined) {
            throw new Error('Required parameter resellerId was null or undefined when calling resellerChiffreAddressesEditAsync.');
        }
        if (chiffreAddressId === null || chiffreAddressId === undefined) {
            throw new Error('Required parameter chiffreAddressId was null or undefined when calling resellerChiffreAddressesEditAsync.');
        }
        if (addressChanges === null || addressChanges === undefined) {
            throw new Error('Required parameter addressChanges was null or undefined when calling resellerChiffreAddressesEditAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/api/v2/resellers/${encodeURIComponent(String(resellerId))}/chiffreAddresses/${encodeURIComponent(String(chiffreAddressId))}`,
            addressChanges,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves a chiffre address of a reseller.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param resellerId Reseller id of the reseller we want to fetch chiffre address data for
     * @param chiffreAddressId the id of the chiffre
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resellerChiffreAddressesGetAsync(resellerId: number, chiffreAddressId: number, observe?: 'body', reportProgress?: boolean): Observable<ChiffreAddress>;
    public resellerChiffreAddressesGetAsync(resellerId: number, chiffreAddressId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChiffreAddress>>;
    public resellerChiffreAddressesGetAsync(resellerId: number, chiffreAddressId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChiffreAddress>>;
    public resellerChiffreAddressesGetAsync(resellerId: number, chiffreAddressId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (resellerId === null || resellerId === undefined) {
            throw new Error('Required parameter resellerId was null or undefined when calling resellerChiffreAddressesGetAsync.');
        }
        if (chiffreAddressId === null || chiffreAddressId === undefined) {
            throw new Error('Required parameter chiffreAddressId was null or undefined when calling resellerChiffreAddressesGetAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<ChiffreAddress>(`${this.basePath}/api/v2/resellers/${encodeURIComponent(String(resellerId))}/chiffreAddresses/${encodeURIComponent(String(chiffreAddressId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves a list of chiffre addresses of a reseller.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param resellerId Reseller id of the reseller we want to fetch chiffre address data for
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resellerChiffreAddressesListAsync(resellerId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ChiffreAddress>>;
    public resellerChiffreAddressesListAsync(resellerId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ChiffreAddress>>>;
    public resellerChiffreAddressesListAsync(resellerId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ChiffreAddress>>>;
    public resellerChiffreAddressesListAsync(resellerId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (resellerId === null || resellerId === undefined) {
            throw new Error('Required parameter resellerId was null or undefined when calling resellerChiffreAddressesListAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<ChiffreAddress>>(`${this.basePath}/api/v2/resellers/${encodeURIComponent(String(resellerId))}/chiffreAddresses`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves the file tree.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager, ResellerSalesManager
     * @param file
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resellerFilesDeleteFile(file: string, observe?: 'body', reportProgress?: boolean): Observable<ResellerFilesRoot>;
    public resellerFilesDeleteFile(file: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResellerFilesRoot>>;
    public resellerFilesDeleteFile(file: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResellerFilesRoot>>;
    public resellerFilesDeleteFile(file: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling resellerFilesDeleteFile.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (file !== undefined) {
            queryParameters = queryParameters.set('file', <any>file);
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.delete<ResellerFilesRoot>(`${this.basePath}/api/v2/resellers/deleteFile`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves the file tree.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager, ResellerSalesManager
     * @param resellerId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resellerFilesGet(resellerId: number, observe?: 'body', reportProgress?: boolean): Observable<ResellerFilesRoot>;
    public resellerFilesGet(resellerId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResellerFilesRoot>>;
    public resellerFilesGet(resellerId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResellerFilesRoot>>;
    public resellerFilesGet(resellerId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (resellerId === null || resellerId === undefined) {
            throw new Error('Required parameter resellerId was null or undefined when calling resellerFilesGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<ResellerFilesRoot>(`${this.basePath}/api/v2/resellers/${encodeURIComponent(String(resellerId))}/files`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves the file tree.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager, ResellerSalesManager
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resellerFilesGetTree(observe?: 'body', reportProgress?: boolean): Observable<ResellerFilesRoot>;
    public resellerFilesGetTree(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResellerFilesRoot>>;
    public resellerFilesGetTree(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResellerFilesRoot>>;
    public resellerFilesGetTree(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<ResellerFilesRoot>(`${this.basePath}/api/v2/resellers/allFiles`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves the file tree.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager, ResellerSalesManager
     * @param path
     * @param fileName
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resellerFilesUpdateDirectory(path: string, fileName: string, observe?: 'body', reportProgress?: boolean): Observable<ResellerFilesRoot>;
    public resellerFilesUpdateDirectory(path: string, fileName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResellerFilesRoot>>;
    public resellerFilesUpdateDirectory(path: string, fileName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResellerFilesRoot>>;
    public resellerFilesUpdateDirectory(path: string, fileName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (path === null || path === undefined) {
            throw new Error('Required parameter path was null or undefined when calling resellerFilesUpdateDirectory.');
        }
        if (fileName === null || fileName === undefined) {
            throw new Error('Required parameter fileName was null or undefined when calling resellerFilesUpdateDirectory.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (path !== undefined) {
            queryParameters = queryParameters.set('path', <any>path);
        }
        if (fileName !== undefined) {
            queryParameters = queryParameters.set('fileName', <any>fileName);
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.post<ResellerFilesRoot>(`${this.basePath}/api/v2/resellers/UpdateDirectory`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves an existing reseller from the system.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager, ResellerSalesManager
     * @param resellerId Reseller id of the reseller we want to fetch data for
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resellerGetAsync(resellerId: number, observe?: 'body', reportProgress?: boolean): Observable<Reseller>;
    public resellerGetAsync(resellerId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reseller>>;
    public resellerGetAsync(resellerId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reseller>>;
    public resellerGetAsync(resellerId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (resellerId === null || resellerId === undefined) {
            throw new Error('Required parameter resellerId was null or undefined when calling resellerGetAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Reseller>(`${this.basePath}/api/v2/resellers/${encodeURIComponent(String(resellerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates an User.
     * Allowed roles: SystemAdmin, Reseller
     * @param userCreate
     * @param resellerId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userCreateUserAsync(userCreate: UserCreate, resellerId: number, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public userCreateUserAsync(userCreate: UserCreate, resellerId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public userCreateUserAsync(userCreate: UserCreate, resellerId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public userCreateUserAsync(userCreate: UserCreate, resellerId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (userCreate === null || userCreate === undefined) {
            throw new Error('Required parameter userCreate was null or undefined when calling userCreateUserAsync.');
        }
        if (resellerId === null || resellerId === undefined) {
            throw new Error('Required parameter resellerId was null or undefined when calling userCreateUserAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<number>(`${this.basePath}/api/v2/resellers/${encodeURIComponent(String(resellerId))}/users`,
            userCreate,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes an User.
     * Allowed roles: SystemAdmin, Reseller
     * @param resellerId
     * @param userId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userDeleteUserAsync(resellerId: number, userId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public userDeleteUserAsync(resellerId: number, userId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public userDeleteUserAsync(resellerId: number, userId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public userDeleteUserAsync(resellerId: number, userId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (resellerId === null || resellerId === undefined) {
            throw new Error('Required parameter resellerId was null or undefined when calling userDeleteUserAsync.');
        }
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling userDeleteUserAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/v2/resellers/${encodeURIComponent(String(resellerId))}/users/${encodeURIComponent(String(userId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Edits the password of an User.
     * Allowed roles: SystemAdmin, Reseller
     * @param userPasswordEdit
     * @param resellerId
     * @param userId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userEditPasswordAsync(userPasswordEdit: UserPasswordEdit, resellerId: number, userId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public userEditPasswordAsync(userPasswordEdit: UserPasswordEdit, resellerId: number, userId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public userEditPasswordAsync(userPasswordEdit: UserPasswordEdit, resellerId: number, userId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public userEditPasswordAsync(userPasswordEdit: UserPasswordEdit, resellerId: number, userId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (userPasswordEdit === null || userPasswordEdit === undefined) {
            throw new Error('Required parameter userPasswordEdit was null or undefined when calling userEditPasswordAsync.');
        }
        if (resellerId === null || resellerId === undefined) {
            throw new Error('Required parameter resellerId was null or undefined when calling userEditPasswordAsync.');
        }
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling userEditPasswordAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/api/v2/resellers/${encodeURIComponent(String(resellerId))}/users/${encodeURIComponent(String(userId))}/password`,
            userPasswordEdit,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Edits an User.
     * Allowed roles: SystemAdmin, Reseller
     * @param userEdit
     * @param resellerId
     * @param userId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userEditUserAsync(userEdit: UserEdit, resellerId: number, userId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public userEditUserAsync(userEdit: UserEdit, resellerId: number, userId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public userEditUserAsync(userEdit: UserEdit, resellerId: number, userId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public userEditUserAsync(userEdit: UserEdit, resellerId: number, userId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (userEdit === null || userEdit === undefined) {
            throw new Error('Required parameter userEdit was null or undefined when calling userEditUserAsync.');
        }
        if (resellerId === null || resellerId === undefined) {
            throw new Error('Required parameter resellerId was null or undefined when calling userEditUserAsync.');
        }
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling userEditUserAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/api/v2/resellers/${encodeURIComponent(String(resellerId))}/users/${encodeURIComponent(String(userId))}`,
            userEdit,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves a single user of a reseller.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager, ResellerSalesManager
     * @param resellerId
     * @param userId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userGetUserAsync(resellerId: number, userId: number, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public userGetUserAsync(resellerId: number, userId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public userGetUserAsync(resellerId: number, userId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public userGetUserAsync(resellerId: number, userId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (resellerId === null || resellerId === undefined) {
            throw new Error('Required parameter resellerId was null or undefined when calling userGetUserAsync.');
        }
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling userGetUserAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<User>(`${this.basePath}/api/v2/resellers/${encodeURIComponent(String(resellerId))}/users/${encodeURIComponent(String(userId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves a list of users of a reseller.
     * Allowed roles: SystemAdmin, Reseller
     * @param resellerId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userGetUsersAsync(resellerId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<User>>;
    public userGetUsersAsync(resellerId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<User>>>;
    public userGetUsersAsync(resellerId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<User>>>;
    public userGetUsersAsync(resellerId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (resellerId === null || resellerId === undefined) {
            throw new Error('Required parameter resellerId was null or undefined when calling userGetUsersAsync.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<User>>(`${this.basePath}/api/v2/resellers/${encodeURIComponent(String(resellerId))}/users`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
