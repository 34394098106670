<div *ngIf="!cookiesAccepted" class="cookie-hint">
  <label>
  Cookies erleichtern die Bereitstellung unserer Dienste. Mit der Nutzung unserer Dienste erklären Sie sich damit
  einverstanden, dass wir Cookies verwenden.
  </label>
  <a class="cookie-hint-link e2eCookieHint" target="_blank" href="https://www.stellenanzeigen.de/ueber-uns/datenschutz/">Mehr Informationen... </a>
  <button mat-icon-button id="cookie-accept-button" class="e2eButtonCookieAccept" (click)="setCookieAccepted()">
    Ok
  </button>
</div>
