import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NavElement } from '../../../main-layout/nav.model';
import { UserRoleType } from '../../reseller-api/client';
import { UserCookieService } from '../cookies/user-cookie/user-cookie.service';
import { ResellerCacheService } from '../reseller/reseller.service';
import { PermissionsPolicyFactory } from './permissions-policy.factory';
import { ResellerPermissions } from './reseller-permissions.model';
import { UserRolePermissionPolicy } from './user-role-permissions-policy.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserPermissionsService {

  constructor(private resellerService: ResellerCacheService,
              private userCookieService: UserCookieService) {
  }

  /***
   * Returns the list of navigation links for each user depending on his user role.
   * @returns {Observable<NavElement[]>}
   */
  public getNavigationLinksAsync(): Observable<NavElement[]> {
    return this.getRolePermissionsPolicyAsync().pipe(map(p => p.getNavigationLinks()));
  }

  /***
   * Calculates whether a user is authorised to access a specific component.
   * @param {string} componentName specifies the component the user wants to access.
   * @returns {Observable<boolean>} true if the user is allowed to access the component, false otherwise
   */
  public canVisitComponentAsync(componentName: string): Observable<boolean> {
    return this.getRolePermissionsPolicyAsync().pipe(map(p => p.canVisitComponent(componentName)));
  }

  public getPermissions(): Observable<ResellerPermissions> {
    return this.resellerService.getCurrentReseller().pipe(map(reseller => new ResellerPermissions(reseller)));
  }

  private getRolePermissionsPolicyAsync(): Observable<UserRolePermissionPolicy> {
    return this.resellerService.getCurrentReseller()
      .pipe(map(reseller => {
        const resellerPermissions: ResellerPermissions = new ResellerPermissions(reseller);
        return PermissionsPolicyFactory.getPermissionsPolicy(this.getUserRole(), resellerPermissions);
      }));
  }

  private getUserRole(): UserRoleType {
    return this.userCookieService.getUserDataCookie().userRole;
  }
}
