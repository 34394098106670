/**
 * Sa.Reseller.Api
 *
 * OpenAPI spec version: v2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export type OfferStatusEnum = 'NichtKomplettEingegeben' | 'WirdGeschaltet' | 'Online' | 'Offline';

export const OfferStatusEnum = {
    NichtKomplettEingegeben: 'NichtKomplettEingegeben' as OfferStatusEnum,
    WirdGeschaltet: 'WirdGeschaltet' as OfferStatusEnum,
    Online: 'Online' as OfferStatusEnum,
    Offline: 'Offline' as OfferStatusEnum
}
