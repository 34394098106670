/**
 * Sa.Reseller.Api
 *
 * OpenAPI spec version: v2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CategoryData } from '../model/categoryData';
import { Country } from '../model/country';
import { FederalState } from '../model/federalState';
import { Location } from '../model/location';
import { LocationSearchResult } from '../model/locationSearchResult';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { StellenanzeigenServicesServiceInterface }                            from './stellenanzeigenServices.serviceInterface';


@Injectable({
  providedIn: 'root'
})
export class StellenanzeigenServicesService implements StellenanzeigenServicesServiceInterface {

    protected basePath = '';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (let consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Returns a list of all known &#39;branches of industry&#39;.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager, ResellerSalesManager
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public branchOfIndustryGetAsync(observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public branchOfIndustryGetAsync(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public branchOfIndustryGetAsync(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public branchOfIndustryGetAsync(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<string>>(`${this.basePath}/api/v2/branchesOfIndustry`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns the categories.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager, ResellerSalesManager
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public categoryGetAllCategoriesAsync(observe?: 'body', reportProgress?: boolean): Observable<Array<CategoryData>>;
    public categoryGetAllCategoriesAsync(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CategoryData>>>;
    public categoryGetAllCategoriesAsync(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CategoryData>>>;
    public categoryGetAllCategoriesAsync(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<CategoryData>>(`${this.basePath}/api/v2/categories`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns all countries.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager, ResellerSalesManager
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locationGetAllCountriesAsync(observe?: 'body', reportProgress?: boolean): Observable<Array<Country>>;
    public locationGetAllCountriesAsync(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Country>>>;
    public locationGetAllCountriesAsync(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Country>>>;
    public locationGetAllCountriesAsync(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<Country>>(`${this.basePath}/api/v2/locations/countries`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns all federal states.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager, ResellerSalesManager
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locationGetAllFederalStatesAsync(observe?: 'body', reportProgress?: boolean): Observable<Array<FederalState>>;
    public locationGetAllFederalStatesAsync(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FederalState>>>;
    public locationGetAllFederalStatesAsync(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FederalState>>>;
    public locationGetAllFederalStatesAsync(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<FederalState>>(`${this.basePath}/api/v2/locations/federalstates`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns Country by location id.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager, ResellerSalesManager
     * @param locationId 
     * @param location 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locationGetCountry(locationId: string, location: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public locationGetCountry(locationId: string, location: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public locationGetCountry(locationId: string, location: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public locationGetCountry(locationId: string, location: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (locationId === null || locationId === undefined) {
            throw new Error('Required parameter locationId was null or undefined when calling locationGetCountry.');
        }
        if (location === null || location === undefined) {
            throw new Error('Required parameter location was null or undefined when calling locationGetCountry.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (locationId !== undefined) {
            queryParameters = queryParameters.set('locationId', <any>locationId);
        }
        if (location !== undefined) {
            queryParameters = queryParameters.set('location', <any>location);
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<string>(`${this.basePath}/api/v2/locations/country`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search for a matching city.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager, ResellerSalesManager
     * @param searchValue The location string to search for.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locationSearchAsync(searchValue: string, observe?: 'body', reportProgress?: boolean): Observable<Array<LocationSearchResult>>;
    public locationSearchAsync(searchValue: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LocationSearchResult>>>;
    public locationSearchAsync(searchValue: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LocationSearchResult>>>;
    public locationSearchAsync(searchValue: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (searchValue === null || searchValue === undefined) {
            throw new Error('Required parameter searchValue was null or undefined when calling locationSearchAsync.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (searchValue !== undefined) {
            queryParameters = queryParameters.set('searchValue', <any>searchValue);
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<LocationSearchResult>>(`${this.basePath}/api/v2/locations`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search for a matching city. You can use city name (or starting chars), city ID, post code or post code ID.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager, ResellerSalesManager
     * @param searchValue The location string to search for.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locationSearchCitiesAsync(searchValue: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Location>>;
    public locationSearchCitiesAsync(searchValue: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Location>>>;
    public locationSearchCitiesAsync(searchValue: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Location>>>;
    public locationSearchCitiesAsync(searchValue: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (searchValue === null || searchValue === undefined) {
            throw new Error('Required parameter searchValue was null or undefined when calling locationSearchCitiesAsync.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (searchValue !== undefined) {
            queryParameters = queryParameters.set('searchValue', <any>searchValue);
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<Location>>(`${this.basePath}/api/v2/locations/cities`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search for a matching city. You can use city name (or starting chars), city ID, post code or post code ID in given country.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager, ResellerSalesManager
     * @param searchValue 
     * @param countryLimitation 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locationSearchCitiesInCountryAsync(searchValue: string, countryLimitation: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Location>>;
    public locationSearchCitiesInCountryAsync(searchValue: string, countryLimitation: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Location>>>;
    public locationSearchCitiesInCountryAsync(searchValue: string, countryLimitation: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Location>>>;
    public locationSearchCitiesInCountryAsync(searchValue: string, countryLimitation: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (searchValue === null || searchValue === undefined) {
            throw new Error('Required parameter searchValue was null or undefined when calling locationSearchCitiesInCountryAsync.');
        }
        if (countryLimitation === null || countryLimitation === undefined) {
            throw new Error('Required parameter countryLimitation was null or undefined when calling locationSearchCitiesInCountryAsync.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (searchValue !== undefined) {
            queryParameters = queryParameters.set('searchValue', <any>searchValue);
        }
        if (countryLimitation !== undefined) {
            queryParameters = queryParameters.set('countryLimitation', <any>countryLimitation);
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<Location>>(`${this.basePath}/api/v2/locations/citiesInCountry`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
