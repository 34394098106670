import { Component, HostListener } from '@angular/core';
import { NavElement } from '../nav.model';
import { AppConfig, IAppConfig } from '../../app.config';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FooterLinksProvider } from './footer-links';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  animations: [
    trigger('visibilityChanged', [
      state('show' , style({ height: '5.5em', display: 'flex'})),
      state('hide', style({ height: 0, opacity: 0, display: 'none' })),
      transition('show => hide', animate('100ms')),
      transition('hide => show', animate('100ms')),
    ])
  ],
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent  {
  public serviceLinks: NavElement[] = FooterLinksProvider.getServiceLinks();
  public legalMattersLinks: NavElement[] = FooterLinksProvider.getLegalLinks();
  public adPageLinks: NavElement[] = FooterLinksProvider.getAdvertLinks();
  public config: IAppConfig = AppConfig;
  public configJson: string = JSON.stringify(this.config.environment);
  showOrHideFooter = 'show';
  date: Date = new Date();
  version: string;

  constructor() {
    // eslint-disable-next-line
    this.version = require('assets/version.json').version;
  }

  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    this.showOrHideFooter = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop  ? 'hide' : 'show';
  }
}


