export * from './company.service';
import { CompanyService } from './company.service';
export * from './companyCompanyProfile.service';
import { CompanyCompanyProfileService } from './companyCompanyProfile.service';
export * from './companyProfileV3.service';
import { CompanyProfileV3Service } from './companyProfileV3.service';
export * from './global.service';
import { GlobalService } from './global.service';
export * from './internalProductName.service';
import { InternalProductNameService } from './internalProductName.service';
export * from './offer.service';
import { OfferService } from './offer.service';
export * from './payment.service';
import { PaymentService } from './payment.service';
export * from './reseller.service';
import { ResellerService } from './reseller.service';
export * from './stellenanzeigenServices.service';
import { StellenanzeigenServicesService } from './stellenanzeigenServices.service';
export * from './user.service';
import { UserService } from './user.service';
export const APIS = [CompanyService, CompanyCompanyProfileService, CompanyProfileV3Service, GlobalService, InternalProductNameService, OfferService, PaymentService, ResellerService, StellenanzeigenServicesService, UserService];
