/**
 * Sa.Reseller.Api
 *
 * OpenAPI spec version: v2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export type OfferOrderRefreshOptionEnum = 'Nach15Tagen' | 'Alle30Tage' | 'Alle7Tage' | 'Alle3Tage';

export const OfferOrderRefreshOptionEnum = {
    Nach15Tagen: 'Nach15Tagen' as OfferOrderRefreshOptionEnum,
    Alle30Tage: 'Alle30Tage' as OfferOrderRefreshOptionEnum,
    Alle7Tage: 'Alle7Tage' as OfferOrderRefreshOptionEnum,
    Alle3Tage: 'Alle3Tage' as OfferOrderRefreshOptionEnum
}
