import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserNotifyService } from '../services/user-notify/user-notify.service';
import { tap } from 'rxjs/operators';
import * as Raven from 'raven-js';

@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private notifyService: UserNotifyService) {}

  intercept( req: HttpRequest<Object>, next: HttpHandler ): Observable<HttpEvent<Object>> {
    return next.handle(req).pipe(tap(() => {}, err => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 500 || err.status === 0 || err.status === 400) {
          this.notifyService.defaultError();
          Raven.captureException(err.message || err);
        }
      }
    }));
  }
}
