import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Reseller, ResellerProductSubscription, ResellerService } from '../../reseller-api/client';
import { ProductHelper } from '../../utils/product/product.helper';
import { UserCookieService } from '../cookies/user-cookie/user-cookie.service';

@Injectable({
  providedIn: 'root',
})
export class ResellerCacheService {

  private reseller$: Observable<Reseller>;

  private static nonSegaProductsFilter(s: ResellerProductSubscription): boolean {
    return !ProductHelper.isSegaProduct(s.product.id);
  }

  constructor(private resellerApi: ResellerService,
              private userCookieService: UserCookieService) {
  }

  public getCurrentReseller(): Observable<Reseller> {
    if (!this.reseller$) {
      const resellerId: number = this.userCookieService.getUserDataCookie().resellerId;
      this.reseller$ = this.resellerApi.resellerGetAsync(resellerId).pipe(shareReplay(1));
    }
    return this.reseller$;
  }

  public getCurrentProductSubscriptions(): Observable<ResellerProductSubscription[]> {
    return this.getCurrentReseller().pipe(map((reseller: Reseller) => [].concat(reseller.resellerProductSubscriptions)));
  }

  /**
   * Sorts available Products of current reseller and removes sega products from list.
   * @returns {Observable<ResellerProductSubscription[]>} sorted list of resellerProductSubscriptions
   */
  public getCurrentProductSubscriptionsSorted(): Observable<ResellerProductSubscription[]> {
    return this.getCurrentProductSubscriptions()
      .pipe(map((resellerProductSubscription: ResellerProductSubscription[]) =>
        resellerProductSubscription
          .sort((a: ResellerProductSubscription, b: ResellerProductSubscription): number =>
            a.product.sort - b.product.sort)
      ));
  }

  /**
   * Resets the reseller.
   * This is necessary for example if a user logs out and another user with different rights regarding
   * product selection logs in, we need to retrieve a refreshed list.
   */
  public resetReseller(): void {
    this.reseller$ = undefined;
  }
}
