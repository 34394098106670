import { Injectable } from '@angular/core';
import { CookieService } from '../cookie.service';
import { UserCookie } from './user-cookie';
import { CookieOptions } from '../cookie-options';
import { User } from '../../../reseller-api/client';

@Injectable({
  providedIn: 'root',
})
export class UserCookieService {
  readonly COOKIE_NAME: string = 'userdata';
  readonly COOKIE_OPTIONS: CookieOptions = {
    expires: 1,
    path: '/'
  };

  constructor(private readonly cookieService: CookieService) {
  }

  getUserDataCookie(): UserCookie {
    return this.cookieService.get(this.COOKIE_NAME);
  }

  setUserDataCookie(username: string, password: string, userData: User): void {
    const cookie: UserCookie = {
      userName: username,
      loggedIn: true,
      password: password,
      resellerId: userData.resellerId,
      userRole: userData.role
    };
    this.cookieService.set(this.COOKIE_NAME, cookie, this.COOKIE_OPTIONS);
  }

  deleteUserDataCookie(): void {
    this.cookieService.delete(this.COOKIE_NAME, this.COOKIE_OPTIONS);
  }
}
