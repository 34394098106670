import { Component } from '@angular/core';
import { SpinnerService } from './spinner.service';
import { Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})

export class SpinnerComponent {
  public loading: Observable<boolean>;

  public constructor(spinnerService: SpinnerService) {
    this.loading = spinnerService.status.pipe(distinctUntilChanged());
  }
}
