import { InjectionToken, NgModule } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiModule } from './client/api.module';
import { Configuration } from './client/configuration';

export const RENDERER_API_CONFIG: InjectionToken<Configuration> = new InjectionToken<Configuration>('RENDERER_API_CONFIG');
const apiConfig: Configuration = new Configuration({ apiKeys: environment.apiKey, basePath: environment.rendererApiEndpoint });
export function configFactory(): Configuration {
  return apiConfig;
}

@NgModule({
  providers: [
    { provide: RENDERER_API_CONFIG, useValue: apiConfig },
  ],
  imports: [
    ApiModule.forRoot(configFactory),
  ]
})

// never import this module in any other module than the AppModule
export class RendererApiModule {
}
