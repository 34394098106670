import { IEnvironment } from './environment.type';

export const environment: IEnvironment = {
  // currently, production is not possible because the selectors change there
  production: false,
  environmentName: 'e2e',
  apiEndpoint: 'https://localhost:44300',
  rendererApiEndpoint: 'https://localhost:44302',
  apiKey: {'X-Api-Key': 'aa9f3bca318e40a389a9128a282f0258'},
  sentryDSN: ''
};
