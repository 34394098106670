/**
 * Sa.Reseller.Api
 *
 * OpenAPI spec version: v2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * State of the company profile
 */
export type CompanyProfileStatusEnum = 'Erstellt' | 'AktivierungAngefordert' | 'Aktiviert' | 'DeaktivierungAngefordert' | 'Deaktiviert' | 'Abgelaufen';

export const CompanyProfileStatusEnum = {
    Erstellt: 'Erstellt' as CompanyProfileStatusEnum,
    AktivierungAngefordert: 'AktivierungAngefordert' as CompanyProfileStatusEnum,
    Aktiviert: 'Aktiviert' as CompanyProfileStatusEnum,
    DeaktivierungAngefordert: 'DeaktivierungAngefordert' as CompanyProfileStatusEnum,
    Deaktiviert: 'Deaktiviert' as CompanyProfileStatusEnum,
    Abgelaufen: 'Abgelaufen' as CompanyProfileStatusEnum
}
