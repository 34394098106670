import { NavElement, NavLinkEnum } from '../nav.model';

export class FooterLinksProvider {
  private static readonly FOOTER_SERVICE_LINKS: NavElement[] = [
    {id: NavLinkEnum.aboutUs, name: 'Über Uns', path: 'https://www.stellenanzeigen.de/ueber-uns/?'},
    {id: NavLinkEnum.contact, name: 'Kontakt', path: 'https://www.stellenanzeigen.de/ueber-uns/kontakt/?'}
  ];

  private static readonly FOOTER_LEGALMATTERS_LINKS: NavElement[] = [
    {id: NavLinkEnum.impressum, name: 'Impressum', path: 'https://www.stellenanzeigen.de/ueber-uns/impressum/?'},
    {id: NavLinkEnum.agb, name: 'AGB', path: 'https://www.stellenanzeigen.de/ueber-uns/agb/?'},
    {id: NavLinkEnum.dataProtection, name: 'Datenschutz', path: 'https://www.stellenanzeigen.de/ueber-uns/datenschutz/'}
  ];

  private static readonly FOOTER_ADVERT_LINKS: NavElement[] = [

  ];

  public static getServiceLinks(): NavElement[] {
    return [].concat(FooterLinksProvider.FOOTER_SERVICE_LINKS);
  }

  public static getLegalLinks(): NavElement[] {
    return [].concat(FooterLinksProvider.FOOTER_LEGALMATTERS_LINKS);
  }

  public static getAdvertLinks(): NavElement[] {
    return [].concat(FooterLinksProvider.FOOTER_ADVERT_LINKS);
  }
}
