/**
 * Sa.Reseller.Api
 *
 * OpenAPI spec version: v2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Specify origin of company name
 */
export type CompanyNameOriginEnum = 'Company' | 'CompanyProfile' | 'AlternativeName';

export const CompanyNameOriginEnum = {
    Company: 'Company' as CompanyNameOriginEnum,
    CompanyProfile: 'CompanyProfile' as CompanyNameOriginEnum,
    AlternativeName: 'AlternativeName' as CompanyNameOriginEnum
}
