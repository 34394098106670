import { Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';

export const appRoutes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full'},
  { path: 'login', component: LoginComponent },
  { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: 'company', loadChildren: () => import('./company/company.module').then(m => m.CompanyModule) },
  { path: 'chiffre-addresses', loadChildren: () => import('./chiffre-addresses/chiffre-addresses.module').then(m => m.ChiffreAddressesModule) },
  {
    path: 'offer-order',
    loadChildren: () => import('./offer-order-management/offer-order-management.module').then(m => m.OfferOrderManagementModule)
  },
  { path: 'downloads', loadChildren: () => import('./downloads/download.module').then(m => m.DownloadModule) },
  {
    path: 'user-administration',
    loadChildren: () => import('./user-administration/user-administration.module').then(m => m.UserAdministrationModule)
  },
  {
    path: 'video-administration',
    loadChildren: () => import('./video-administration/video-administration.module').then(m => m.VideoAdministrationModule)
  },
  {
    path: 'statistics-administration',
    loadChildren: () => import('./statistics-administration/statistics-administration.module').then(m => m.StatisticsAdministrationModule)
  },
  {
    path: 'internal-product-name-administration',
    loadChildren: () => import('./internal-product-name-administration/internal-product-name-administration.module').then(m => m.InternalProductNameAdministrationModule)
  },
  { path: '**', redirectTo: '/home' }
];
