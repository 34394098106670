import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Configuration } from './configuration';

import { CompanyService } from './api/company.service';
import { CompanyCompanyProfileService } from './api/companyCompanyProfile.service';
import { CompanyProfileV3Service } from './api/companyProfileV3.service';
import { GlobalService } from './api/global.service';
import { InternalProductNameService } from './api/internalProductName.service';
import { OfferService } from './api/offer.service';
import { PaymentService } from './api/payment.service';
import { ResellerService } from './api/reseller.service';
import { StellenanzeigenServicesService } from './api/stellenanzeigenServices.service';
import { UserService } from './api/user.service';

@NgModule({
  imports:      [ CommonModule, HttpClientModule ],
  declarations: [],
  exports:      [],
  providers: [
    CompanyService,
    CompanyCompanyProfileService,
    CompanyProfileV3Service,
    GlobalService,
    InternalProductNameService,
    OfferService,
    PaymentService,
    ResellerService,
    StellenanzeigenServicesService,
    UserService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        }
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import your base AppModule only.');
        }
    }
}
