import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  NODataPagedResultOfferOrderViewItem,
  OfferOrderAndOfferSearchFilter,
  OfferService
} from '../../../../shared/reseller-api/client';

@Injectable({
  providedIn: 'root',
})
export class OfferOrderSearchService {
  constructor(private offerApi: OfferService) {
  }

  public searchOfferOrder(searchParams: OfferOrderAndOfferSearchFilter): Observable<NODataPagedResultOfferOrderViewItem> {
    return this.offerApi.offerOrderV3SearchOfferOrdersAndOffersAsync(
      searchParams.offerName,
      searchParams.externalOfferId,
      searchParams.companySearchTerm,
      searchParams.onlineAt,
      searchParams.offerOrderNumber,
      searchParams.productTypes,
      searchParams.products,
      searchParams.companyId,
      searchParams.includeCanceledOfferOrders,
      searchParams.publishedAtOrAfter,
      searchParams.publishedAtOrBefore,
      searchParams.createdAtOrAfter,
      searchParams.createdAtOrBefore,
      searchParams.offerOrderStates,
      searchParams.topJob,
      searchParams.tableEntry,
      searchParams.jobBlitz,
      searchParams.kalaydo,
      searchParams.itJobs,
      searchParams.tenhil,
      searchParams.refresh,
      searchParams.isSebu,
      searchParams.socialMedia,
      searchParams.workflowStates,
      searchParams.skip,
      searchParams.take,
      searchParams.resellerId,
      searchParams.orderBy,
      'body'
    );
  }

  public searchOfferOrderWithCsvResponse(searchParams: OfferOrderAndOfferSearchFilter): Observable<Blob> {
    return this.offerApi.exportOfferOrders(
        searchParams.offerName,
        searchParams.externalOfferId,
        searchParams.companySearchTerm,
        searchParams.onlineAt,
        searchParams.offerOrderNumber,
        searchParams.productTypes,
        searchParams.products,
        searchParams.companyId,
        searchParams.includeCanceledOfferOrders,
        searchParams.publishedAtOrAfter,
        searchParams.publishedAtOrBefore,
        searchParams.createdAtOrAfter,
        searchParams.createdAtOrBefore,
        searchParams.offerOrderStates,
        searchParams.topJob,
        searchParams.tableEntry,
        searchParams.jobBlitz,
        searchParams.kalaydo,
        searchParams.itJobs,
        searchParams.tenhil,
        searchParams.refresh,
        searchParams.isSebu,
        searchParams.socialMedia,
        searchParams.workflowStates,
        searchParams.skip,
        searchParams.take,
        searchParams.resellerId,
        searchParams.orderBy,
        'body');
  }

  public isOfferOrderNumberInUse(offerOrderNumber: string): Observable<boolean> {
     return this.offerApi.offerOrderV3OfferOrderByNumberAsync(offerOrderNumber.trim()).pipe(
       // eslint-disable-next-line
       catchError(err => of(false)))
       .pipe(map(response => response === null));
  }
}
