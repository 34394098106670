/**
 * Sa.Reseller.Api
 *
 * OpenAPI spec version: v2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export type OfferSalaryUnitEnum = 'Hour' | 'Week' | 'Month' | 'Year';

export const OfferSalaryUnitEnum = {
    Hour: 'Hour' as OfferSalaryUnitEnum,
    Week: 'Week' as OfferSalaryUnitEnum,
    Month: 'Month' as OfferSalaryUnitEnum,
    Year: 'Year' as OfferSalaryUnitEnum
}
