/**
 * Sa.Reseller.Api
 *
 * OpenAPI spec version: v2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export type TopJobDurationEnum = 'Unlimited' | 'Days07' | 'Days14' | 'Days21';

export const TopJobDurationEnum = {
    Unlimited: 'Unlimited' as TopJobDurationEnum,
    Days07: 'Days07' as TopJobDurationEnum,
    Days14: 'Days14' as TopJobDurationEnum,
    Days21: 'Days21' as TopJobDurationEnum
}
