import {
  EXCLUDE_WITH_CHIFFRE,
  FILLED_BY_STANZ,
  REFRESH_NOT_BOOKED_BY_RESELLER,
  TABLE_ENTRY_NOT_BOOKED_BY_RESELLER,
  INDIVIDUAL_REFRESH_MODIFIERS, ONLY_WHEN_REFRESH_AND_JOBBLITZ
} from './modifiers/modifiers.model';
import {
  Config,
  CREATE_EDIT_CORRECT_DISPLAY,
  CORRECT_DISPLAY,
  DISPLAY,
  OfferOrderProperty,
  OfferProperty,
  ProductProperty
} from './product-config.model';

// eslint-disable-next-line
export namespace OnlineOnlyConfig {
// Html-Layout
  const html: Config = new Config();
  html.product = [
    {property: ProductProperty.companyName, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: ProductProperty.companyNumber, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: ProductProperty.companyLink, modes: DISPLAY},
    {property: ProductProperty.product, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: ProductProperty.number, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: ProductProperty.offerOrderStatus, modes: CREATE_EDIT_CORRECT_DISPLAY}
  ];
  html.offerOrder = [
    {property: OfferOrderProperty.chiffre, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.attachment, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.createdDate, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.tableEntry, modes: CREATE_EDIT_CORRECT_DISPLAY, modifiers: TABLE_ENTRY_NOT_BOOKED_BY_RESELLER},
    {property: OfferOrderProperty.topJob, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.jobBlitz, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.jobBlitzRefresh, modes: CREATE_EDIT_CORRECT_DISPLAY, modifiers: ONLY_WHEN_REFRESH_AND_JOBBLITZ},
    {property: OfferOrderProperty.kalaydo, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.itJobs, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.socialMedia, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.internalProductNameId, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.duration, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.eMail, modes: CREATE_EDIT_CORRECT_DISPLAY, modifiers: EXCLUDE_WITH_CHIFFRE},
    {property: OfferOrderProperty.applicationUrl, modes: CREATE_EDIT_CORRECT_DISPLAY, modifiers: EXCLUDE_WITH_CHIFFRE},
    {property: OfferOrderProperty.homepage, modes: CREATE_EDIT_CORRECT_DISPLAY, modifiers: EXCLUDE_WITH_CHIFFRE},
    {property: OfferOrderProperty.alternativeCompany, modes: CREATE_EDIT_CORRECT_DISPLAY },
    {property: OfferOrderProperty.chiffreContactDetails, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.comment, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.publishEmail, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.customerApprovalEmail, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.publishingDate, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.deactivationDate, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.refreshOption, modes: CREATE_EDIT_CORRECT_DISPLAY, modifiers: REFRESH_NOT_BOOKED_BY_RESELLER},
    {property: OfferOrderProperty.individualRefreshDates, modes: CORRECT_DISPLAY, modifiers: INDIVIDUAL_REFRESH_MODIFIERS}
  ];
  html.offer = [
    {property: OfferProperty.title, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferProperty.externalLink, modes: DISPLAY},
    {property: OfferProperty.externalId, modes: DISPLAY},
    {property: OfferProperty.location, modes: CREATE_EDIT_CORRECT_DISPLAY, modifiers: FILLED_BY_STANZ}, // Entered by Stanz
    {property: OfferProperty.categoryGroupIds, modes: CREATE_EDIT_CORRECT_DISPLAY, modifiers: FILLED_BY_STANZ}, // Entered by Stanz
    {property: OfferProperty.startAndEndDates, modes: DISPLAY},
    {property: OfferProperty.statusAndWorkflowToDo, modes: DISPLAY}, // Not editable
    {property: OfferProperty.clicks, modes: DISPLAY} // Not editable
  ];
  export const HTML: Config = html;

  // PDF-Layout
  const pdf: Config = new Config();
  pdf.product = [
    {property: ProductProperty.companyName, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: ProductProperty.companyNumber, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: ProductProperty.companyLink, modes: DISPLAY},
    {property: ProductProperty.product, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: ProductProperty.number, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: ProductProperty.offerOrderStatus, modes: CREATE_EDIT_CORRECT_DISPLAY}
  ];
  pdf.offerOrder = [
    {property: OfferOrderProperty.chiffre, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.attachment, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.createdDate, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.tableEntry, modes: CREATE_EDIT_CORRECT_DISPLAY, modifiers: TABLE_ENTRY_NOT_BOOKED_BY_RESELLER},
    {property: OfferOrderProperty.topJob, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.jobBlitz, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.jobBlitzRefresh, modes: CREATE_EDIT_CORRECT_DISPLAY, modifiers: ONLY_WHEN_REFRESH_AND_JOBBLITZ},
    {property: OfferOrderProperty.kalaydo, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.itJobs, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.socialMedia, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.internalProductNameId, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.duration, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.eMail, modes: CREATE_EDIT_CORRECT_DISPLAY, modifiers: EXCLUDE_WITH_CHIFFRE},
    {property: OfferOrderProperty.applicationUrl, modes: CREATE_EDIT_CORRECT_DISPLAY, modifiers: EXCLUDE_WITH_CHIFFRE},
    {property: OfferOrderProperty.homepage, modes: CREATE_EDIT_CORRECT_DISPLAY, modifiers: EXCLUDE_WITH_CHIFFRE},
    {property: OfferOrderProperty.alternativeCompany, modes: CREATE_EDIT_CORRECT_DISPLAY },
    {property: OfferOrderProperty.chiffreContactDetails, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.comment, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.publishEmail, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.customerApprovalEmail, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.publishingDate, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.deactivationDate, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.refreshOption, modes: CREATE_EDIT_CORRECT_DISPLAY, modifiers: REFRESH_NOT_BOOKED_BY_RESELLER},
    {property: OfferOrderProperty.individualRefreshDates, modes: CORRECT_DISPLAY, modifiers: INDIVIDUAL_REFRESH_MODIFIERS}
  ];
  pdf.offer = [
    {property: OfferProperty.title, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferProperty.externalLink, modes: DISPLAY},
    {property: OfferProperty.externalId, modes: DISPLAY},
    {property: OfferProperty.location, modes: CREATE_EDIT_CORRECT_DISPLAY, modifiers: FILLED_BY_STANZ}, // Entered by Stanz
    {property: OfferProperty.categoryGroupIds, modes: CREATE_EDIT_CORRECT_DISPLAY, modifiers: FILLED_BY_STANZ}, // Entered by Stanz
    {property: OfferProperty.startAndEndDates, modes: DISPLAY},
    {property: OfferProperty.statusAndWorkflowToDo, modes: DISPLAY}, // Not editable
    {property: OfferProperty.clicks, modes: DISPLAY} // Not editable
  ];
  export const PDF: Config = pdf;

}

