/**
 * Sa.Reseller.Api
 *
 * OpenAPI spec version: v2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Product type
 */
export type SegaProductTypeEnum = 'RegionaleFlatrate' | 'RegionaleFlatrateLight' | 'Basis' | 'RegionaleAnzeigen' | 'PremiumAnzeigen';

export const SegaProductTypeEnum = {
    RegionaleFlatrate: 'RegionaleFlatrate' as SegaProductTypeEnum,
    RegionaleFlatrateLight: 'RegionaleFlatrateLight' as SegaProductTypeEnum,
    Basis: 'Basis' as SegaProductTypeEnum,
    RegionaleAnzeigen: 'RegionaleAnzeigen' as SegaProductTypeEnum,
    PremiumAnzeigen: 'PremiumAnzeigen' as SegaProductTypeEnum
}
