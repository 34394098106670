import { ProductEnum, ProductTypeEnum, ProductVariantEnum } from '../../reseller-api/client';
import { ErrorHelper } from '../error/error.helper';
import { Config, ProductConfigs } from '../../configs/product-configs';

export class ProductData {
  product: ProductEnum;
  type: ProductTypeEnum;
  variant: ProductVariantEnum;
  variantName?: string; // if null, use variant
}

/**
 * Helper for Products and the evaluation of product attributes
 * **/
export class ProductHelper {

  public static sortProductsAlphabetically(a: ProductEnum, b: ProductEnum): number {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  }

  public static isSegaProduct(product: ProductEnum): boolean {
    return product === ProductEnum.SegaRegioOnlineOnlyHtml
      || product === ProductEnum.SegaKontingentRegioOnlineOnlyHtml
      || product === ProductEnum.SegaPremiumOnlineOnlyHtml;
  }

  public static getProductData(product: ProductEnum): ProductData {
    switch (product) {
      case ProductEnum.OnlineOnlyHtml:
        return {
          product: product,
          type: ProductTypeEnum.OnlineOnly,
          variant: ProductVariantEnum.Html
        };
      case ProductEnum.OnlineOnlyPdfUndSonstigesFormat:
        return {
          product: product,
          type: ProductTypeEnum.OnlineOnly,
          variant: ProductVariantEnum.PdfUndSonstigesFormat
        };
      case ProductEnum.PremiumPokHtml:
        return {
          product: product,
          type: ProductTypeEnum.PremiumPok,
          variant: ProductVariantEnum.Html
        };
      case ProductEnum.PremiumPokPdfUndSonstigesFormat:
        return {
          product: product,
          type: ProductTypeEnum.PremiumPok,
          variant: ProductVariantEnum.PdfUndSonstigesFormat
        };
      case ProductEnum.PortalOnlineOnlyVonStellenanzeigenDeErstellt:
        return {
          product: product,
          type: ProductTypeEnum.PortalOnlineOnly,
          variant: ProductVariantEnum.PdfUndSonstigesFormat
        };
      case ProductEnum.PortalPokVonStellenanzeigenDeErstellt:
        return {
          product: product,
          type: ProductTypeEnum.PortalPok,
          variant: ProductVariantEnum.PdfUndSonstigesFormat
        };
      case ProductEnum.PortalPokPlusVonStellenanzeigenDeErstellt:
        return {
          product: product,
          type: ProductTypeEnum.PortalPokPlus,
          variant: ProductVariantEnum.PdfUndSonstigesFormat
        };
      case ProductEnum.RegioOnlineOnlyHtml:
        return {
          product: product,
          type: ProductTypeEnum.RegioOnlineOnly,
          variant: ProductVariantEnum.Html
        };
      case ProductEnum.RegioOnlineOnlyJpg:
        return {
          product: product,
          type: ProductTypeEnum.RegioOnlineOnly,
          variant: ProductVariantEnum.Jpg
        };
      case ProductEnum.RegioOnlineOnlyStandardlayout:
        return {
          product: product,
          type: ProductTypeEnum.RegioOnlineOnly,
          variant: ProductVariantEnum.Standardlayout
        };
      case ProductEnum.RegioOnlineOnlyStandardlayoutFuerFliesstextanzeigen:
        return {
          product: product,
          type: ProductTypeEnum.RegioOnlineOnly,
          variant: ProductVariantEnum.StandardlayoutFuerFliesstextanzeigen
        };
      case ProductEnum.RegioPokHtml:
        return {
          product: product,
          type: ProductTypeEnum.RegioPok,
          variant: ProductVariantEnum.Html
        };
      case ProductEnum.RegioPokStandardHtml:
        return {
          product: product,
          type: ProductTypeEnum.RegioPok,
          variant: ProductVariantEnum.StandardHtml
        };
      case ProductEnum.RegioPokJpg:
        return {
          product: product,
          type: ProductTypeEnum.RegioPok,
          variant: ProductVariantEnum.Jpg
        };
      case ProductEnum.RegioPokStandardlayout:
        return {
          product: product,
          type: ProductTypeEnum.RegioPok,
          variant: ProductVariantEnum.Standardlayout
        };
      case ProductEnum.RegioPokStandardlayoutFuerFliesstextanzeigen:
        return {
          product: product,
          type: ProductTypeEnum.RegioPok,
          variant: ProductVariantEnum.StandardlayoutFuerFliesstextanzeigen
        };
      case ProductEnum.RegioOnlineOnlyAzubi:
      case ProductEnum.RegioOnlineOnlyMinijobs:
        return {
          product: product,
          type: ProductTypeEnum.RegioOnlineOnly,
          variant: ProductVariantEnum.Standardlayout
        };
      case ProductEnum.PortalOnlineOnlyAzubi:
        return {
          product: product,
          type: ProductTypeEnum.PortalOnlineOnly,
          variant: ProductVariantEnum.PdfUndSonstigesFormat,
          variantName: 'Azubi'
        };
      case ProductEnum.OnlineOnlyAzubi:
        return {
          product: product,
          type: ProductTypeEnum.OnlineOnly,
          variant: ProductVariantEnum.PdfUndSonstigesFormat,
          variantName: 'Azubi'
        };
      case ProductEnum.PremiumPokAzubi:
        return {
          product: product,
          type: ProductTypeEnum.PremiumPok,
          variant: ProductVariantEnum.PdfUndSonstigesFormat,
          variantName: 'Azubi'
        };
      case ProductEnum.PublisherPaket1:
        return {
          product: product,
          type: ProductTypeEnum.PaketBundles,
          variant: ProductVariantEnum.Html,
          variantName: 'PublisherPaket1'
        };
      case ProductEnum.PublisherPaket2:
          return {
            product: product,
            type: ProductTypeEnum.PaketBundles,
            variant: ProductVariantEnum.Html,
            variantName: 'PublisherPaket2'
          };
      case ProductEnum.PublisherPaket3:
            return {
              product: product,
              type: ProductTypeEnum.PaketBundles,
              variant: ProductVariantEnum.Html,
              variantName: 'PublisherPaket3'
            };
      case ProductEnum.ITPaket:
              return {
                product: product,
                type: ProductTypeEnum.PaketBundles,
                variant: ProductVariantEnum.Html,
                variantName: 'ITPaket'
              };
      default: {
        ErrorHelper.throwError(`No product data for product ${product} defined in the ProductHelper.`);
      }
    }
  }

  public static getConfigForProduct(product: ProductEnum): Config {
    const config: Config = ProductConfigs[product];
    if (!config) {
      ErrorHelper.throwError(`No product configuration for product ${product} implemented!`);
    }
    return config;
  }

  public static productHasOfferMetadataStep(product: ProductEnum | undefined): boolean {
    if (!product || ProductHelper.isSegaProduct(product)) {
      return false;
    }

    const productData: ProductData = ProductHelper.getProductData(product);
    const productType: ProductTypeEnum = productData.type;
    const productVariant: ProductVariantEnum = productData.variant;

    // RegioPOKJpg is excluded as it has multiple offers
    return !(productType === ProductTypeEnum.RegioPok && productVariant === ProductVariantEnum.Jpg);
  }

  public static productHasLayoutStep(product: ProductEnum): boolean {
    const config: Config = ProductHelper.getConfigForProduct(product);
    return !!config.standardLayout;
  }
}

