<footer [@visibilityChanged]="showOrHideFooter" fxLayout="column" fxLayoutAlign="center center">
  <ul fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="2em">
    <li *ngFor="let serviceLink of serviceLinks">
      <a href='{{ serviceLink.path }}' target="_blank">{{ serviceLink.name }}</a>
    </li>
    <li *ngFor="let link of legalMattersLinks">
      <a href='{{ link.path }}' target="_blank">{{ link.name }}</a>
    </li>
    <li *ngFor="let adPageLink of adPageLinks">
      <a href='{{ adPageLink.path }}' target="_blank">{{ adPageLink.name }}</a>
    </li>
    <li> © {{ date.getFullYear() }} stellenanzeigen.de GmbH & Co.KG</li>
  </ul>
  <p style="padding: 0.4em" *ngIf="!config.environment.production">{{ configJson }}</p>
  <span class="version">{{ version }}</span>
</footer>
