/* eslint-disable @typescript-eslint/no-explicit-any */
export enum ConfigModifier {
  excludeForChiffre = <any>'excludeForChiffre',
  filledByStellenanzeigen = <any>'filledByStellenanzeigen',
  tableEntryNotBookedByReseller = <any>'tableEntryNotBookedByReseller',
  refreshNotBookedByReseller = <any>'refreshNotBookedByReseller',
  notVisibleUntilOnline = <any>'notVisibleUntilOnline',
  onlyWhenRefreshAndJobBlitz = <any>'onlyWhenRefreshAndJobBlitz'
}

// Modifier for properties which should be filled in by stellenanzeigen
export const FILLED_BY_STANZ: ConfigModifier[] = [ConfigModifier.filledByStellenanzeigen];

// Modifier for properties which should be hidden if chiffre is true
export const EXCLUDE_WITH_CHIFFRE: ConfigModifier[] = [ConfigModifier.excludeForChiffre];

// Modifier for properties which should be hidden if tableEntry is not booked by reseller
export const TABLE_ENTRY_NOT_BOOKED_BY_RESELLER: ConfigModifier[] = [ConfigModifier.tableEntryNotBookedByReseller];

// Modifier for properties which should be hidden if refresh feature is not booked by reseller
export const REFRESH_NOT_BOOKED_BY_RESELLER: ConfigModifier[] = [ConfigModifier.refreshNotBookedByReseller];

// Modifiers for properties individual refresh property which has to be booked by the reseller and should be hidden until the order is online (Geschalted)
export const INDIVIDUAL_REFRESH_MODIFIERS: ConfigModifier[] = [ConfigModifier.refreshNotBookedByReseller, ConfigModifier.notVisibleUntilOnline];

// Modifier for properties which should be hidden when jobblitz is false or no refresh is booked (as well any refresh option as any refresh dates)
export const ONLY_WHEN_REFRESH_AND_JOBBLITZ: ConfigModifier[] = [ConfigModifier.onlyWhenRefreshAndJobBlitz];
