import { UserRolePermissionPolicy } from '../user-role-permissions-policy.model';
import { ResellerPermissions } from '../reseller-permissions.model';
import { NavLinkEnum } from '../../../../main-layout/nav.model';

export class ResellerUserPolicy extends UserRolePermissionPolicy {
  private static readonly forbiddenComponents: string[] = [];

  private static readonly forbiddenNavigationLinks: NavLinkEnum[] = [];

  constructor(resellerPermissions: ResellerPermissions) {
    super(ResellerUserPolicy.forbiddenComponents,
      ResellerUserPolicy.forbiddenNavigationLinks,
      resellerPermissions);
  }
}
