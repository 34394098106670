/**
 * Sa.Reseller.Api
 *
 * OpenAPI spec version: v2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export type OfferOrderTopJobStatus = 'Planned' | 'Active' | 'Expired';

export const OfferOrderTopJobStatus = {
    Planned: 'Planned' as OfferOrderTopJobStatus,
    Active: 'Active' as OfferOrderTopJobStatus,
    Expired: 'Expired' as OfferOrderTopJobStatus
}
