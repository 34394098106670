import { DisplayTextDictionaries } from '../../shared/utils/classes/display-text-dictionaries';

export const OPEN_OFFER_ORDERS_COUNTER: DisplayTextDictionaries = {
  labels: {
    youHave: 'Sie haben',
    openOfferOrders: 'offene Aufträge',
    openOfferOrder: 'offenen Auftrag',
    noOpenOfferOrders: 'keine offene Aufträge',
    tooltip: 'Öffnet eine Übersicht über die offenen Aufträge.',
  }
};

export const BASIC_COMPANY_PROFILE_COUNTER: DisplayTextDictionaries = {
  labels: {
    youHave: 'Sie haben',
    openBasicCompanyProfile: 'offene Basic Firmenprofile',
    oneOpenBasicCompanyProfile: 'offenes Basic Firmenprofil',
    noOpenBasicCompanyProfile: 'keine offene Basic Firmenprofile',
    tooltip: 'Öffnet eine Übersicht über offene Basic Firmenprofile'
  }
};

