import { Injectable } from '@angular/core';
import { GuidHelper } from '../../utils/strings/guid.helper';

/**
 * This service is a typed wrapper for the access to the Session Storage
 */
@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {

  /**
   * Retrieves the typed value of the item stored under the given key or null if not found
   * @returns {T} The typed retrieved object
   */
  public load<T>(storageKey: string): T {
    const retrievedValuesString: string = sessionStorage.getItem(storageKey);
    return retrievedValuesString ? JSON.parse(retrievedValuesString) as T : null;
  }

  /**
   * Stores the value of the object into the session storage under the provided storage key. If no key is provided, a
   * GUID will be generated and used as key.
   * @returns {string} the key used to store the value in the session storage
   */
  public store(values: Object, storageKey?: string): string {
    storageKey = storageKey || GuidHelper.getGuid();
    sessionStorage.setItem(storageKey, JSON.stringify(values));
    return storageKey;
  }

  /**
   * Removes the object stored under the given key and returns its las value before being removed.
   * @param storageKey
   * @returns {T}
   */
  public remove<T>(storageKey: string): T {
    const objectToRemove: T = this.load<T>(storageKey);
    sessionStorage.removeItem(storageKey);
    return objectToRemove;
  }

  /**
   * Wrapper function for clearing the session storage
   */
  public clear(): void {
    sessionStorage.clear();
  }
}
