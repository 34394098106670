/**
 * Sa.Reseller.Api
 *
 * OpenAPI spec version: v2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export type OfferOrderSearchFilterSortOrder = 'ByCompanyNumberASC' | 'ByCompanyNumberDESC' | 'ByCreatedAtASC' | 'ByCreatedAtDESC' | 'ByOfferOrderNumberASC' | 'ByOfferOrderNumberDESC' | 'ByOfferOrderStatusASC' | 'ByOfferOrderStatusDESC' | 'ByProductTypeASC' | 'ByProductTypeDESC' | 'ByPublishDateASC' | 'ByPublishDateDESC' | 'ByTopJobASC' | 'ByTopJobDESC' | 'ByJobBlitzASC' | 'ByJobBlitzDESC' | 'ByKalaydoASC' | 'ByKalaydoDESC' | 'ByItJobsASC' | 'ByItJobsDESC';

export const OfferOrderSearchFilterSortOrder = {
    ByCompanyNumberASC: 'ByCompanyNumberASC' as OfferOrderSearchFilterSortOrder,
    ByCompanyNumberDESC: 'ByCompanyNumberDESC' as OfferOrderSearchFilterSortOrder,
    ByCreatedAtASC: 'ByCreatedAtASC' as OfferOrderSearchFilterSortOrder,
    ByCreatedAtDESC: 'ByCreatedAtDESC' as OfferOrderSearchFilterSortOrder,
    ByOfferOrderNumberASC: 'ByOfferOrderNumberASC' as OfferOrderSearchFilterSortOrder,
    ByOfferOrderNumberDESC: 'ByOfferOrderNumberDESC' as OfferOrderSearchFilterSortOrder,
    ByOfferOrderStatusASC: 'ByOfferOrderStatusASC' as OfferOrderSearchFilterSortOrder,
    ByOfferOrderStatusDESC: 'ByOfferOrderStatusDESC' as OfferOrderSearchFilterSortOrder,
    ByProductTypeASC: 'ByProductTypeASC' as OfferOrderSearchFilterSortOrder,
    ByProductTypeDESC: 'ByProductTypeDESC' as OfferOrderSearchFilterSortOrder,
    ByPublishDateASC: 'ByPublishDateASC' as OfferOrderSearchFilterSortOrder,
    ByPublishDateDESC: 'ByPublishDateDESC' as OfferOrderSearchFilterSortOrder,
    ByTopJobASC: 'ByTopJobASC' as OfferOrderSearchFilterSortOrder,
    ByTopJobDESC: 'ByTopJobDESC' as OfferOrderSearchFilterSortOrder,
    ByJobBlitzASC: 'ByJobBlitzASC' as OfferOrderSearchFilterSortOrder,
    ByJobBlitzDESC: 'ByJobBlitzDESC' as OfferOrderSearchFilterSortOrder,
    ByKalaydoASC: 'ByKalaydoASC' as OfferOrderSearchFilterSortOrder,
    ByKalaydoDESC: 'ByKalaydoDESC' as OfferOrderSearchFilterSortOrder,
    ByItJobsASC: 'ByItJobsASC' as OfferOrderSearchFilterSortOrder,
    ByItJobsDESC: 'ByItJobsDESC' as OfferOrderSearchFilterSortOrder
}
