/**
 * Sa.Reseller.Api
 *
 * OpenAPI spec version: v2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export type OfferActionEnum = 'Activate' | 'Deactivate' | 'Extend';

export const OfferActionEnum = {
    Activate: 'Activate' as OfferActionEnum,
    Deactivate: 'Deactivate' as OfferActionEnum,
    Extend: 'Extend' as OfferActionEnum
}
