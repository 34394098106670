/**
 * Sa.Reseller.Api
 *
 * OpenAPI spec version: v2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * The status of the CompanyProfileV3.
 */
export type CompanyProfileV3StatusEnum = 'Created' | 'WaitingForActivation' | 'Active' | 'WaitingForDeactivation' | 'Deactivated' | 'WaitingForReactivation' | 'Expired' | 'WaitingForPublication';

export const CompanyProfileV3StatusEnum = {
    Created: 'Created' as CompanyProfileV3StatusEnum,
    WaitingForActivation: 'WaitingForActivation' as CompanyProfileV3StatusEnum,
    Active: 'Active' as CompanyProfileV3StatusEnum,
    WaitingForDeactivation: 'WaitingForDeactivation' as CompanyProfileV3StatusEnum,
    Deactivated: 'Deactivated' as CompanyProfileV3StatusEnum,
    WaitingForReactivation: 'WaitingForReactivation' as CompanyProfileV3StatusEnum,
    Expired: 'Expired' as CompanyProfileV3StatusEnum,
    WaitingForPublication: 'WaitingForPublication' as CompanyProfileV3StatusEnum
}
