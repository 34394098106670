/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { CookieOptions } from './cookie-options';

@Injectable({
  providedIn: 'root',
})
export class CookieService {

  constructor() {
    // do nothing.
  }

  /**
   * Checks the existence of a single cookie
   */
  public check(name: string): boolean {
    name = encodeURIComponent(name);
    const regexp = new RegExp(`(?:^${name}|;\\s*${name})=(.*?)(?:;|$)`, 'g');
    return regexp.test( document.cookie );
  }

  /**
   * Returns a single cookie
   */
  public get(name: string): any {
    if (this.check(name)) {
      name = encodeURIComponent(name);
      const regexp = new RegExp(`(?:^${name}|;\\s*${name})=(.*?)(?:;|$)`, 'g');
      const result: RegExpExecArray = regexp.exec(document.cookie);
      return JSON.parse(decodeURIComponent(result[1]));
    } else {
      return {};
    }
  }

  /**
   * Set the Cookie
   */
  public set(name: string, value: Object, options?: CookieOptions): void {
    if (name.indexOf('-') > -1) {
      console.error(`The usage of cookie names with '-' is not allowed. Please rename your cookie ${name}.`);
      return;
    }

    let cookieStr = `${encodeURIComponent( name )}=${encodeURIComponent( JSON.stringify(value) )};`;

    if (options) {
      if ( options.expires ) {
        if ( typeof options.expires === 'number' ) {
          const dtExpires: Date = new Date( new Date().getTime() + options.expires * 1000 * 60 * 60 * 24 );
          cookieStr += `expires=${dtExpires.toUTCString()};`;
        } else {
          cookieStr += `expires=${options.expires.toUTCString()};`;
        }
      }
      if ( options.path ) {
        cookieStr += `path=${options.path};`;
      }
      if ( options.domain ) {
        cookieStr += `domain=${options.domain};`;
      }
      if ( options.secure ) {
        cookieStr += 'secure;';
      }
    }
    document.cookie = cookieStr;
  }

  /**
   * Expire & delete specified Cookie
   */
  public delete(name: string, options?: CookieOptions): void {
    if (this.get(name)) {
      const optionsToUse: CookieOptions = Object.assign({}, options);
      optionsToUse.expires = new Date('01 Jan 1970 00:00:00 GMT');
      this.set(name, '', options);
    }
  }
}
