import { InjectionToken, NgModule } from '@angular/core';
import { ApiModule, Configuration } from './client';
import { environment } from '../../../environments/environment';

export const SARE_API_CONFIG: InjectionToken<Configuration> = new InjectionToken<Configuration>('SARE_API_CONFIG');
export const apiConfig: Configuration = new Configuration({ apiKeys: environment.apiKey, basePath: environment.apiEndpoint });
export function configFactory(): Configuration {
  return apiConfig;
}

@NgModule({
  providers: [
    { provide: SARE_API_CONFIG, useValue: apiConfig },
  ],
  imports: [
    ApiModule.forRoot(configFactory),
  ]
})

// never import this module in any other module than the AppModule
export class SareApiModule {
}

