/**
 * Sa.Reseller.Api
 *
 * OpenAPI spec version: v2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export type CompanyProfileActionEnum = 'RequestActivation' | 'Activate' | 'RequestDeactivation' | 'Deactivate' | 'Extend' | 'Expire' | 'Update';

export const CompanyProfileActionEnum = {
    RequestActivation: 'RequestActivation' as CompanyProfileActionEnum,
    Activate: 'Activate' as CompanyProfileActionEnum,
    RequestDeactivation: 'RequestDeactivation' as CompanyProfileActionEnum,
    Deactivate: 'Deactivate' as CompanyProfileActionEnum,
    Extend: 'Extend' as CompanyProfileActionEnum,
    Expire: 'Expire' as CompanyProfileActionEnum,
    Update: 'Update' as CompanyProfileActionEnum
}
