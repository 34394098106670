<header class="e2eHeader" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="1em">
  <div>
    <a [routerLink]="''"><img src="../images/stellenanzeigen-logo.svg" width="184"></a>
  </div>

  <app-nav class="e2eNavbar" fxFlex="55%"></app-nav>

  <app-open-offer-orders-counter fxFlex="8%"></app-open-offer-orders-counter>
  <app-ready-for-basic-company-profile-counter fxFlex="12%"></app-ready-for-basic-company-profile-counter>

  <div fxLayout="row" fxLayoutAlign="end center" fxFlex="15%">
    <h4 class="welcome-text">Willkommen, {{ getUserName() }}</h4>
    <button mat-icon-button matTooltip="logout" class="e2eButtonLogout" (click)="logout()" type="button">
      <mat-icon color="accent">power_settings_new</mat-icon>
    </button>
  </div>
</header>
