/**
 * Sa.Reseller.Api
 *
 * OpenAPI spec version: v2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CompanyProfileV3 } from '../model/companyProfileV3';
import { CompanyProfileV3Actions } from '../model/companyProfileV3Actions';
import { CompanyProfileV3Create } from '../model/companyProfileV3Create';
import { CompanyProfileV3Extend } from '../model/companyProfileV3Extend';
import { CompanyProfileV3RenderData } from '../model/companyProfileV3RenderData';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { CompanyProfileV3ServiceInterface }                            from './companyProfileV3.serviceInterface';


@Injectable({
  providedIn: 'root'
})
export class CompanyProfileV3Service implements CompanyProfileV3ServiceInterface {

    protected basePath = '';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (let consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Creates a company profile subscription.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param companyId
     * @param companyProfileV3Create
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCompanyProfile(companyId: number, companyProfileV3Create: CompanyProfileV3Create, observe?: 'body', reportProgress?: boolean): Observable<CompanyProfileV3>;
    public createCompanyProfile(companyId: number, companyProfileV3Create: CompanyProfileV3Create, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CompanyProfileV3>>;
    public createCompanyProfile(companyId: number, companyProfileV3Create: CompanyProfileV3Create, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CompanyProfileV3>>;
    public createCompanyProfile(companyId: number, companyProfileV3Create: CompanyProfileV3Create, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling createCompanyProfile.');
        }
        if (companyProfileV3Create === null || companyProfileV3Create === undefined) {
            throw new Error('Required parameter companyProfileV3Create was null or undefined when calling createCompanyProfile.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<CompanyProfileV3>(`${this.basePath}/api/v2/companies/${encodeURIComponent(String(companyId))}/companyProfiles/3`,
            companyProfileV3Create,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Removes the current company profile draft.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param companyId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCompanyProfileDraft(companyId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteCompanyProfileDraft(companyId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteCompanyProfileDraft(companyId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteCompanyProfileDraft(companyId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling deleteCompanyProfileDraft.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/v2/companies/${encodeURIComponent(String(companyId))}/companyProfiles/3/draft`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Extends a company profile subscription.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param companyId
     * @param companyProfileV3Extend
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public extendCompanyProfile(companyId: number, companyProfileV3Extend: CompanyProfileV3Extend, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public extendCompanyProfile(companyId: number, companyProfileV3Extend: CompanyProfileV3Extend, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public extendCompanyProfile(companyId: number, companyProfileV3Extend: CompanyProfileV3Extend, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public extendCompanyProfile(companyId: number, companyProfileV3Extend: CompanyProfileV3Extend, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling extendCompanyProfile.');
        }
        if (companyProfileV3Extend === null || companyProfileV3Extend === undefined) {
            throw new Error('Required parameter companyProfileV3Extend was null or undefined when calling extendCompanyProfile.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/api/v2/companies/${encodeURIComponent(String(companyId))}/companyProfiles/3/extend`,
            companyProfileV3Extend,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns the company profile subscription.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager, ResellerSalesManager
     * @param companyId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanyProfile(companyId: number, observe?: 'body', reportProgress?: boolean): Observable<CompanyProfileV3>;
    public getCompanyProfile(companyId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CompanyProfileV3>>;
    public getCompanyProfile(companyId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CompanyProfileV3>>;
    public getCompanyProfile(companyId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getCompanyProfile.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<CompanyProfileV3>(`${this.basePath}/api/v2/companies/${encodeURIComponent(String(companyId))}/companyProfiles/3`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns the available actions for a company profile.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager, ResellerSalesManager
     * @param companyId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanyProfileActions(companyId: number, observe?: 'body', reportProgress?: boolean): Observable<CompanyProfileV3Actions>;
    public getCompanyProfileActions(companyId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CompanyProfileV3Actions>>;
    public getCompanyProfileActions(companyId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CompanyProfileV3Actions>>;
    public getCompanyProfileActions(companyId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getCompanyProfileActions.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<CompanyProfileV3Actions>(`${this.basePath}/api/v2/companies/${encodeURIComponent(String(companyId))}/companyProfiles/3/actions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns the current company profile render data.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager, ResellerSalesManager
     * @param companyId The company id
     * @param ignoreDraft If this is set to true, the live data will be returned always.  If set to false, the draft data is returned when it exists, otherwise the live data is returned aswell.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanyProfileRenderData(companyId: number, ignoreDraft?: boolean, observe?: 'body', reportProgress?: boolean): Observable<CompanyProfileV3RenderData>;
    public getCompanyProfileRenderData(companyId: number, ignoreDraft?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CompanyProfileV3RenderData>>;
    public getCompanyProfileRenderData(companyId: number, ignoreDraft?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CompanyProfileV3RenderData>>;
    public getCompanyProfileRenderData(companyId: number, ignoreDraft?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getCompanyProfileRenderData.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (ignoreDraft !== undefined) {
            queryParameters = queryParameters.set('ignoreDraft', <any>ignoreDraft);
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<CompanyProfileV3RenderData>(`${this.basePath}/api/v2/companies/${encodeURIComponent(String(companyId))}/companyProfiles/3/renderData`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Turns the draft data into live data and uploads the given zip. After this, the draft data is deleted.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param companyId
     * @param zipToken
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public publishCompanyProfile(companyId: number, zipToken: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public publishCompanyProfile(companyId: number, zipToken: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public publishCompanyProfile(companyId: number, zipToken: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public publishCompanyProfile(companyId: number, zipToken: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling publishCompanyProfile.');
        }
        if (zipToken === null || zipToken === undefined) {
            throw new Error('Required parameter zipToken was null or undefined when calling publishCompanyProfile.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (zipToken !== undefined) {
            queryParameters = queryParameters.set('zipToken', <any>zipToken);
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.put<any>(`${this.basePath}/api/v2/companies/${encodeURIComponent(String(companyId))}/companyProfiles/3/publish`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Activate request for the specified company profile.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param companyId The Id of the company the profile belongs to
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public requestActivationForCompanyProfile(companyId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public requestActivationForCompanyProfile(companyId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public requestActivationForCompanyProfile(companyId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public requestActivationForCompanyProfile(companyId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling requestActivationForCompanyProfile.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.put<any>(`${this.basePath}/api/v2/companies/${encodeURIComponent(String(companyId))}/companyProfiles/3/requestActivation`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deactivate request for the specified company profile.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param companyId The Id of the company the profile belongs to
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public requestDeactivationForCompanyProfile(companyId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public requestDeactivationForCompanyProfile(companyId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public requestDeactivationForCompanyProfile(companyId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public requestDeactivationForCompanyProfile(companyId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling requestDeactivationForCompanyProfile.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.put<any>(`${this.basePath}/api/v2/companies/${encodeURIComponent(String(companyId))}/companyProfiles/3/requestDeactivation`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requests Publication at a specific date in the Future for company profile
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param companyId
     * @param startDate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public requestFuturePublication(companyId: number, startDate: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public requestFuturePublication(companyId: number, startDate: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public requestFuturePublication(companyId: number, startDate: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public requestFuturePublication(companyId: number, startDate: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling requestFuturePublication.');
        }
        if (startDate === null || startDate === undefined) {
            throw new Error('Required parameter startDate was null or undefined when calling requestFuturePublication.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (startDate !== undefined) {
            queryParameters = queryParameters.set('startDate', <any>startDate);
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.put<any>(`${this.basePath}/api/v2/companies/${encodeURIComponent(String(companyId))}/companyProfiles/3/requestPublication`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Saves a new draft for the company profile.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param companyId
     * @param renderData
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public saveCompanyProfileDraft(companyId: number, renderData: CompanyProfileV3RenderData, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public saveCompanyProfileDraft(companyId: number, renderData: CompanyProfileV3RenderData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public saveCompanyProfileDraft(companyId: number, renderData: CompanyProfileV3RenderData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public saveCompanyProfileDraft(companyId: number, renderData: CompanyProfileV3RenderData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling saveCompanyProfileDraft.');
        }
        if (renderData === null || renderData === undefined) {
            throw new Error('Required parameter renderData was null or undefined when calling saveCompanyProfileDraft.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/api/v2/companies/${encodeURIComponent(String(companyId))}/companyProfiles/3/draft`,
            renderData,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sends an email with a preview link for a company profile with a given {companyId}  to the company (or to an optional {emailAddress}) including the {zipToken} for display.
     * Allowed roles: SystemAdmin, Reseller, ResellerOfferManager
     * @param companyId
     * @param zipToken
     * @param emailAddress
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sendPreviewLink(companyId: number, zipToken: string, emailAddress?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public sendPreviewLink(companyId: number, zipToken: string, emailAddress?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public sendPreviewLink(companyId: number, zipToken: string, emailAddress?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public sendPreviewLink(companyId: number, zipToken: string, emailAddress?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling sendPreviewLink.');
        }
        if (zipToken === null || zipToken === undefined) {
            throw new Error('Required parameter zipToken was null or undefined when calling sendPreviewLink.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (zipToken !== undefined) {
            queryParameters = queryParameters.set('zipToken', <any>zipToken);
        }
        if (emailAddress !== undefined) {
            queryParameters = queryParameters.set('emailAddress', <any>emailAddress);
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys["X-Api-Key"]) {
            headers = headers.set('X-Api-Key', this.configuration.apiKeys["X-Api-Key"]);
        }

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.put<any>(`${this.basePath}/api/v2/companies/${encodeURIComponent(String(companyId))}/companyProfiles/3/sendPreviewLink`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
