import {
  Config,
  CREATE_EDIT_CORRECT,
  CREATE_EDIT_CORRECT_DISPLAY,
  CORRECT_DISPLAY,
  DISPLAY,
  LayoutProperty,
  OfferOrderProperty,
  OfferProperty,
  ProductProperty
} from './product-config.model';
import {
  EXCLUDE_WITH_CHIFFRE,
  REFRESH_NOT_BOOKED_BY_RESELLER,
  TABLE_ENTRY_NOT_BOOKED_BY_RESELLER,
  INDIVIDUAL_REFRESH_MODIFIERS, ONLY_WHEN_REFRESH_AND_JOBBLITZ
} from './modifiers/modifiers.model';

// eslint-disable-next-line
export namespace RegioOnlineOnlyConfig {
  // HTML
  const html: Config = new Config();
  html.product = [
    {property: ProductProperty.companyName, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: ProductProperty.companyNumber, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: ProductProperty.companyLink, modes: DISPLAY},
    {property: ProductProperty.product, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: ProductProperty.number, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: ProductProperty.offerOrderStatus, modes: CREATE_EDIT_CORRECT_DISPLAY}
  ];

  html.offerOrder = [
    {property: OfferOrderProperty.chiffre, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.attachment, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.createdDate, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.tableEntry, modes: CREATE_EDIT_CORRECT_DISPLAY, modifiers: TABLE_ENTRY_NOT_BOOKED_BY_RESELLER},
    {property: OfferOrderProperty.topJob, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.jobBlitz, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.jobBlitzRefresh, modes: CREATE_EDIT_CORRECT_DISPLAY, modifiers: ONLY_WHEN_REFRESH_AND_JOBBLITZ},
    {property: OfferOrderProperty.kalaydo, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.itJobs, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.socialMedia, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.internalProductNameId, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.duration, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.eMail, modes: CREATE_EDIT_CORRECT_DISPLAY, modifiers: EXCLUDE_WITH_CHIFFRE},
    {property: OfferOrderProperty.applicationUrl, modes: CREATE_EDIT_CORRECT_DISPLAY, modifiers: EXCLUDE_WITH_CHIFFRE},
    {property: OfferOrderProperty.homepage, modes: CREATE_EDIT_CORRECT_DISPLAY, modifiers: EXCLUDE_WITH_CHIFFRE},
    {property: OfferOrderProperty.alternativeCompany, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.chiffreContactDetails, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.comment, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.searchTerm, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.publishEmail, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.publishingDate, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.deactivationDate, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.refreshOption, modes: CREATE_EDIT_CORRECT_DISPLAY, modifiers: REFRESH_NOT_BOOKED_BY_RESELLER},
    {property: OfferOrderProperty.individualRefreshDates, modes: CORRECT_DISPLAY, modifiers: INDIVIDUAL_REFRESH_MODIFIERS}
  ];

  html.offer = [
    {property: OfferProperty.title, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferProperty.externalLink, modes: DISPLAY},
    {property: OfferProperty.externalId, modes: DISPLAY},
    {property: OfferProperty.location, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferProperty.requirements, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferProperty.categoryGroupIds, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferProperty.titleAddition, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferProperty.searchKeywords, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferProperty.startAndEndDates, modes: DISPLAY},
    {property: OfferProperty.statusAndWorkflowToDo, modes: DISPLAY},
    {property: OfferProperty.clicks, modes: DISPLAY},
  ];
  export const HTML: Config = html;

  // JPG
  const jpg: Config = new Config();
  jpg.product = [
    {property: ProductProperty.companyName, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: ProductProperty.companyNumber, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: ProductProperty.companyLink, modes: DISPLAY},
    {property: ProductProperty.product, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: ProductProperty.number, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: ProductProperty.offerOrderStatus, modes: CREATE_EDIT_CORRECT_DISPLAY}
  ];

  jpg.offerOrder = [
    {property: OfferOrderProperty.chiffre, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.attachment, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.createdDate, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.tableEntry, modes: CREATE_EDIT_CORRECT_DISPLAY, modifiers: TABLE_ENTRY_NOT_BOOKED_BY_RESELLER},
    {property: OfferOrderProperty.topJob, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.jobBlitz, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.jobBlitzRefresh, modes: CREATE_EDIT_CORRECT_DISPLAY, modifiers: ONLY_WHEN_REFRESH_AND_JOBBLITZ},
    {property: OfferOrderProperty.kalaydo, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.itJobs, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.socialMedia, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.internalProductNameId, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.duration, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.eMail, modes: CREATE_EDIT_CORRECT_DISPLAY, modifiers: EXCLUDE_WITH_CHIFFRE},
    {property: OfferOrderProperty.applicationUrl, modes: CREATE_EDIT_CORRECT_DISPLAY, modifiers: EXCLUDE_WITH_CHIFFRE},
    {property: OfferOrderProperty.homepage, modes: CREATE_EDIT_CORRECT_DISPLAY, modifiers: EXCLUDE_WITH_CHIFFRE},
    {property: OfferOrderProperty.alternativeCompany, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.chiffreContactDetails, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.comment, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.searchTerm, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.publishEmail, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.publishingDate, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.deactivationDate, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.refreshOption, modes: CREATE_EDIT_CORRECT_DISPLAY, modifiers: REFRESH_NOT_BOOKED_BY_RESELLER},
    {property: OfferOrderProperty.individualRefreshDates, modes: CORRECT_DISPLAY, modifiers: INDIVIDUAL_REFRESH_MODIFIERS}
  ];

  jpg.offer = [
    {property: OfferProperty.title, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferProperty.externalLink, modes: DISPLAY},
    {property: OfferProperty.externalId, modes: DISPLAY},
    {property: OfferProperty.location, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferProperty.requirements, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferProperty.categoryGroupIds, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferProperty.titleAddition, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferProperty.searchKeywords, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferProperty.startAndEndDates, modes: DISPLAY},
    {property: OfferProperty.statusAndWorkflowToDo, modes: DISPLAY},
    {property: OfferProperty.clicks, modes: DISPLAY},
  ];
  export const JPG: Config = jpg;

  // Standard-Layout
  const standard_layout: Config = new Config();
  standard_layout.product = [
    {property: ProductProperty.companyName, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: ProductProperty.companyNumber, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: ProductProperty.companyLink, modes: DISPLAY},
    {property: ProductProperty.product, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: ProductProperty.number, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: ProductProperty.offerOrderStatus, modes: CREATE_EDIT_CORRECT_DISPLAY}
  ];
  standard_layout.offerOrder = [
    {property: OfferOrderProperty.chiffre, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.createdDate, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.tableEntry, modes: CREATE_EDIT_CORRECT_DISPLAY, modifiers: TABLE_ENTRY_NOT_BOOKED_BY_RESELLER},
    {property: OfferOrderProperty.topJob, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.jobBlitz, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.jobBlitzRefresh, modes: CREATE_EDIT_CORRECT_DISPLAY, modifiers: ONLY_WHEN_REFRESH_AND_JOBBLITZ},
    {property: OfferOrderProperty.duration, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.socialMedia, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.internalProductNameId, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.eMail, modes: CREATE_EDIT_CORRECT_DISPLAY, modifiers: EXCLUDE_WITH_CHIFFRE},
    {property: OfferOrderProperty.applicationUrl, modes: CREATE_EDIT_CORRECT_DISPLAY, modifiers: EXCLUDE_WITH_CHIFFRE},
    {property: OfferOrderProperty.homepage, modes: CREATE_EDIT_CORRECT_DISPLAY, modifiers: EXCLUDE_WITH_CHIFFRE},
    {property: OfferOrderProperty.alternativeCompany, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.chiffreContactDetails, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.comment, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.publishingDate, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.deactivationDate, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.refreshOption, modes: CREATE_EDIT_CORRECT_DISPLAY, modifiers: REFRESH_NOT_BOOKED_BY_RESELLER},
    {property: OfferOrderProperty.kalaydo, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.itJobs, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.individualRefreshDates, modes: CORRECT_DISPLAY, modifiers: INDIVIDUAL_REFRESH_MODIFIERS}
  ];
  standard_layout.offer = [
    {property: OfferProperty.title, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferProperty.externalLink, modes: DISPLAY},
    {property: OfferProperty.externalId, modes: DISPLAY},
    {property: OfferProperty.location, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferProperty.requirements, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferProperty.categoryGroupIds, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferProperty.titleAddition, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferProperty.searchKeywords, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferProperty.startAndEndDates, modes: DISPLAY},
    {property: OfferProperty.statusAndWorkflowToDo, modes: DISPLAY},
    {property: OfferProperty.clicks, modes: DISPLAY},
  ];
  standard_layout.standardLayout = [
    {property: LayoutProperty.data, modes: CREATE_EDIT_CORRECT},
    {property: LayoutProperty.preview, modes: DISPLAY}
  ];
  export const STANDARD_LAYOUT: Config = standard_layout;

  // Fliesstext-Layout
  const fliesstext: Config = new Config();
  fliesstext.product = [
    {property: ProductProperty.companyName, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: ProductProperty.companyNumber, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: ProductProperty.companyLink, modes: DISPLAY},
    {property: ProductProperty.product, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: ProductProperty.number, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: ProductProperty.offerOrderStatus, modes: CREATE_EDIT_CORRECT_DISPLAY}
  ];
  fliesstext.offerOrder = [
    {property: OfferOrderProperty.chiffre, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.createdDate, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.tableEntry, modes: CREATE_EDIT_CORRECT_DISPLAY, modifiers: TABLE_ENTRY_NOT_BOOKED_BY_RESELLER},
    {property: OfferOrderProperty.topJob, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.jobBlitz, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.jobBlitzRefresh, modes: CREATE_EDIT_CORRECT_DISPLAY, modifiers: ONLY_WHEN_REFRESH_AND_JOBBLITZ},
    {property: OfferOrderProperty.kalaydo, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.itJobs, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.socialMedia, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.internalProductNameId, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.duration, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.eMail, modes: CREATE_EDIT_CORRECT_DISPLAY, modifiers: EXCLUDE_WITH_CHIFFRE},
    {property: OfferOrderProperty.applicationUrl, modes: CREATE_EDIT_CORRECT_DISPLAY, modifiers: EXCLUDE_WITH_CHIFFRE},
    {property: OfferOrderProperty.homepage, modes: CREATE_EDIT_CORRECT_DISPLAY, modifiers: EXCLUDE_WITH_CHIFFRE},
    {property: OfferOrderProperty.alternativeCompany, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.chiffreContactDetails, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.comment, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.searchTerm, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.publishingDate, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.deactivationDate, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferOrderProperty.refreshOption, modes: CREATE_EDIT_CORRECT_DISPLAY, modifiers: REFRESH_NOT_BOOKED_BY_RESELLER},
    {property: OfferOrderProperty.individualRefreshDates, modes: CORRECT_DISPLAY, modifiers: INDIVIDUAL_REFRESH_MODIFIERS}
  ];
  fliesstext.offer = [
    {property: OfferProperty.title, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferProperty.externalLink, modes: DISPLAY},
    {property: OfferProperty.externalId, modes: DISPLAY},
    {property: OfferProperty.location, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferProperty.requirements, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferProperty.categoryGroupIds, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferProperty.titleAddition, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferProperty.searchKeywords, modes: CREATE_EDIT_CORRECT_DISPLAY},
    {property: OfferProperty.startAndEndDates, modes: DISPLAY},
    {property: OfferProperty.statusAndWorkflowToDo, modes: DISPLAY},
    {property: OfferProperty.clicks, modes: DISPLAY},
  ];
  export const FLIESSTEXT: Config = fliesstext;
}
