import { Reseller } from '../../reseller-api/client';

export class ResellerPermissions {
  public readonly segaEnabled: boolean;
  public readonly topJobEnabled: boolean;
  public readonly topCompanyEnabled: boolean;
  public readonly companyProfilesEnabled: boolean;
  public readonly companyProfilesV3Enabled: boolean;
  public readonly companyVideosEnabled: boolean;
  public readonly statisticsEnabled: boolean;
  public readonly internalProductNameEnabled: boolean;

  constructor(reseller: Reseller) {
    this.segaEnabled = reseller.segaEnabled;
    this.topJobEnabled = reseller.topJobEnabled;
    this.topCompanyEnabled = reseller.topCompanyEnabled;
    this.companyProfilesEnabled = reseller.companyProfilesEnabled;
    this.companyProfilesV3Enabled = reseller.companyProfilesV3Enabled;
    this.companyVideosEnabled = reseller.companyVideosEnabled;
    this.statisticsEnabled = reseller.statisticsEnabled;
    this.internalProductNameEnabled = reseller.internalProductNameEnabled;
  }
}
