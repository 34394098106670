import { UserRoleType } from '../../reseller-api/client';
import { ErrorHelper } from '../../utils/error/error.helper';
import { OfferManagerPolicy } from './policies/offer-manager-policy';
import { ResellerUserPolicy } from './policies/reseller-user-policy';
import { SalesManagerPolicy } from './policies/sales-manager-policy';
import { SystemAdminPolicy } from './policies/system-admin-policy';
import { UndefinedRolePolicy } from './policies/undefined-role-policy';
import { ResellerPermissions } from './reseller-permissions.model';
import { UserRolePermissionPolicy } from './user-role-permissions-policy.model';

export class PermissionsPolicyFactory {
  public static getPermissionsPolicy(role: UserRoleType, resellerPermissions: ResellerPermissions): UserRolePermissionPolicy {
    switch (role) {
      case UserRoleType.ResellerOfferManager:
        return new OfferManagerPolicy(resellerPermissions);
      case UserRoleType.ResellerSalesManager:
        return new SalesManagerPolicy(resellerPermissions);
      case UserRoleType.SystemAdmin:
        return new SystemAdminPolicy(resellerPermissions);
      case UserRoleType.Reseller:
        return new ResellerUserPolicy(resellerPermissions);
      case UserRoleType.Undefined:
        return new UndefinedRolePolicy(resellerPermissions);
      default:
        ErrorHelper.throwError(`No User role authentication implemented for ${role}`);
        break;
    }
  }
}
