import { catchError, map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CompanyTableFilter } from '../../../company-overview/filter/company-table-filter';
import { StringHelper } from '../../../../shared/utils/strings/string.helper';
import { ResellerCacheService } from '../../../../shared/services/reseller/reseller.service';
import {
  Company,
  CompanySearchByNumberAndNameResult,
  CompanySearchFilter,
  CompanyService,
  NODataPagedResultCompanyListItem,
  NODataPagedResultCompanySearchByNumberAndNameResult,
  Reseller
} from '../../../../shared/reseller-api/client';

@Injectable({
  providedIn: 'root',
})
export class CompanySearchService {

  private static querySortComparator(query: string): (a: CompanySearchByNumberAndNameResult, b: CompanySearchByNumberAndNameResult) => number {
    return (a: CompanySearchByNumberAndNameResult, b: CompanySearchByNumberAndNameResult) =>
      StringHelper.querySortComparator(query)(a.name, b.name);
  }

  public constructor(private companyApi: CompanyService,
                     private resellerService: ResellerCacheService) {
  }

  public searchCompany(companySearchFilter: CompanySearchFilter): Observable<NODataPagedResultCompanyListItem> {
    return this.companyApi.companySearchCompaniesAsync(
      companySearchFilter.companyNameSearchTerm,
      companySearchFilter.companyNumberSearchTerm,
      companySearchFilter.isTopCompany,
      companySearchFilter.originApiClients,
      companySearchFilter.isSebuCompany,
      companySearchFilter.segaCompany,
      undefined,
      undefined,
      companySearchFilter.companyProfile,
      companySearchFilter.basicCompanyProfile,
      companySearchFilter.skip,
      companySearchFilter.take,
      companySearchFilter.resellerId,
      companySearchFilter.orderBy ? companySearchFilter.orderBy : undefined);
  }

  public searchCompanyWithCsvResponse(companySearchFilter: CompanySearchFilter): Observable<Blob> {
    return this.companyApi.exportCompanies(
      companySearchFilter.companyNameSearchTerm,
      companySearchFilter.companyNumberSearchTerm,
      companySearchFilter.isTopCompany,
      companySearchFilter.originApiClients,
      companySearchFilter.isSebuCompany,
      companySearchFilter.segaCompany,
      undefined,
      undefined,
      companySearchFilter.companyProfile,
      companySearchFilter.basicCompanyProfile,
      companySearchFilter.skip,
      companySearchFilter.take,
      companySearchFilter.resellerId,
      companySearchFilter.orderBy ? companySearchFilter.orderBy : undefined);
  }

  public searchCompanyByNameOrNumber(searchTerm: string, numberOfResults = 10): Observable<CompanySearchByNumberAndNameResult[]> {
    return this.companyApi.companySearchCompaniesUsingNameOrNumberAsync(searchTerm, 0, numberOfResults)
      .pipe(map((res: NODataPagedResultCompanySearchByNumberAndNameResult) =>
        res.page.sort(CompanySearchService.querySortComparator(searchTerm))));
  }

  public mapTableFilterValuesToCompanySearchFilter(companyTableFilter: CompanyTableFilter): CompanyTableFilter {
    const companySearchFilter: CompanySearchFilter = {
      companyNameSearchTerm: companyTableFilter.name,
      companyNumberSearchTerm: companyTableFilter.number,
      isTopCompany: companyTableFilter.topCompany ? true : undefined,
      isSebuCompany: companyTableFilter.isSebuCompany ? true : undefined,
      segaCompany: companyTableFilter.segaCompany || undefined,
      companyProfile: companyTableFilter.companyProfile || undefined,
      basicCompanyProfile: companyTableFilter.basicCompanyProfile || undefined
    };
    return companySearchFilter;
  }

  public getCompanyById(id: number): Observable<Company> {
    return this.resellerService.getCurrentReseller().pipe(
      switchMap((reseller: Reseller) =>
        this.companyApi.companyGetAsync(id, reseller.id)));
  }

  public isCompanyNumberInUse(companyNumber: string): Observable<boolean> {
    return this.companyApi.companyCompanyByCompanyNumberAsync(companyNumber).pipe(
      catchError(() => of(false)),
      map(res => res === null));
  }
}
