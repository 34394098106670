import { ProductEnum } from '../../reseller-api/client/index';
import { OnlineOnlyConfig } from './online-only-config';
import { PortalOnlineOnlyConfig } from './portal-online-only-config';
import { PortalPOKConfig } from './portal-pok-config';
import { PremiumPOKConfig } from './premium-pok-config';
import { Config } from './product-config.model';
import { RegioOnlineOnlyConfig } from './regio-online-only-config';
import { RegioPOKConfig } from './regio-pok-config';
import { SegaKontingentRegioOnlineOnlyConfig } from './sega-kontingent-regio-online-only-config';
import { SegaPremiumOnlineOnlyConfig } from './sega-premium-online-only-config';
import { SegaRegioOnlineOnlyConfig } from './sega-regio-online-only';
import { PaketBundlesConfig } from './paket-bundles-config';

const productConfigs: Config[] = [];
productConfigs[ProductEnum.OnlineOnlyHtml] = OnlineOnlyConfig.HTML;
productConfigs[ProductEnum.OnlineOnlyPdfUndSonstigesFormat] = OnlineOnlyConfig.PDF;
productConfigs[ProductEnum.PortalOnlineOnlyVonStellenanzeigenDeErstellt] = PortalOnlineOnlyConfig.PDF;
productConfigs[ProductEnum.PortalPokVonStellenanzeigenDeErstellt] = PortalPOKConfig.PDF;
productConfigs[ProductEnum.PortalPokPlusVonStellenanzeigenDeErstellt] = PortalPOKConfig.PDF; // identical to PortalPOK
productConfigs[ProductEnum.PremiumPokHtml] = PremiumPOKConfig.HTML;
productConfigs[ProductEnum.PremiumPokPdfUndSonstigesFormat] = PremiumPOKConfig.PDF;
productConfigs[ProductEnum.RegioOnlineOnlyHtml] = RegioOnlineOnlyConfig.HTML;
productConfigs[ProductEnum.RegioOnlineOnlyJpg] = RegioOnlineOnlyConfig.JPG;
productConfigs[ProductEnum.RegioOnlineOnlyStandardlayout] = RegioOnlineOnlyConfig.STANDARD_LAYOUT;
productConfigs[ProductEnum.RegioOnlineOnlyStandardlayoutFuerFliesstextanzeigen] = RegioOnlineOnlyConfig.FLIESSTEXT;
productConfigs[ProductEnum.RegioPokHtml] = RegioPOKConfig.HTML;
productConfigs[ProductEnum.RegioPokJpg] = RegioPOKConfig.JPG;
productConfigs[ProductEnum.RegioPokStandardlayout] = RegioPOKConfig.STANDARD_LAYOUT;
productConfigs[ProductEnum.RegioPokStandardHtml] = RegioPOKConfig.STANDARD_HTML;
productConfigs[ProductEnum.RegioPokStandardlayoutFuerFliesstextanzeigen] = RegioPOKConfig.FLIESSTEXT;
productConfigs[ProductEnum.SegaKontingentRegioOnlineOnlyHtml] = SegaKontingentRegioOnlineOnlyConfig.HTML;
productConfigs[ProductEnum.SegaPremiumOnlineOnlyHtml] = SegaPremiumOnlineOnlyConfig.HTML;
productConfigs[ProductEnum.SegaRegioOnlineOnlyHtml] = SegaRegioOnlineOnlyConfig.HTML;

productConfigs[ProductEnum.RegioOnlineOnlyAzubi] = RegioOnlineOnlyConfig.STANDARD_LAYOUT; // identical to RegioOO Standardlayout
productConfigs[ProductEnum.RegioOnlineOnlyMinijobs] = RegioOnlineOnlyConfig.STANDARD_LAYOUT; // identical to RegioOO Standardlayout
productConfigs[ProductEnum.PortalOnlineOnlyAzubi] = PortalOnlineOnlyConfig.PDF; // identical to PortalOO Pdf
productConfigs[ProductEnum.OnlineOnlyAzubi] = OnlineOnlyConfig.PDF; // identical to Premium OO Pdf
productConfigs[ProductEnum.PremiumPokAzubi] = PremiumPOKConfig.PDF; // identical to Premium POK Pdf
productConfigs[ProductEnum.PublisherPaket1] = PaketBundlesConfig.HTML; // identical to Premium OO HTML
productConfigs[ProductEnum.PublisherPaket2] = PaketBundlesConfig.HTML; // identical to Premium OO HTML
productConfigs[ProductEnum.PublisherPaket3] = PaketBundlesConfig.HTML; // identical to Premium OO HTML
productConfigs[ProductEnum.ITPaket] = PaketBundlesConfig.HTML; // identical to Premium OO HTML

export const ProductConfigs: Config[] = productConfigs;
export * from './online-only-config';
export * from './portal-online-only-config';
export * from './portal-pok-config';
export * from './premium-pok-config';
export * from './regio-online-only-config';
export * from './regio-pok-config';
export * from './sega-kontingent-regio-online-only-config';
export * from './sega-premium-online-only-config';
export * from './sega-regio-online-only';
export * from './paket-bundles-config';
export * from './product-config.model';
