/**
 * Sa.Reseller.Api
 *
 * OpenAPI spec version: v2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * The client who created this company.
 */
export type ApiClientEnum = 'Stellenanzeigen' | 'Ui' | 'Webservice' | 'Sega' | 'SebuBackend' | 'Preview' | 'SynchronizationTool' | 'WorkflowTool' | 'Amsterdam' | 'ResellerAdmin';

export const ApiClientEnum = {
    Stellenanzeigen: 'Stellenanzeigen' as ApiClientEnum,
    Ui: 'Ui' as ApiClientEnum,
    Webservice: 'Webservice' as ApiClientEnum,
    Sega: 'Sega' as ApiClientEnum,
    SebuBackend: 'SebuBackend' as ApiClientEnum,
    Preview: 'Preview' as ApiClientEnum,
    SynchronizationTool: 'SynchronizationTool' as ApiClientEnum,
    WorkflowTool: 'WorkflowTool' as ApiClientEnum,
    Amsterdam: 'Amsterdam' as ApiClientEnum,
    ResellerAdmin: 'ResellerAdmin' as ApiClientEnum
}
