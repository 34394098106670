/**
 * Sa.Reseller.Api
 *
 * OpenAPI spec version: v2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export type OfferLocationTypeEnum = 'Ort' | 'Bundesland' | 'Land';

export const OfferLocationTypeEnum = {
    Ort: 'Ort' as OfferLocationTypeEnum,
    Bundesland: 'Bundesland' as OfferLocationTypeEnum,
    Land: 'Land' as OfferLocationTypeEnum
}
