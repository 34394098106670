/**
 * Sa.Reseller.Api
 *
 * OpenAPI spec version: v2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export type OfferRequirementEnum = 'KeineAusbildungErforderlich' | 'BerufsausbildungErforderlich' | 'TechnikerAbschluss' | 'Meister' | 'KeinStudiumErforderlich' | 'StudiumErforderlich' | 'PromotionErforderlich' | 'HabilitationErforderlich' | 'NichtLeitendePosition' | 'LeitendePosition' | 'BerufserfahrungNichtErforderlich' | 'BerufserfahrungWuenschenswert' | 'BerufserfahrungErforderlich' | 'FuehrungserfahrungNichtErforderlich' | 'FuehrungserfahrungWuenschenswert' | 'FuehrungserfahrungErforderlich' | 'ReisetaetigkeitJa' | 'ReisetaetigkeitNein' | 'Vollzeit' | 'Teilzeit' | 'DasInDerStellenanzeigeGenannteUnternehmenSelbst' | 'EinsatzDesArbeitnehmersBeiAnderenUnternehmen' | 'EinNichtInDerAnzeigeGenanntesUnternehmen' | 'FreelancerProjektarbeit' | 'Gelegentlich' | 'HomeOfficeMoeglich100' | 'HomeOfficeMoeglich' | 'KeineAngabe';

export const OfferRequirementEnum = {
    KeineAusbildungErforderlich: 'KeineAusbildungErforderlich' as OfferRequirementEnum,
    BerufsausbildungErforderlich: 'BerufsausbildungErforderlich' as OfferRequirementEnum,
    TechnikerAbschluss: 'TechnikerAbschluss' as OfferRequirementEnum,
    Meister: 'Meister' as OfferRequirementEnum,
    KeinStudiumErforderlich: 'KeinStudiumErforderlich' as OfferRequirementEnum,
    StudiumErforderlich: 'StudiumErforderlich' as OfferRequirementEnum,
    PromotionErforderlich: 'PromotionErforderlich' as OfferRequirementEnum,
    HabilitationErforderlich: 'HabilitationErforderlich' as OfferRequirementEnum,
    NichtLeitendePosition: 'NichtLeitendePosition' as OfferRequirementEnum,
    LeitendePosition: 'LeitendePosition' as OfferRequirementEnum,
    BerufserfahrungNichtErforderlich: 'BerufserfahrungNichtErforderlich' as OfferRequirementEnum,
    BerufserfahrungWuenschenswert: 'BerufserfahrungWuenschenswert' as OfferRequirementEnum,
    BerufserfahrungErforderlich: 'BerufserfahrungErforderlich' as OfferRequirementEnum,
    FuehrungserfahrungNichtErforderlich: 'FuehrungserfahrungNichtErforderlich' as OfferRequirementEnum,
    FuehrungserfahrungWuenschenswert: 'FuehrungserfahrungWuenschenswert' as OfferRequirementEnum,
    FuehrungserfahrungErforderlich: 'FuehrungserfahrungErforderlich' as OfferRequirementEnum,
    ReisetaetigkeitJa: 'ReisetaetigkeitJa' as OfferRequirementEnum,
    ReisetaetigkeitNein: 'ReisetaetigkeitNein' as OfferRequirementEnum,
    Vollzeit: 'Vollzeit' as OfferRequirementEnum,
    Teilzeit: 'Teilzeit' as OfferRequirementEnum,
    DasInDerStellenanzeigeGenannteUnternehmenSelbst: 'DasInDerStellenanzeigeGenannteUnternehmenSelbst' as OfferRequirementEnum,
    EinsatzDesArbeitnehmersBeiAnderenUnternehmen: 'EinsatzDesArbeitnehmersBeiAnderenUnternehmen' as OfferRequirementEnum,
    EinNichtInDerAnzeigeGenanntesUnternehmen: 'EinNichtInDerAnzeigeGenanntesUnternehmen' as OfferRequirementEnum,
    FreelancerProjektarbeit: 'FreelancerProjektarbeit' as OfferRequirementEnum,
    Gelegentlich: 'Gelegentlich' as OfferRequirementEnum,
    HomeOfficeMoeglich100: 'HomeOfficeMoeglich100' as OfferRequirementEnum,
    HomeOfficeMoeglich: 'HomeOfficeMoeglich' as OfferRequirementEnum,
    KeineAngabe: 'KeineAngabe' as OfferRequirementEnum
}
