import { Component, OnDestroy, OnInit } from '@angular/core';
import { User } from './user';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiLoginService } from '../shared/services/api-login.service';
import { Observable } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  loginFailed = false;
  user: User = new User();
  private componentDestroyed$: Subject<void> = new Subject<void>();


  constructor(private route: ActivatedRoute,
              private router: Router,
              private sareLogin: ApiLoginService) {
  }

  public ngOnInit(): void {
    this.onInitAsync()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  public onInitAsync(): Observable<void> {
    return this.route.queryParams.pipe(
      takeUntil(this.componentDestroyed$),
      filter(params => params.username && params.password),
      map(params => {this.user = { userName: params.username, password: params.password }; })
    );
  }

  public onSubmit(): void {
    this.login(this.user.userName, this.user.password)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe();
  }

  public login(userName: string, password: string): Observable<boolean> {
    return this.sareLogin.login(userName, password).pipe(
      takeUntil(this.componentDestroyed$),
      tap(success => this.loginFailed = !success),
      filter(success => !!success),
      switchMap(() => this.router.navigateByUrl(this.route.snapshot.queryParams['returnUrl'] || '/'))
    );
  }
}
