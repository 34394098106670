import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import 'hammerjs';
import { AppCommonsModule } from './app-commons.module';
import { AppDialogModule } from './app-dialog.module';
import { AppComponent } from './app.component';
import { appRoutes } from './app-routes';
import { APP_CONFIG, AppConfig } from './app.config';
import { ConfirmCookieComponent } from './shared/components/confirm-cookie/confirm-cookie.component';
import { LoginModule } from './login/login.module';
import { MainLayoutModule } from './main-layout/main-layout.module';
import { SareApiModule } from './shared/reseller-api/sare-api.module';
import localeDE from '@angular/common/locales/de';
import { registerLocaleData } from '@angular/common';
import { RendererApiModule } from './shared/renderer-api/renderer-api.module';
import { HttpClientModule } from '@angular/common/http';
import { provideErrorHandler } from './shared/utils/sentry/raven-error-handler';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpInterceptorsModule } from './shared/interceptors/http-interceptors.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

registerLocaleData(localeDE);

@NgModule({
    imports: [
        // SARE API
        SareApiModule,
        RendererApiModule,
        HttpClientModule,
        // modules required by Angular and Material
        AppCommonsModule,
        BrowserModule,
        BrowserAnimationsModule,
        // dialogue related dependencies
        AppDialogModule,
        // Feature Modules (non-lazy)
        LoginModule,
        MainLayoutModule,
        HttpInterceptorsModule,
        // needs to be last
        RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload', preloadingStrategy: PreloadAllModules })
    ],
    declarations: [
        AppComponent,
        ConfirmCookieComponent
    ],
    providers: [
        { provide: APP_CONFIG, useValue: AppConfig },
        { provide: LOCALE_ID, useValue: 'de-DE' },
        { provide: ErrorHandler, useFactory: provideErrorHandler },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { hideRequiredMarker: true },
        },
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
}
