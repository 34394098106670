import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { OfferOrderStatusEnum } from '../../../shared/reseller-api/client';
import { debounceTime } from 'rxjs/operators';
import { map, switchMap } from 'rxjs/internal/operators';
import { OfferOrderSearchService } from '../../../offer-order-management/shared/services/offer-order-search/offer-order-search.service';

@Injectable({
  providedIn: 'root',
})

/*
* This service is for triggering the reloading of the current open offer order count if no routing takes place.
* */
export class OpenOfferOrdersCounterService {

  // It's a string, so we can display zero, except when loading
  private readonly openOfferOrdersCount: Observable<string>;
  private reloadOfferOrderCounter: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private offerOrderSearchService: OfferOrderSearchService) {
    this.openOfferOrdersCount = this.reloadOfferOrderCounter.pipe(debounceTime(2000),
      switchMap(() => this.offerOrderSearchService.searchOfferOrder({ offerOrderStates: [OfferOrderStatusEnum.Offen], take: 0 })),
      map(result => result.totalCount.toString())
    );
  }

  public getCounter(): Observable<string> {
    return this.openOfferOrdersCount;
  }

  public refreshCounter(): void {
    this.reloadOfferOrderCounter.next(true);
  }
}
