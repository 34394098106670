import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { SessionStorageService } from '../../../services/session-storage/session-storage.service';
import { Observable } from 'rxjs/internal/Observable';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Subject } from 'rxjs/internal/Subject';
import { TableFilterValues } from '../table-filter.model';

@Injectable({
  providedIn: 'root',
})
export class FilterStorageService {

  private filterValuesObservables: Map<string, Subject<TableFilterValues>> = new Map<string, Subject<TableFilterValues>>();

  constructor(private location: Location,
              private storage: SessionStorageService) {
  }

  public loadFilterValues(storageKey: string): TableFilterValues {
    return this.storage.load<TableFilterValues>(storageKey) || new TableFilterValues();
  }

  public storeFilterValues(values: TableFilterValues, storageKey: string): void {
    this.storage.store(values, storageKey);
    this.getOrAddSubject(storageKey).next(values);
  }

  public clearFilterValues(): void {
    this.filterValuesObservables.clear();
  }

  public onUpdatedFilterValues(storageKey: string): Observable<TableFilterValues> {
    return this.getOrAddSubject(storageKey);
  }

  private getOrAddSubject(storageKey: string): Subject<TableFilterValues> {
    if (!this.filterValuesObservables.has(storageKey)) {
      this.filterValuesObservables.set(storageKey, new BehaviorSubject(new TableFilterValues()));
    }
    return this.filterValuesObservables.get(storageKey);
  }
}
