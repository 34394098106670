/**
 * Sa.Reseller.Api
 *
 * OpenAPI spec version: v2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export type CompanySearchFilterCompanyProfileStatus = 'NotPurchased' | 'ActiveOrPurchased' | 'Expired';

export const CompanySearchFilterCompanyProfileStatus = {
    NotPurchased: 'NotPurchased' as CompanySearchFilterCompanyProfileStatus,
    ActiveOrPurchased: 'ActiveOrPurchased' as CompanySearchFilterCompanyProfileStatus,
    Expired: 'Expired' as CompanySearchFilterCompanyProfileStatus
}
