/**
 * Sa.Reseller.Api
 *
 * OpenAPI spec version: v2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export type ProductVariantEnum = 'Html' | 'PdfUndSonstigesFormat' | 'Jpg' | 'StandardlayoutFuerFliesstextanzeigen' | 'Standardlayout' | 'StandardHtml';

export const ProductVariantEnum = {
    Html: 'Html' as ProductVariantEnum,
    PdfUndSonstigesFormat: 'PdfUndSonstigesFormat' as ProductVariantEnum,
    Jpg: 'Jpg' as ProductVariantEnum,
    StandardlayoutFuerFliesstextanzeigen: 'StandardlayoutFuerFliesstextanzeigen' as ProductVariantEnum,
    Standardlayout: 'Standardlayout' as ProductVariantEnum,
    StandardHtml: 'StandardHtml' as ProductVariantEnum
}
