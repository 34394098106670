/* eslint-disable */
import { CompanyProfileV3CreateComponent } from '../../../company/company-detail/company-profile-v3/company-profile-v3-create/company-profile-v3-create.component';
import { CompanyProfileV3EditComponent } from '../../../company/company-detail/company-profile-v3/company-profile-v3-edit/company-profile-v3-edit.component';
import { CompanyProfileV3ExtendComponent } from '../../../company/company-detail/company-profile-v3/company-profile-v3-extend/company-profile-v3-extend.component';
import { CompanyProfileCreateComponent } from '../../../company/company-detail/company-profile/company-profile-create/company-profile-create.component';
import { CompanyProfileDeactivateComponent } from '../../../company/company-detail/company-profile/company-profile-deactivate/company-profile-deactivate.component';
import { CompanyProfileExtendComponent } from '../../../company/company-detail/company-profile/company-profile-extend/company-profile-extend.component';
import { CompanyProfileTrackingCodeComponent } from '../../../company/company-detail/company-profile/company-profile-tracking-code-dialog/company-profile-tracking-code.component';
import { NavLinksProvider } from '../../../main-layout/nav-links';
import { NavElement, NavLinkEnum } from '../../../main-layout/nav.model';
import { VideoDialogComponent } from '../../../video-administration/video-overview/video-dialog/video-dialog.component';
import { VideoOverviewTableComponent } from '../../../video-administration/video-overview/video-overview-table.component';
import { ResellerPermissions } from './reseller-permissions.model';
import {
  InternalProductNameOverviewComponent
} from "../../../internal-product-name-administration/internal-product-name-overview/internal-product-name-overview.component";
/* eslint-enable */

export class UserRolePermissionPolicy {
  private readonly forbiddenNavigationLinks: NavLinkEnum[];
  private readonly forbiddenComponents: string[];
  private readonly resellerPermissions: ResellerPermissions;

  constructor(forbiddenComponents: string[],
              forbiddenNavigationLinks: NavLinkEnum[],
              resellerPermissions: ResellerPermissions) {
    this.forbiddenComponents = [].concat(forbiddenComponents);
    this.forbiddenNavigationLinks = [].concat(forbiddenNavigationLinks);
    this.resellerPermissions = Object.assign({}, resellerPermissions);
    this.filterDisabledMainFeatures();
  }

  public canVisitComponent(component: string): boolean {
    return !this.forbiddenComponents.includes(component);
  }

  public getNavigationLinks(): NavElement[] {
    return NavLinksProvider.getNavLinks().filter((navigationLink: NavElement) =>
      !this.forbiddenNavigationLinks.includes(navigationLink.id));
  }

  private filterDisabledMainFeatures(): void {
    if (!this.resellerPermissions.companyVideosEnabled) {
      this.forbiddenNavigationLinks.push(NavLinkEnum.videoManagement);
      this.forbiddenComponents.push('VideoOverviewTableComponent');
      this.forbiddenComponents.push('VideoDialogComponent');
    }
    if (!this.resellerPermissions.statisticsEnabled) {
      this.forbiddenNavigationLinks.push(NavLinkEnum.statistics);
      this.forbiddenComponents.push('StatisticsOverviewComponent');
    }
    if (!this.resellerPermissions.segaEnabled || !this.resellerPermissions.companyProfilesEnabled) {
      this.forbiddenComponents.push('CompanyProfileCreateComponent');
      this.forbiddenComponents.push('CompanyProfileDeactivateComponent');
      this.forbiddenComponents.push('CompanyProfileExtendComponent');
      this.forbiddenComponents.push('CompanyProfileTrackingCodeComponent');
    }
    if (!this.resellerPermissions.companyProfilesV3Enabled) {
      this.forbiddenComponents.push('CompanyProfileV3CreateComponent');
      this.forbiddenComponents.push('CompanyProfileV3EditComponent');
      this.forbiddenComponents.push('CompanyProfileV3ExtendComponent');
      this.forbiddenComponents.push('CompanyProfileV3PublishComponent');
    }
    if (!this.resellerPermissions.internalProductNameEnabled) {
      this.forbiddenNavigationLinks.push(NavLinkEnum.internalProductName);
      this.forbiddenComponents.push('InternalProductNameOverviewComponent');
    }
  }
}
