import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { DisplayTextDictionaries } from '../../../shared/utils/classes/display-text-dictionaries';
import { OPEN_OFFER_ORDERS_COUNTER } from '../../shared/header-display-texts';
import { OpenOfferOrdersCounterService } from './open-offer-orders-counter.service';
import { Router } from '@angular/router';
import { OfferOrderStatusEnum } from '../../../shared/reseller-api/client';
import { FilterStorageService } from '../../../shared/components/table-filter-component/filter-storage/filter-storage.service';
import { TableFilterValues } from '../../../shared/components/table-filter-component/table-filter.model';
import { OFFER_ORDER_MANAGEMENT_KEY } from '../../../offer-order-management/offer-order-overview/filter/models/offer-order-filter.constants';

@Component({
  selector: 'app-open-offer-orders-counter',
  templateUrl: './open-offer-orders-counter.component.html',
  styleUrls: ['./open-offer-orders-counter.component.scss']
})
export class OpenOfferOrdersCounterComponent {

  public numberOfOpenOfferOrders$: Observable<string>;
  public displayTexts: DisplayTextDictionaries = OPEN_OFFER_ORDERS_COUNTER;

  constructor(private readonly router: Router,
              private readonly tableFilterValuesService: FilterStorageService,
              private readonly openOfferOrdersCountService: OpenOfferOrdersCounterService) {
    this.numberOfOpenOfferOrders$ = this.openOfferOrdersCountService.getCounter();
  }

  public goToTable(): void {
    this.setTableFilterValues();
    this.router.navigate(['/offer-order']);
  }

  private setTableFilterValues(): void {
    const filter: TableFilterValues = new TableFilterValues();
    filter.values = [{key: 'statuses', value: [OfferOrderStatusEnum.Offen]}];
    this.tableFilterValuesService.storeFilterValues(filter, OFFER_ORDER_MANAGEMENT_KEY);
  }
}
