/**
 * Sa.Reseller.Api
 *
 * OpenAPI spec version: v2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export type ProductTypeEnum = 'RegioPok' | 'RegioOnlineOnly' | 'PremiumPok' | 'OnlineOnly' | 'PortalPok' | 'PortalOnlineOnly' | 'PortalPokPlus' | 'PaketBundles';

export const ProductTypeEnum = {
    RegioPok: 'RegioPok' as ProductTypeEnum,
    RegioOnlineOnly: 'RegioOnlineOnly' as ProductTypeEnum,
    PremiumPok: 'PremiumPok' as ProductTypeEnum,
    OnlineOnly: 'OnlineOnly' as ProductTypeEnum,
    PortalPok: 'PortalPok' as ProductTypeEnum,
    PortalOnlineOnly: 'PortalOnlineOnly' as ProductTypeEnum,
    PortalPokPlus: 'PortalPokPlus' as ProductTypeEnum,
    PaketBundles: 'PaketBundles' as ProductTypeEnum
}
