/**
 * Sa.Reseller.Api
 *
 * OpenAPI spec version: v2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export type CompanySearchFilterBasicCompanyProfileStatus = 'Ready' | 'Active' | 'Incomplete';

export const CompanySearchFilterBasicCompanyProfileStatus = {
    Ready: 'Ready' as CompanySearchFilterBasicCompanyProfileStatus,
    Active: 'Active' as CompanySearchFilterBasicCompanyProfileStatus,
    Incomplete: 'Incomplete' as CompanySearchFilterBasicCompanyProfileStatus
}
