import { Component } from '@angular/core';
import { CookieService } from '../../services/cookies/cookie.service';
import { CookieOptions } from '../../services/cookies/cookie-options';
import { ConfirmCookie } from './confirm-cookie';
import { MAX_DATE } from '../../utils/date/date-constants';

@Component({
  selector: 'app-confirm-cookie',
  templateUrl: './confirm-cookie.component.html',
  styleUrls: ['./confirm-cookie.component.scss']
})
export class ConfirmCookieComponent {
  public cookiesAccepted: boolean;
  readonly COOKIE_NAME: string = 'cookiesconfirmed';
  readonly COOKIE_OPTIONS: CookieOptions = {
    path: '/',
    // max available date since cookie should not expire
    expires: MAX_DATE
  };

  constructor(private cookieService: CookieService) {
    this.cookiesAccepted = this.cookieService.get(this.COOKIE_NAME).cookiesAccepted || false;
  }

  public setCookieAccepted(): void {
    this.cookiesAccepted = true;
    const confirmCookie: ConfirmCookie = {
      cookiesAccepted: this.cookiesAccepted
    };
    this.cookieService.set(this.COOKIE_NAME, confirmCookie, this.COOKIE_OPTIONS);
  }
}
